import { Box, Button, Flex, Tbody, Tr } from '@chakra-ui/react';
import React, { useState } from 'react';
import { termosColors } from '../..';
import {
  Portfolio,
  PortfolioPerformanceStats,
  PPChartDataRangeType,
} from '../../ApiClients';
import { ColorTextedLabel } from '../../components/ColorTextedLabel';
import { TLineChart } from '../../components/TLineChart';
import { TTable, TStickyHead, TTh, TTr, TinyTd } from '../../components/TTable';
import {
  useGetPortfolioPerformanceChartData,
  useGetPortfolioPerformanceStats,
} from '../../hooks/portfolios';
import { formatDateSpecial } from '../../utils/date';
import { colorNumberAroundZero, formatThousandSpace } from '../../utils/number';

interface Props {
  portfolio: Portfolio | undefined;
}

export const PortfolioOverview: React.FC<Props> = ({ portfolio }) => {
  const [chartType, setChartType] = useState(PPChartDataRangeType.OneMonth);
  const { data: chartData } = useGetPortfolioPerformanceChartData(
    chartType,
    portfolio?.id ? portfolio.id : 0,
    null
  );
  const { data: stats } = useGetPortfolioPerformanceStats(
    portfolio?.id ? portfolio.id : 0,
    null
  );

  const data = {
    labels: chartData && chartData.labels ? chartData.labels : [],
    datasets: [
      {
        label: '',
        data: chartData && chartData.values ? chartData.values : [],
        borderColor: 'white',
        fill: true,
        backgroundColor: 'rgb(11, 67, 120, 0.5)',
        borderWidth: 0.6,
      },
    ],
  };

  return (
    <Flex mt={2} mb={2}>
      <Flex
        border='1px'
        borderColor={termosColors.gray}
        flex={1}
        mr={2}
        p={4}
        fontSize='14px'
      >
        <Box flex={1}>
          <PortStats portfolio={portfolio} stats={stats} />
        </Box>
        <Box flex={1} pl={4}>
          <ColorTextedLabel
            label='Total change:'
            text={`${stats?.maxChange?.percentChange}% / ${formatThousandSpace(
              stats?.maxChange?.change
            )} ${portfolio?.currency?.value}`}
            color={colorNumberAroundZero(stats?.maxChange?.change)}
          />
          <ColorTextedLabel
            label='Total value:'
            text={`${formatThousandSpace(stats?.lastValue)} ${
              portfolio?.currency?.value
            }`}
            color={colorNumberAroundZero(stats?.lastValue)}
          />
          <ColorTextedLabel
            label='As of'
            text={formatDateSpecial(stats?.lastCreatedUtc, 'dd.MM.yyyy HH:mm')}
          />
        </Box>
      </Flex>
      <Box flex={1}>
        <TLineChart data={data} />
        <Flex justifyContent='space-around'>
          <Button
            colorScheme='ghost'
            variant='solid'
            color={
              chartType === PPChartDataRangeType.OneMonth
                ? 'termos.orange'
                : 'white'
            }
            p={0}
            mr={8}
            onClick={() => setChartType(PPChartDataRangeType.OneMonth)}
          >
            1m
          </Button>
          <Button
            colorScheme='ghost'
            variant='solid'
            color={
              chartType === PPChartDataRangeType.ThreeMonth
                ? 'termos.orange'
                : 'white'
            }
            p={0}
            mr={8}
            onClick={() => setChartType(PPChartDataRangeType.ThreeMonth)}
          >
            3m
          </Button>
          <Button
            colorScheme='ghost'
            variant='solid'
            color={
              chartType === PPChartDataRangeType.SixMonth
                ? 'termos.orange'
                : 'white'
            }
            p={0}
            mr={8}
            onClick={() => setChartType(PPChartDataRangeType.SixMonth)}
          >
            6m
          </Button>
          <Button
            colorScheme='ghost'
            variant='solid'
            color={
              chartType === PPChartDataRangeType.YTD ? 'termos.orange' : 'white'
            }
            p={0}
            mr={8}
            onClick={() => setChartType(PPChartDataRangeType.YTD)}
          >
            YTD
          </Button>
          <Button
            colorScheme='ghost'
            variant='solid'
            color={
              chartType === PPChartDataRangeType.OneYear
                ? 'termos.orange'
                : 'white'
            }
            p={0}
            mr={8}
            onClick={() => setChartType(PPChartDataRangeType.OneYear)}
          >
            1y
          </Button>
          <Button
            colorScheme='ghost'
            variant='solid'
            color={
              chartType === PPChartDataRangeType.Max ? 'termos.orange' : 'white'
            }
            p={0}
            mr={8}
            onClick={() => setChartType(PPChartDataRangeType.Max)}
          >
            Max
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

interface PortStatsProps {
  portfolio: Portfolio | undefined;
  stats: PortfolioPerformanceStats | undefined;
}

const PortStats: React.FC<PortStatsProps> = ({ portfolio, stats }) => {
  return (
    <TTable>
      <TStickyHead>
        <Tr>
          <TTh>
            <></>
          </TTh>
          <TTh>Change %</TTh>
          <TTh>{`Change (${portfolio?.currency?.value})`}</TTh>
        </Tr>
      </TStickyHead>
      <Tbody>
        <TTr i={0}>
          <TinyTd>1d</TinyTd>
          <TinyTd
            color={colorNumberAroundZero(stats?.oneDayChange?.percentChange)}
          >
            <>{stats?.oneDayChange?.percentChange}%</>
          </TinyTd>
          <TinyTd color={colorNumberAroundZero(stats?.oneDayChange?.change)}>
            {formatThousandSpace(stats?.oneDayChange?.change)}
          </TinyTd>
        </TTr>
        <TTr i={1}>
          <TinyTd>1w</TinyTd>
          <TinyTd
            color={colorNumberAroundZero(stats?.oneWeekChange?.percentChange)}
          >
            <>{stats?.oneWeekChange?.percentChange}%</>
          </TinyTd>
          <TinyTd color={colorNumberAroundZero(stats?.oneWeekChange?.change)}>
            {formatThousandSpace(stats?.oneWeekChange?.change)}
          </TinyTd>
        </TTr>
        <TTr i={0}>
          <TinyTd>1m</TinyTd>
          <TinyTd
            color={colorNumberAroundZero(stats?.oneMonthChange?.percentChange)}
          >
            <>{stats?.oneMonthChange?.percentChange}%</>
          </TinyTd>
          <TinyTd color={colorNumberAroundZero(stats?.oneMonthChange?.change)}>
            {formatThousandSpace(stats?.oneMonthChange?.change)}
          </TinyTd>
        </TTr>
        <TTr i={1}>
          <TinyTd>3m</TinyTd>
          <TinyTd
            color={colorNumberAroundZero(
              stats?.threeMonthChange?.percentChange
            )}
          >
            <>{stats?.threeMonthChange?.percentChange}%</>
          </TinyTd>
          <TinyTd
            color={colorNumberAroundZero(stats?.threeMonthChange?.change)}
          >
            {formatThousandSpace(stats?.threeMonthChange?.change)}
          </TinyTd>
        </TTr>
        <TTr i={0}>
          <TinyTd>6m</TinyTd>
          <TinyTd
            color={colorNumberAroundZero(stats?.sixMonthChange?.percentChange)}
          >
            <>{stats?.sixMonthChange?.percentChange}%</>
          </TinyTd>
          <TinyTd color={colorNumberAroundZero(stats?.sixMonthChange?.change)}>
            {formatThousandSpace(stats?.sixMonthChange?.change)}
          </TinyTd>
        </TTr>
        <TTr i={1}>
          <TinyTd>YTD</TinyTd>
          <TinyTd
            color={colorNumberAroundZero(stats?.ytdChange?.percentChange)}
          >
            <>{stats?.ytdChange?.percentChange}%</>
          </TinyTd>
          <TinyTd color={colorNumberAroundZero(stats?.ytdChange?.change)}>
            {formatThousandSpace(stats?.ytdChange?.change)}
          </TinyTd>
        </TTr>
        <TTr i={0}>
          <TinyTd>1y</TinyTd>
          <TinyTd
            color={colorNumberAroundZero(stats?.oneYearChange?.percentChange)}
          >
            <>{stats?.oneYearChange?.percentChange}%</>
          </TinyTd>
          <TinyTd color={colorNumberAroundZero(stats?.oneYearChange?.change)}>
            {formatThousandSpace(stats?.oneYearChange?.change)}
          </TinyTd>
        </TTr>
      </Tbody>
    </TTable>
  );
};
