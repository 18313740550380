import { Box, Flex, Heading } from '@chakra-ui/layout';
import React from 'react';
import { Company } from '../../ApiClients';
import { Infos } from '../company/Infos';
import { Links } from '../company/Links';
import { Prices } from '../company/Prices';
import { Ratios } from '../company/Ratios';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import { EQ, routes } from '../../routes';
import { AddToWatchlist } from '../watchlist/AddToWatchlist';
import Highlighter from 'react-highlight-words';

interface Props {
  company: Company;
  highlightWords?: string[];
}

export const CompanyDetails: React.FC<Props> = ({
  company,
  highlightWords,
}) => {
  const fundamental =
    company.fundamentals && company.fundamentals.length > 0
      ? company.fundamentals[0]
      : undefined;
  return (
    <Box maxW='50vw'>
      <Flex justifyContent='space-between'>
        <Link
          as={RouterLink}
          to={generatePath(routes.find((r) => r.key === EQ)?.path ?? '', {
            ticker: company.ticker,
          })}
          isExternal
        >
          <Heading
            mb={4}
          >{`${company.ticker} - ${company.name} (${company.currency?.value})`}</Heading>
        </Link>
        <AddToWatchlist companyId={company.id} />
      </Flex>
      <Flex mb={4}>
        <Prices company={company} fundamental={fundamental} doFetch={false} />
        <Ratios fundamental={fundamental} />
        <Infos company={company} fundamental={fundamental} />
        <Links company={company} />
      </Flex>
      <Box>
        <Highlighter
          highlightClassName='YourHighlightClass'
          searchWords={highlightWords ? highlightWords : []}
          autoEscape={true}
          textToHighlight={company.description ? company.description : ''}
        />
      </Box>
    </Box>
  );
};
