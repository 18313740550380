import React from 'react';
import { TermosMultiSelect } from '../../components/TermosMultiSelect';
import { useGetCompanyIndices } from '../../hooks';

interface Props {
  onChange: (value: string) => void;
}

export const CompanyIndexSelect: React.FC<Props> = ({ onChange }) => {
  const { data: indices } = useGetCompanyIndices();

  return (
    <TermosMultiSelect
      title=''
      options={indices?.map((i) => ({
        value: i.extId,
        label: i.name,
      }))}
      onChange={(opts) =>
        onChange(opts ? opts.map((o) => `'${o.value}'`).toString() : '')
      }
    />
  );
};
