import {
  useDisclosure,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  ModalBody,
  Center,
  Input,
  ModalFooter,
  Button,
  Text,
  Flex,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { VscBriefcase } from 'react-icons/vsc';
import { useQueryClient } from 'react-query';
import { PortfoliosClient } from '../../ApiClients';
import { TermosSelect } from '../../components/TermosSelect';
import { useSearchCompany } from '../../hooks';

const portfoliosClient = new PortfoliosClient();

interface Props {
  currentPortfolioId: number;
  refetchPortfolios: () => void;
}

export const AddPortfolioCompany: React.FC<Props> = ({
  currentPortfolioId,
  refetchPortfolios,
}) => {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [searchText, setSearchText] = useState('');
  const { data: companies } = useSearchCompany(searchText);
  const [companyId, setCompanyId] = useState(0);
  const [units, setUnits] = useState('');
  const [gav, setGav] = useState('');

  const handleAddPortfolioCompany = () => {
    if (units === '' || gav === '') return;
    portfoliosClient
      .addCompany({
        portfolioId: currentPortfolioId,
        companyId: companyId,
        units: parseFloat(units),
        gav: parseFloat(gav),
      })
      .then(() => {
        setCompanyId(0);
        setUnits('');
        setGav('');
        onClose();
        refetchPortfolios();
        queryClient.invalidateQueries();
      });
  };

  return (
    <>
      <IconButton
        aria-label='Add portfolio company'
        icon={<VscBriefcase size={21} />}
        colorScheme='ghost'
        variant='solid'
        color='white'
        _hover={{ color: 'termos.orange' }}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} id='createportfolio'>
        <ModalOverlay />
        <ModalContent borderWidth={2} borderColor='termos.gray'>
          <Box bgColor='termos.gray' h={9}>
            <Text
              color='white'
              fontSize='md'
              fontWeight='bold'
              ml='22px'
              mt='4px'
            >
              Add company
            </Text>
          </Box>
          <ModalBody bgColor='black' color='termos.orange'>
            <Center mb={4}>
              <Box>
                <Text color='white'>Company</Text>
                <TermosSelect
                  title=''
                  options={companies?.map((c) => ({
                    value: c.id.toString(),
                    label: `${c.ticker} - ${c.name}`,
                  }))}
                  value={companyId.toString()}
                  onInputChange={(e) => setSearchText(e)}
                  onChange={(e) => {
                    e?.value && setCompanyId(parseInt(e?.value));
                  }}
                />
              </Box>
            </Center>
            <Center>
              <Flex>
                <Box mr={6}>
                  <Text color='white'>Units</Text>
                  <Input
                    color='black'
                    bgColor='termos.orange'
                    border={0}
                    _focus={{ outline: 'none' }}
                    _hover={{ outline: 'none' }}
                    w='162px'
                    onChange={(e) => setUnits(e.target.value)}
                    value={units}
                    type='text'
                  />
                </Box>
                <Box>
                  <Text color='white'>Gav</Text>
                  <Input
                    color='black'
                    bgColor='termos.orange'
                    border={0}
                    _focus={{ outline: 'none' }}
                    _hover={{ outline: 'none' }}
                    w='162px'
                    onChange={(e) => setGav(e.target.value)}
                    value={gav}
                    type='text'
                  />
                </Box>
              </Flex>
            </Center>
          </ModalBody>

          <ModalFooter bgColor='black'>
            <Button
              bgColor='termos.gray'
              color='white'
              onClick={onClose}
              mr={4}
            >
              Close
            </Button>
            <Button
              bgColor='termos.gray'
              color='white'
              onClick={handleAddPortfolioCompany}
            >
              Add
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
