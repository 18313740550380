import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import { Box, Center, Text } from '@chakra-ui/layout';
import {
  Button,
  Collapse,
  Flex,
  Icon,
  IconButton,
  Spinner,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import { VscChevronDown, VscChevronRight, VscError } from 'react-icons/vsc';
import { ScreenFilter } from '../../ApiClients';
import { Filter } from './Filter';
import { v4 as uuidv4 } from 'uuid';
import { useScreenFilterCompanies } from '../../hooks/screen';
import { HelpModal } from './HelpModal';
import { ScreenTable } from './ScreenTable';
import { useEffectOnce } from '../../hooks';
import { SCRN } from '../../routes';

export const Screen: React.FC = () => {
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const [filters, setFilters] = useState<ScreenFilter[]>([]);
  const bottomRef = useRef<HTMLDivElement>(null);
  // const bottomIsVisible = useOnScreen(bottomRef);
  const currentResultsOnPage = 100;

  useEffectOnce(() => {
    document.title = `Termos - ${SCRN}`;
  });

  const [state, setState] = useState({
    sortingModel: '',
    sortingValue: '',
    sortingDesc: true,
    currentPageNr: 1,
    doSearch: false,
    searchId: uuidv4(),
  });

  const { data, isLoading, isFetching, fetchNextPage, remove } =
    useScreenFilterCompanies(state.searchId, state.doSearch, {
      sortingModel: state.sortingModel,
      sortingValue: state.sortingValue,
      sortingDesc: state.sortingDesc,
      pageNumber: state.currentPageNr,
      resultsOnPage: currentResultsOnPage,
      screenFilters: filters,
    });

  const handleAddFilter = () => {
    setFilters((prev) => [
      ...prev,
      {
        uid: uuidv4(),
        filterMetricType: '',
        filterMetricValue: '',
        filterMetricModel: '',
        operator: '',
        inputFrom: '',
        inputTo: '',
      },
    ]);
  };

  const handleFilterUpdate = (filter: ScreenFilter) => {
    setFilters((prev) => prev.map((f) => (f.uid === filter.uid ? filter : f)));
  };

  const handleDeleteFilter = (uid: string) => {
    setFilters((prev) => prev.filter((f) => f.uid !== uid));
  };

  const handleSearch = () => {
    remove();
    onToggle();
    setState((prev) => ({
      ...prev,
      currentPageNr: 1,
      doSearch: true,
      searchId: uuidv4(),
    }));
  };

  const handleSorting = (model: string, value: string) => {
    setState((prev) => ({
      sortingModel: model,
      sortingValue: value,
      sortingDesc: prev.sortingValue === value ? !prev.sortingDesc : true,
      currentPageNr: 1,
      doSearch: true,
      searchId: uuidv4(),
    }));
  };

  /*const moreCompaniesToLoad =
    currentPageNr * currentResultsOnPage <
    (totalNumberOfCompanies ? totalNumberOfCompanies : 0);*/

  /*useEffect(() => {
    if (bottomIsVisible && moreToLoad) {
      setCurrentPageNr((prev) => prev + 1);
    }
  }, [bottomIsVisible, moreToLoad]);*/

  // const hasCompanies = screenCompanies && screenCompanies.length > 0;

  useEffect(() => {
    if (state.currentPageNr > 1) fetchNextPage();
  }, [state.currentPageNr, fetchNextPage]);

  return (
    <Box>
      <Box bgColor='termos.beige' w='100%' h={9} onClick={onToggle}>
        <Flex>
          <Center>
            {isOpen ? (
              <Icon as={VscChevronDown} w={7} h={7} color='white' />
            ) : (
              <Icon as={VscChevronRight} w={7} h={7} color='white' />
            )}
          </Center>
          <Text fontSize='28' color='white'>
            Select filters ({state.currentPageNr})/(
            {data && data.pages[0].totalNumberOfCompanies})
          </Text>
        </Flex>
      </Box>
      <Collapse in={isOpen} animateOpacity>
        {filters?.map((f, i) => (
          <Flex key={i}>
            <Filter filter={f} handleFilterUpdate={handleFilterUpdate} />
            <IconButton
              aria-label='Delete filter'
              as={VscError}
              w={7}
              h={7}
              mt={10}
              ml={4}
              color='termos.darkred'
              colorScheme='ghost'
              onClick={() => handleDeleteFilter(f.uid)}
            />
          </Flex>
        ))}
        <Flex>
          <Box mt={4}>
            <Button
              leftIcon={<AddIcon />}
              colorScheme='ghost'
              variant='solid'
              onClick={handleAddFilter}
            >
              Add another filter
            </Button>
          </Box>
          <Box mt={4} ml={16}>
            <Button
              leftIcon={<SearchIcon />}
              colorScheme='ghost'
              variant='solid'
              onClick={handleSearch}
            >
              Search
            </Button>
          </Box>
          <Box mt={4} ml={16}>
            <HelpModal />
          </Box>
        </Flex>
      </Collapse>
      <Box bgColor='termos.red' w='100%' h={9} mt={6}>
        <Text fontSize='28' color='white' ml={2}>
          Results
        </Text>
      </Box>
      <Box mt={6} mb={6}>
        <ScreenTable
          data={data}
          currentResultsOnPage={currentResultsOnPage}
          handleSorting={handleSorting}
          sortingValue={state.sortingValue}
          descriptionFilters={filters.filter(
            (f) => f.filterMetricValue === 'description'
          )}
        />
      </Box>
      {isLoading && (
        <Center mt={6}>
          <Spinner size='lg' color='termos.orange' />
        </Center>
      )}
      {data &&
        data.pages[0].totalNumberOfCompanies >
          state.currentPageNr * currentResultsOnPage && (
          <Center mt={6} mb={6}>
            <Button
              leftIcon={<AddIcon />}
              colorScheme='ghost'
              variant='solid'
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  currentPageNr: prev.currentPageNr + 1,
                }))
              }
              isLoading={isFetching}
            >
              Load more companies
            </Button>
          </Center>
        )}
      <Box ref={bottomRef} h={1} bgColor='black'></Box>
    </Box>
  );
};
