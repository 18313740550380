import { Box } from '@chakra-ui/layout';
import { Grid } from '@chakra-ui/react';
import React, { useEffect } from 'react';
import { Commodities } from './Commodities';
import { DarkIndex } from './DarkIndex';
import { Industries } from './Industries';
import { Intrest } from './Intrest';
import { LatestNews } from './LatestNews';
import { MajorIndices } from './MajorIndices';
import { Multpl } from './Multpl';
import { Sectors } from './Sectors';

export const Dashboard: React.FC = () => {
  useEffect(() => {
    document.title = `Termos - Dashboard`;
  }, []);

  return (
    <Box h='100%'>
      <Grid templateColumns='repeat(5, 1fr)' gap={0} h='700px' mb={4}>
        <Box w='100%'>
          <Intrest />
        </Box>
        <Box w='100%'>
          <Commodities />
        </Box>
        <Box w='100%'>
          <MajorIndices />
        </Box>
        <Box w='100%'>
          <Sectors />
          <DarkIndex />
          <Multpl />
        </Box>
        <Box w='100%'>
          <Industries />
        </Box>
      </Grid>
      <Box pb={4}>
        <LatestNews />
      </Box>
    </Box>
  );
};
