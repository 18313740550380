export const intrestingFunds = [
  'scion-asset-management-llc',
  'towle-co',
  'greenlight-capital-inc',
  'baupost-group-llc-ma',
  'pamet-capital-management-lp',
  'appaloosa-management-lp',
  'weber-alan-w',
  'icahn-carl-c-et-al',
  'berkshire-hathaway-inc',
  'lapides-asset-management-llc',
  'perceptive-advisors-llc',
  'ares-management-llc',
  'oaktree-capital-management-llc',
  'mhr-fund-management-llc',
  'hussman-econometrics-advisors-inc',
  'third-avenue-management-llc',
  'walthausen-amp-co-llc',
  'portolan-capital-management-llc',
  'gavekal-capital-llc',
  'mcclain-value-management-llc',
  'signia-capital-management-llc',
  'bernzott-capital-advisors',
  'sheffield-asset-management',
  'hodges-capital-management-inc',
  'weitz-wallace-r-co',
  'intrepid-capital-management-inc',
  'old-west-investment-management-llc',
  'luminus-management-llc',
  'o-shaughnessy-asset-management-llc',
  'kopernik-global-investors-llc',
];
