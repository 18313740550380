import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { termosColors } from '../..';
import { AdminCompaniesClient, AdminPriceAlertsClient } from '../../ApiClients';
import { useInterval } from '../../hooks/useInterval';

const adminCompaniesClient = new AdminCompaniesClient();
const adminPriceAlertsClient = new AdminPriceAlertsClient();

export const Company: React.FC = () => {
  const [shardar, setShardar] = useState({
    isSyncing: false,
    ok: false,
    err: false,
  });

  const [plvCalc, setPlvCalc] = useState({
    isCalcing: false,
    ok: false,
    err: false,
  });

  const [priceAlert, setPriceAlert] = useState({
    isCalcing: false,
    ok: false,
    err: false,
  });

  const [isSyncingYhEmpsDesc, setIsSyncingYhEmpsDesc] = useState(true);
  const [yhEmpsDescOk, setYhEmpsDescOk] = useState(false);
  const [yhEmpsDescErr, setYhEmpsDescErr] = useState(false);
  const [yhEmpsDescStatusText, setYhEmpsDescStatusText] = useState('');
  const [time, setTime] = useState(0);
  const [isPaused, setPaused] = useState(false);
  const [fromFile, setFromFile] = useState(true);

  const calcPlv = () => {
    setPlvCalc({
      isCalcing: true,
      ok: false,
      err: false,
    });
    adminCompaniesClient
      .calculatePLV()
      .then(() => {
        setPlvCalc((prev) => ({
          ...prev,
          isCalcing: false,
          ok: true,
        }));
      })
      .catch(() => {
        setPlvCalc((prev) => ({
          ...prev,
          isCalcing: false,
          err: true,
        }));
      });
  };

  const syncShardar = () => {
    setShardar({
      isSyncing: true,
      ok: false,
      err: false,
    });
    adminCompaniesClient
      .syncShardar()
      .then(() => {
        setShardar((prev) => ({
          ...prev,
          isSyncing: false,
          ok: true,
        }));
      })
      .catch(() => {
        setShardar((prev) => ({
          ...prev,
          isSyncing: false,
          err: true,
        }));
      });
  };

  const fetchYhEmpsDescStatusText = () => {
    adminCompaniesClient.getYahooFinanceEmpsAndDescStatus().then((res) => {
      if (res !== '') {
        setIsSyncingYhEmpsDesc(true);
        setYhEmpsDescStatusText(res);
        if (isPaused) setPaused(false);
      } else {
        setPaused(true);
        setIsSyncingYhEmpsDesc(false);
      }
    });
  };

  useInterval(
    () => {
      setTime(time + 1);
      fetchYhEmpsDescStatusText();
    },
    isPaused ? null : 2000
  );

  const getYhEmpsDesc = () => {
    setIsSyncingYhEmpsDesc(true);
    setPaused(false);
    setYhEmpsDescOk(false);
    setYhEmpsDescErr(false);
    adminCompaniesClient
      .syncEmpsAndDesc(fromFile)
      .then(() => {
        setIsSyncingYhEmpsDesc(false);
        setYhEmpsDescOk(true);
        setPaused(true);
      })
      .catch(() => {
        setIsSyncingYhEmpsDesc(false);
        setYhEmpsDescErr(true);
        setPaused(true);
      });
  };

  const checkPriceAlert = () => {
    setPriceAlert({
      isCalcing: true,
      ok: false,
      err: false,
    });
    adminPriceAlertsClient
      .checkPrices()
      .then(() => {
        setPriceAlert((prev) => ({
          ...prev,
          isCalcing: false,
          ok: true,
        }));
      })
      .catch(() => {
        setPriceAlert((prev) => ({
          ...prev,
          isCalcing: false,
          err: true,
        }));
      });
  };

  return (
    <Box p={6}>
      <Box border='1px' borderColor='black' p={2} w='100%' mb={8}>
        <Text color='black' fontWeight='bold' mb={2}>
          Sync companies
        </Text>
        <Button onClick={syncShardar} isLoading={shardar.isSyncing} mr={4}>
          GO
        </Button>
        {shardar.ok && <CheckIcon w={6} h={6} color={termosColors.darkgreen} />}
        {shardar.err && <CloseIcon w={5} h={5} color={termosColors.darkred} />}
      </Box>
      <Box border='1px' borderColor='black' p={2} w='100%' mb={8}>
        <Flex mb={2}>
          <Text color='black' fontWeight='bold' mr={2}>
            Sync company employees and description
          </Text>
          <RadioGroup
            onChange={(e) => setFromFile(e === 'true')}
            value={fromFile.toString()}
          >
            <Stack direction='row'>
              <Radio value='true'>FI</Radio>
              <Radio value='false'>YH</Radio>
            </Stack>
          </RadioGroup>
        </Flex>
        <Button onClick={getYhEmpsDesc} isLoading={isSyncingYhEmpsDesc} mr={4}>
          GO
        </Button>
        {yhEmpsDescOk && (
          <CheckIcon w={6} h={6} color={termosColors.darkgreen} mr={4} />
        )}
        {yhEmpsDescErr && (
          <CloseIcon w={5} h={5} color={termosColors.darkred} mr={4} />
        )}
        {yhEmpsDescStatusText}
      </Box>
      <Box border='1px' borderColor='black' p={2} w='100%' mb={8}>
        <Text color='black' fontWeight='bold' mb={2}>
          Calculate PLV
        </Text>
        <Button onClick={calcPlv} isLoading={plvCalc.isCalcing} mr={4}>
          GO
        </Button>
        {plvCalc.ok && <CheckIcon w={6} h={6} color={termosColors.darkgreen} />}
        {plvCalc.err && <CloseIcon w={5} h={5} color={termosColors.darkred} />}
      </Box>
      <Box border='1px' borderColor='black' p={2} w='100%'>
        <Text color='black' fontWeight='bold' mb={2}>
          Check prices for alerts (daily)
        </Text>
        <Button
          onClick={checkPriceAlert}
          isLoading={priceAlert.isCalcing}
          mr={4}
        >
          GO
        </Button>
        {priceAlert.ok && (
          <CheckIcon w={6} h={6} color={termosColors.darkgreen} />
        )}
        {priceAlert.err && (
          <CloseIcon w={5} h={5} color={termosColors.darkred} />
        )}
      </Box>
    </Box>
  );
};
