import { RecommendationTrend, Trend } from '../../../ApiClients';
import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { termosColors } from '../../..';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { Box } from '@chakra-ui/layout';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

type Right = 'right';
const right: Right = 'right';

export const options = {
  color: 'white',
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Bar Chart - Stacked',
    },
    datalabels: {
      color: 'black',
      font: {
        family: 'monospace',
      },
    },
    legend: {
      position: right,
      reverse: true,
      labels: {
        font: {
          family: 'monospace',
        },
      },
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: 'white',
        font: {
          family: 'monospace',
        },
      },
    },
    y: {
      stacked: true,
      ticks: {
        color: 'white',
        font: {
          family: 'monospace',
        },
      },
    },
  },
};

interface Props {
  recommendationTrend: RecommendationTrend | undefined;
}

export const RecommendationTrendChart: React.FC<Props> = ({
  recommendationTrend,
}) => {
  const recTrend = recommendationTrend?.trend;
  if (!recommendationTrend || !recTrend) return <></>;

  const trend: Trend[] = [];
  for (let i = recTrend.length - 1; i >= 0; i--) {
    trend.push(recTrend[i]);
  }

  const strongBuy = trend.map((t) => t.strongBuy).filter((x) => x !== 0);
  const buy = trend.map((t) => t.buy).filter((x) => x !== 0);
  const hold = trend.map((t) => t.hold).filter((x) => x !== 0);
  const sell = trend.map((t) => t.sell).filter((x) => x !== 0);
  const strongSell = trend.map((t) => t.strongSell).filter((x) => x !== 0);

  const periodMonth = (period: string | undefined) => {
    if (!period) return '-';
    const now = new Date();
    return new Date(
      now.setMonth(now.getMonth() + parseInt(period.replace('m', '')))
    ).toLocaleString('default', { month: 'long' });
  };

  const data = {
    labels: trend.map((t) => periodMonth(t.period)),
    datasets: [
      {
        label: 'Strong Sell',
        data: strongSell,
        backgroundColor: termosColors.red,
      },
      {
        label: 'Sell',
        data: sell,
        backgroundColor: termosColors.orange,
      },
      {
        label: 'Hold',
        data: hold,
        backgroundColor: termosColors.yellow,
      },

      {
        label: 'Buy',
        data: buy,
        backgroundColor: termosColors.green,
      },
      {
        label: 'Strong Buy',
        data: strongBuy,
        backgroundColor: termosColors.darkgreen,
      },
    ],
  };

  return (
    <Box border='1px' borderColor='termos.gray' p={2}>
      <Bar options={options} data={data} height='120px' width='300px' />
    </Box>
  );
};
