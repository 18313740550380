import { Box, Flex, Text } from '@chakra-ui/react';
import { Company } from '../../../ApiClients';
import { useGetUpgradeDowngradeHistory } from '../../../hooks/yahoofinance';
import { formatUnix } from '../../../utils/date';

interface UpgradeDowngradeHistoryProps {
  company: Company;
}

export const UpgradeDowngradeHistory: React.FC<UpgradeDowngradeHistoryProps> =
  ({ company }) => {
    const { data: quoteSummary } = useGetUpgradeDowngradeHistory(
      company.ticker ? company.ticker : ''
    );

    if (
      !quoteSummary ||
      !quoteSummary.result ||
      quoteSummary.result.length === 0
    )
      return <>Could not get Upgrade Downgrade History</>;

    const upgradeDowngradeHistory =
      quoteSummary.result[0].upgradeDowngradeHistory;

    const actionColor = (action: string | undefined) => {
      if (action) {
        if (action === 'UP') return 'termos.green';
        else if (action === 'DOWN') return 'termos.red';
        else return 'termos.orange';
      }

      return 'termos.orange';
    };

    const toGradeColor = (toGrade: string | undefined) => {
      const positiveGrades = [
        'Outperform',
        'Buy',
        'Sector Outperform',
        'Positive',
        'Overweight',
      ];
      const negativeGrades = ['Underperform', 'Sell', 'Underweight'];
      if (toGrade) {
        if (positiveGrades.includes(toGrade)) return 'termos.green';
        else if (negativeGrades.includes(toGrade)) return 'termos.red';
        else return 'termos.orange';
      }

      return 'termos.orange';
    };

    return (
      <Box mt={4} border='1px' p={2} borderColor='termos.gray' color='white'>
        <Text color='white' mb={2}>
          Upgrade Downgrade History
        </Text>
        <Box h='240px' overflowY='auto' color='termos.orange'>
          {upgradeDowngradeHistory?.history?.map((h, i) => (
            <Flex
              key={i}
              h='30px'
              bgColor={i % 2 ? 'termos.darkorange' : 'black'}
            >
              <Box
                w='100px'
                pl={1}
                pr={1}
                pt='5px'
                color={actionColor(h.action?.toUpperCase())}
              >
                {h.action?.toUpperCase()}
              </Box>
              <Box w='220px' pl={1} pr={1} pt='5px'>
                {h.firm}
              </Box>
              <Box w='300px' pl={1} pr={1} pt='5px'>
                {`${h.fromGrade} ${h.fromGrade ? 'to' : ''} `}
                <Box display='inline' color={toGradeColor(h.toGrade)}>
                  {h.toGrade}
                </Box>
              </Box>
              <Box w='80px' pt='5px'>
                {formatUnix(h.epochGradeDate)}
              </Box>
            </Flex>
          ))}
        </Box>
      </Box>
    );
  };
