import { Box, Button, Input, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { CompanyIndicesClient } from '../../ApiClients';
import { TermosSelect } from '../../components/TermosSelect';
import { useGetCompanyIndices } from '../../hooks';

const companyIndicesClient = new CompanyIndicesClient();

export const Index: React.FC = () => {
  const { data: indices } = useGetCompanyIndices();
  const [selectedIndex, setSelectedIndex] = useState('');
  const [file, setFile] = useState<File | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [err, setErr] = useState(false);

  const handleSyncIndex = () => {
    setIsLoading(true);
    setErr(false);
    companyIndicesClient
      .syncIndices(
        { data: file, fileName: file?.name ? file.name : '' },
        selectedIndex
      )
      .then(() => {
        setSelectedIndex('');
        setFile(undefined);
        setIsLoading(false);
      })
      .catch(() => {
        setSelectedIndex('');
        setFile(undefined);
        setIsLoading(false);
        setErr(true);
      });
  };

  return (
    <Box>
      <Text color='black'>Select Index</Text>
      <TermosSelect
        title=''
        options={indices?.map((i) => ({
          value: i.extId,
          label: i.name,
        }))}
        value={selectedIndex}
        onChange={(opt) => setSelectedIndex(opt?.value ? opt?.value : '')}
      />
      <Box h={4} />
      <Input
        type='file'
        border={0}
        p={0}
        disabled={selectedIndex === ''}
        onChange={(e) =>
          setFile(e.target.files ? e.target.files[0] : undefined)
        }
      />
      <Box h={2} />
      {err && 'ERROR!'}
      <Button
        disabled={file === undefined}
        onClick={handleSyncIndex}
        isLoading={isLoading}
      >
        Sync index
      </Button>
    </Box>
  );
};
