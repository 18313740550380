import { Table, Td, Thead, Tooltip } from '@chakra-ui/react';
import { Th, Tr } from '@chakra-ui/table';
import { ReactElement } from 'react';

interface TTableProps {
  children: number | string | ReactElement | ReactElement[];
  hasBorder?: boolean;
}

export const TTable: React.FC<TTableProps> = ({ children, hasBorder }) => {
  return (
    <Table
      variant='simple'
      color='termos.orange'
      border={hasBorder ? '1px' : ''}
      borderColor={hasBorder ? 'termos.gray' : ''}
    >
      {children}
    </Table>
  );
};

interface TStickyHeadProps {
  children: number | string | ReactElement | ReactElement[];
}

export const TStickyHead: React.FC<TStickyHeadProps> = ({ children }) => {
  return (
    <Thead bgColor='termos.beige' position='sticky' top='0'>
      {children}
    </Thead>
  );
};

interface TTrProps {
  children: number | string | ReactElement | ReactElement[] | any;
  i: number;
  hidden?: boolean;
  hover?: boolean;
}

export const TTr: React.FC<TTrProps> = ({ children, i, hidden, hover }) => {
  return (
    <Tr
      key={i}
      bgColor={i % 2 ? 'termos.darkorange' : 'black'}
      hidden={hidden}
      _hover={{ bgColor: hover ? 'termos.darkblue' : '' }}
    >
      {children}
    </Tr>
  );
};

interface TThProps {
  children: number | string | ReactElement | ReactElement[];
  color?: string;
  i?: number;
}

export const TTh: React.FC<TThProps> = ({ children, color = 'white' }) => {
  return (
    <Th color={color} border={0} pl={2.5}>
      {children}
    </Th>
  );
};

interface TTdProps {
  children: undefined | number | string | ReactElement | ReactElement[] | any;
  color?: string;
  i?: number;
  onClick?: () => void;
  colSpan?: number;
  p?: number;
}

export const TinyTd: React.FC<TTdProps> = ({
  children,
  color,
  onClick,
  colSpan,
  p,
}) => {
  return (
    <Td
      border={0}
      pr={0}
      pt={0}
      pb={0}
      pl={2.5}
      textOverflow='ellipsis'
      overflow='hidden'
      whiteSpace='nowrap'
      color={color}
      onClick={onClick}
      colSpan={colSpan}
      p={p}
    >
      {children}
    </Td>
  );
};

interface TTooltipProps {
  children: undefined | number | string | ReactElement | ReactElement[];
  label: string | undefined | ReactElement | ReactElement[];
}

export const TTooltip: React.FC<TTooltipProps> = ({ children, label }) => {
  return (
    <Tooltip
      color='black'
      bgColor='white'
      label={label}
      placement='top'
      shouldWrapChildren
    >
      <>{children}</>
    </Tooltip>
  );
};
