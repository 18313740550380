import { Flex, Text } from '@chakra-ui/layout';
import React from 'react';
import { termosColors } from '..';

interface Props {
  label: string | undefined;
  text: string | number | undefined;
  color?: string;
}

export const ColorTextedLabel: React.FC<Props> = ({
  label,
  text,
  color = termosColors.orange,
}) => {
  return (
    <Flex>
      <Text color='white' mr={2}>
        {label}
      </Text>
      <Text color={color}>{text}</Text>
    </Flex>
  );
};
