import { Box } from '@chakra-ui/layout';
import {
  Thead,
  Tr,
  Tbody,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  useDisclosure,
  Text,
  ModalCloseButton,
} from '@chakra-ui/react';
import React from 'react';
import { Fundamental } from '../../ApiClients';
import {
  TinyTd,
  TStickyHead,
  TTable,
  TTh,
  TTooltip,
  TTr,
} from '../../components/TTable';
import { useGetFundamentalKeys } from '../../hooks/fundamental';
import { formatYearSpecial } from '../../utils/date';
import { formatNumber } from '../../utils/number';
import {
  allFundamentalKeys,
  fundamentalColorRules,
  selectedKeys,
} from './utils';

interface Props {
  fundamentals: Fundamental[] | undefined;
}

export const Fundamentals: React.FC<Props> = ({ fundamentals }) => {
  const { data: fundamentalKeys } = useGetFundamentalKeys();

  if (!fundamentalKeys) return <>fant ikke fundamental keys</>;

  return (
    <Box pb={16} maxW='820px'>
      <TTable hasBorder>
        <Thead bgColor='termos.beige'>
          <Tr>
            <TTh>
              <AllFundamentals fundamentals={fundamentals} />
            </TTh>
            {fundamentals?.map((f, i) => (
              <TTh key={i}>
                {`${formatYearSpecial(f.calendardate)} (${f.dimension})`}
              </TTh>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {selectedKeys.map((sk, i) => (
            <TTr key={i} i={i}>
              <TinyTd>
                <TTooltip
                  label={
                    <Box p={2}>
                      <Box mb={2}>
                        {fundamentalKeys.find((fk) => fk.value === sk)?.name}
                      </Box>
                      <Box>
                        {
                          fundamentalKeys.find((fk) => fk.value === sk)
                            ?.description
                        }
                      </Box>
                    </Box>
                  }
                >
                  {sk}
                </TTooltip>
              </TinyTd>
              {fundamentals?.map((f, j) => (
                <TinyTd
                  key={j}
                  color={fundamentalColorRules(
                    sk,
                    f[sk as keyof Fundamental] as number
                  )}
                >
                  {formatNumber(f[sk as keyof Fundamental] as number)}
                </TinyTd>
              ))}
            </TTr>
          ))}
        </Tbody>
      </TTable>
    </Box>
  );
};

const AllFundamentals: React.FC<Props> = ({ fundamentals }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: fundamentalKeys } = useGetFundamentalKeys();

  if (!fundamentalKeys) return <>fant ikke fundamental keys</>;

  return (
    <>
      <Text onClick={onOpen} _hover={{ cursor: 'pointer' }}>
        Year
      </Text>
      <Modal isOpen={isOpen} onClose={onClose} size='full'>
        <ModalOverlay />
        <ModalContent borderWidth={2} borderColor='termos.gray'>
          <Box bgColor='termos.gray' h={9}>
            <Text
              color='white'
              fontSize='md'
              fontWeight='bold'
              ml='22px'
              mt='4px'
            >
              Fundamentals
            </Text>
            <ModalCloseButton />
          </Box>
          <ModalBody bgColor='black'>
            <Box h='720px' overflowY='auto'>
              <TTable hasBorder>
                <TStickyHead>
                  <Tr>
                    <TTh>Year</TTh>
                    {fundamentals?.map((f, i) => (
                      <TTh key={i}>
                        {`${formatYearSpecial(f.calendardate)} (${
                          f.dimension
                        })`}
                      </TTh>
                    ))}
                  </Tr>
                </TStickyHead>
                <Tbody>
                  {allFundamentalKeys.map((sk, i) => (
                    <TTr key={i} i={i} hover>
                      <TinyTd>
                        <TTooltip
                          label={
                            <Box p={2}>
                              <Box mb={2}>
                                {
                                  fundamentalKeys.find((fk) => fk.value === sk)
                                    ?.name
                                }
                              </Box>
                              <Box>
                                {
                                  fundamentalKeys.find((fk) => fk.value === sk)
                                    ?.description
                                }
                              </Box>
                            </Box>
                          }
                        >
                          {fundamentalKeys.find((fk) => fk.value === sk)?.name}
                        </TTooltip>
                      </TinyTd>
                      {fundamentals?.map((f, j) => (
                        <TinyTd
                          key={j}
                          color={fundamentalColorRules(
                            sk,
                            f[sk as keyof Fundamental] as number
                          )}
                        >
                          {formatNumber(f[sk as keyof Fundamental] as number)}
                        </TinyTd>
                      ))}
                    </TTr>
                  ))}
                </Tbody>
              </TTable>
            </Box>
          </ModalBody>
          <ModalFooter bgColor='black'>
            <Button
              bgColor='termos.gray'
              color='white'
              onClick={onClose}
              mr={4}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
