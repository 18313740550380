import { Box, Grid, GridItem, Heading } from '@chakra-ui/react';
import React from 'react';
import { termosColors } from '../..';
import { TBarChart } from '../../components/TBarChart';
import { useGetPortfolioWinnLoss } from '../../hooks/portfolios';
import { PortfolioBenchmark } from './PortfolioBenchmark';

interface Props {
  portfolioId: number;
}

export const PortfolioAnalysis: React.FC<Props> = ({ portfolioId }) => {
  const { data: winloss } = useGetPortfolioWinnLoss(portfolioId);

  const bgColors = () => {
    const colors = [];
    for (let i = 1; i <= 10; i++) {
      if (i <= 5) colors.push(termosColors.darkred);
      else colors.push(termosColors.darkgreen);
    }
    return colors;
  };

  const data = {
    labels: [
      '<(1000)',
      '(1000)-(750)',
      '(750)-(500)',
      '(500)-(250)',
      '(250)-0',
      '0-250',
      '250-500',
      '500-750',
      '750-1000',
      '>1000',
    ],
    datasets: [
      {
        data: winloss ? winloss : [],
        backgroundColor: bgColors(),
        borderWidth: 0.6,
      },
    ],
  };

  return (
    <Box h='640px' border='1px' borderColor='termos.gray'>
      <Grid templateColumns='repeat(3, 1fr)' h='100%'>
        <GridItem colSpan={2} p={2} borderRight='1px' borderColor='termos.gray'>
          <Heading color='white'>Winning/losing days 1 year</Heading>
          <TBarChart data={data} />
        </GridItem>
        <GridItem p={2} borderLeft='1px' borderColor='termos.gray'>
          <Heading color='white'>Benchmark 1 year</Heading>
          <PortfolioBenchmark portfolioId={portfolioId} />
        </GridItem>
      </Grid>
    </Box>
  );
};
