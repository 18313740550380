import { useQuery } from 'react-query';
import { Multpl, MultplClient } from '../ApiClients';

const multplClient = new MultplClient();

const noRefetch = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const useGetUsInflationRate = () =>
  useQuery<Multpl>(
    'multplClient-usinflation',
    () => multplClient.getUsInflationRate(),
    noRefetch
  );

export const useGetShillerPe = () =>
  useQuery<Multpl>(
    'multplClient-shillerpe',
    () => multplClient.getShillerPe(),
    noRefetch
  );
