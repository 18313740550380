import { InfoIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/layout';
import {
  Heading,
  Flex,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  IconButton,
  Link,
  Collapse,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { useGetCompany } from '../../hooks';
import { CcFundamental } from './CcFundamental';
import { CompanyAnalysis } from './analysis/CompanyAnalysis';
import { CompanyTags } from './CompanyTags';
import { Description } from './Description';
import { Fundamentals } from './Fundamentals';
import { Infos } from './Infos';
import { Links } from './Links';
import { Management } from './Management';
import { News } from './News';
import { OpenInsiders } from './OpenInsiders';
import { Prices } from './Prices';
import { Ratios } from './Ratios';
import { Risk } from './Risk';
import { SecFilings } from './SecFilings';
import { WhaleWisdoms } from './WhaleWisdoms';
import { useGetOptionWithQuote } from '../../hooks/yahoofinance';
import { Options } from './Options';
import { AddToWatchlist } from '../watchlist/AddToWatchlist';
import { VscPreview } from 'react-icons/vsc';
import { CreatePriceAlert } from './CreatePriceAlert';

interface Props {
  ticker: string;
}

export const Company: React.FC<Props> = ({ ticker }) => {
  const { data: company, refetch } = useGetCompany(ticker);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(true);
  const [tabIndex, setTabIndex] = useState(0);
  const [openInsiderEnabled, setOpenInsiderEnabled] = useState(false);
  const [whaleWisdomEnabled, setWhaleWisdomEnabled] = useState(false);

  useEffect(() => {
    document.title = `Termos - EQ (${ticker.toUpperCase()})`;
  }, [ticker]);

  useEffect(() => {
    if (tabIndex === 2 && !openInsiderEnabled) setOpenInsiderEnabled(true);
    if (tabIndex === 3 && !whaleWisdomEnabled) setWhaleWisdomEnabled(true);
  }, [tabIndex, openInsiderEnabled, whaleWisdomEnabled]);

  if (!company || !company.name) return <>404</>;

  const fundamental =
    company.fundamentals && company.fundamentals.length > 0
      ? company.fundamentals[company.fundamentals.length - 1]
      : undefined;

  const refetchCompany = () => {
    refetch();
  };

  return (
    <Box color='termos.orange'>
      <Flex justifyContent='space-between'>
        <Flex>
          <Heading mb={4} mr={24}>
            {`${company.ticker} - ${company.name} (${company.currency?.value})`}
            <IconButton
              aria-label='Toggle description'
              icon={<InfoIcon />}
              onClick={() => setIsDescriptionOpen((p) => !p)}
              colorScheme='ghost'
              size='lg'
              _hover={{ color: 'termos.orange' }}
              ml={3}
            />
            <IconButton
              aria-label='Toggle preview'
              icon={<VscPreview />}
              onClick={() => setIsPreviewOpen((p) => !p)}
              colorScheme='ghost'
              size='lg'
              _hover={{ color: 'termos.orange' }}
            />
          </Heading>
          <Price ticker={ticker} />
        </Flex>
        <Flex>
          <CreatePriceAlert company={company} />
          <AddToWatchlist companyId={company.id} />
        </Flex>
      </Flex>

      <Description company={company} isOpen={isDescriptionOpen} />

      <Flex>
        <Box mr={4}>
          <Collapse in={isPreviewOpen} animateOpacity>
            <Box maxH='275px' mb={8}>
              <Flex mr={8}>
                <Prices company={company} fundamental={fundamental} />
                <Ratios fundamental={fundamental} />
                <Infos company={company} fundamental={fundamental} />
                <Links company={company} />
              </Flex>
            </Box>
          </Collapse>
          <Tabs
            variant='unstyled'
            mb={4}
            onChange={(index) => setTabIndex(index)}
          >
            <TabList color='white'>
              <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
                Cmp
              </Tab>
              <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
                Opts
              </Tab>
              <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
                Insiders
              </Tab>
              <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
                Ownership
              </Tab>
              <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
                Analysis
              </Tab>
              <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
                Risk
              </Tab>
              <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
                Tags
              </Tab>
              <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
                Management
              </Tab>
              <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
                News
              </Tab>
              <Tab _selected={{ color: 'termos.orange' }} p={0}>
                SEC
              </Tab>
            </TabList>
            <TabPanels>
              <TabPanel p={0}>
                <CcFundamental company={company} />
              </TabPanel>
              <TabPanel p={0}>
                <Options ticker={ticker} />
              </TabPanel>
              <TabPanel p={0}>
                <OpenInsiders
                  ticker={company.ticker ? company.ticker : ''}
                  doFetch={openInsiderEnabled}
                />
              </TabPanel>
              <TabPanel p={0}>
                <WhaleWisdoms ticker={ticker} doFetch={whaleWisdomEnabled} />
              </TabPanel>
              <TabPanel p={0}>
                <CompanyAnalysis
                  company={company}
                  refetchCompany={refetchCompany}
                />
              </TabPanel>
              <TabPanel p={0}>
                <Risk company={company} />
              </TabPanel>
              <TabPanel p={0}>
                <CompanyTags
                  companyId={company.id}
                  tags={company.tags}
                  refetchCompany={refetchCompany}
                />
              </TabPanel>
              <TabPanel p={0}>
                <Management company={company} />
              </TabPanel>
              <TabPanel p={0}>
                <News
                  name={company.name ? company.name : ''}
                  ticker={company.ticker ? company.ticker : ''}
                />
              </TabPanel>
              <TabPanel p={0}>
                <SecFilings company={company} />
              </TabPanel>
            </TabPanels>
          </Tabs>
        </Box>
        <Fundamentals fundamentals={company.fundamentals} />
      </Flex>
    </Box>
  );
};

interface PriceProps {
  ticker: string;
}

const Price: React.FC<PriceProps> = ({ ticker }) => {
  const {
    data: optionWithQuote,
    isFetching,
    refetch,
  } = useGetOptionWithQuote(ticker, true);
  const quote = optionWithQuote?.quote;
  if (isFetching) return <></>;
  if (!quote) return <>Cloud not get price.</>;
  return (
    <Box>
      <Link _hover={{ textDecoration: 'none' }} onClick={() => refetch()}>
        <Flex h='35px'>
          <Text mr={3} fontSize='30px'>
            {quote.regularMarketPrice.toFixed(2)}
          </Text>
          <Flex mt={3}>
            <Text
              mr={3}
              color={
                quote.regularMarketChange < 0 ? 'termos.red' : 'termos.green'
              }
              fontSize='20px'
            >
              {quote.regularMarketChange.toFixed(2)}
            </Text>
            <Text
              color={
                quote.regularMarketChangePercent < 0
                  ? 'termos.red'
                  : 'termos.green'
              }
              fontSize='20px'
            >
              ({quote.regularMarketChangePercent.toFixed(2)}%)
            </Text>
          </Flex>
        </Flex>
      </Link>
    </Box>
  );
};
