import { useQuery } from 'react-query';
import {
  PortfolioBenchmark,
  PortfolioBreakdown,
  PortfolioBreakdownType,
  PortfolioPerformanceChartData,
  PortfolioPerformanceStats,
  PortfoliosClient,
  PortfolioViewModel,
  PPChartDataRangeType,
} from '../ApiClients';

const portfoliosClient = new PortfoliosClient();

export const useGetPortfolios = () =>
  useQuery<PortfolioViewModel[]>('portfolios', () => portfoliosClient.getAll());

export const useGetPortfolioPerformanceChartData = (
  type: PPChartDataRangeType,
  portfolioId: number,
  companyId: number | null | undefined
) =>
  useQuery<PortfolioPerformanceChartData>(
    `PortfolioPerformanceChartData-${type}-${portfolioId}`,
    () =>
      portfoliosClient.getPortfolioPerformanceChartData(
        type,
        portfolioId,
        companyId
      ),
    {
      enabled: portfolioId !== 0,
    }
  );

export const useGetPortfolioPerformanceStats = (
  portfolioId: number,
  companyId: number | null | undefined
) =>
  useQuery<PortfolioPerformanceStats>(
    `PortfolioPerformanceStats-${portfolioId}-${companyId}`,
    () => portfoliosClient.getPortfolioPerformanceStats(portfolioId, companyId),
    {
      enabled: portfolioId !== 0,
    }
  );

export const useGetPortfolioBreakdown = (
  id: number | undefined,
  type: PortfolioBreakdownType
) =>
  useQuery<PortfolioBreakdown>(
    `GetPortfolioBreakdown-${id}-${type}`,
    () => portfoliosClient.getPortfolioBreakdown(id, type),
    {
      enabled: id !== undefined,
    }
  );

export const useGetPortfolioWinnLoss = (portfolioId: number) =>
  useQuery<number[]>(`portfolioWinLoss-${portfolioId}`, () =>
    portfoliosClient.getWinLoss(portfolioId)
  );

export const useGetPortfolioBenchmark = (
  portfolioId: number,
  benchmarkIndexSymbol: string
) =>
  useQuery<PortfolioBenchmark>(
    `portfolioBenchmarl-${portfolioId}-${benchmarkIndexSymbol}`,
    () => portfoliosClient.getBenckmark(portfolioId, benchmarkIndexSymbol),
    {
      enabled: benchmarkIndexSymbol !== '',
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
