import { ExternalLinkIcon } from '@chakra-ui/icons';
import {
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  Box,
  ModalBody,
  Heading,
  Center,
  ModalFooter,
  Button,
  Text,
  Link,
} from '@chakra-ui/react';
import { Readability } from '@mozilla/readability';
import { useState } from 'react';
import { useGetArticle } from '../hooks/news';
import React from 'react';
import parse from 'html-react-parser';

interface Props {
  title: string;
  url: string;
}

export const ArticleModal: React.FC<Props> = ({ title, url }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [doSearch, setDoSearch] = useState(false);
  const { data: articleDocument } = useGetArticle(doSearch, url);

  var parser = new DOMParser();
  var doc = parser.parseFromString(
    articleDocument ? articleDocument : '',
    'text/html'
  );
  var article = new Readability(doc).parse();

  return (
    <>
      <Link
        onClick={() => {
          onOpen();
          setDoSearch(true);
        }}
        fontSize='16px'
      >
        {title}
      </Link>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderWidth={2}
          borderColor='termos.gray'
          maxW='1600px'
          mt={0}
        >
          <Box bgColor='termos.gray' h={9}>
            <Link href={url} isExternal>
              <Text
                color='white'
                fontSize='md'
                fontWeight='bold'
                ml='28px'
                mt='4px'
              >
                {title} <ExternalLinkIcon mx='2px' />
              </Text>
            </Link>
          </Box>

          <ModalBody bgColor='black' color='termos.orange'>
            <Heading>{article?.title}</Heading>
            <Box h='600px' overflowY='auto' pt={2}>
              <Center>
                <Box maxW='800px' fontSize='16px' lineHeight='32px'>
                  {article?.content && parse(article?.content)}
                </Box>
              </Center>
            </Box>
          </ModalBody>

          <ModalFooter bgColor='black'>
            <Button
              bgColor='termos.gray'
              color='white'
              onClick={() => {
                onClose();
                setDoSearch(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
