import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';

export const authMethods = {
  signin: (
    email: string,
    password: string,
    setErrors: any,
    redirect: () => void
  ) => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((res) => {
        const user = res.user;
        if (user) {
          redirect();
        }
      })
      .catch((err) => {
        setErrors((prev: any) => [...prev, err.message]);
      });
  },
  signout: (setErrors: any, redirect: () => void) => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        redirect();
      })
      .catch((err) => {
        setErrors((prev: any) => [...prev, err.message]);
      });
  },
};
