import { useQuery } from 'react-query';
import { FundamentalsClient, FundamentalKey } from '../ApiClients';

const fundamentalsClient = new FundamentalsClient();

export const fundamentalKeysKey = () => 'fundamentalKeys';

export const useGetFundamentalKeys = () =>
  useQuery<FundamentalKey[]>(fundamentalKeysKey(), () =>
    fundamentalsClient.getFundamentalKeys()
  );
