import { Box, Center, Flex, Text } from '@chakra-ui/layout';
import {
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Input,
  Spinner,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { CompaniesClient, Company } from '../../../ApiClients';
import { TermosSelectSmall } from '../../../components/TermosSelectSmall';
import { useGetQuote } from '../../../hooks/yahoofinance';
import { EsgScores } from './EsgScores';
import { FinanceInsight } from './FinanceInsight';
import { FinancialData } from './FinancialData';
import { Technical } from './Technical';
import { UpgradeDowngradeHistory } from './UpgradeDowngradeHistory';

interface Props {
  company: Company;
  refetchCompany: () => void;
}

const companiesClient = new CompaniesClient();
const confidenceScores = ['1', '2', '3', '4', '5', '6'];

export const CompanyAnalysis: React.FC<Props> = ({
  company,
  refetchCompany,
}) => {
  const { data: quote } = useGetQuote(company.ticker ? company.ticker : '');
  const handleScoreSelect = (score: string | undefined) => {
    if (score) {
      try {
        companiesClient
          .setConfidence(company.id, parseInt(score))
          .then((res) => {
            refetchCompany();
          });
      } catch {}
    }
  };
  const [myPrice, setMyPrice] = useState<string | undefined>(
    company.myPrice?.toString()
  );
  const { current } = React.useRef<{ myPrice: string | undefined; timer: any }>(
    { myPrice, timer: null }
  );
  const [savingMyPrice, setSavingMyPrice] = useState(false);

  useEffect(() => {
    setMyPrice(company.myPrice?.toString());
  }, [company.myPrice]);

  const inputChangeHandler = (newPrice: string) => {
    current.myPrice = newPrice;

    if (current.timer) clearTimeout(current.timer);

    current.timer = setTimeout(() => {
      setSavingMyPrice(true);
      current.timer = null;
      companiesClient
        .setMyPrice(
          company.id,
          !current.myPrice ? undefined : parseFloat(current.myPrice)
        )
        .then((res) => {
          refetchCompany();
          setSavingMyPrice(false);
        });
    }, 1000);
  };

  const [currentIncrease, setCurrentIncrease] = useState(0);

  useEffect(() => {
    if (myPrice && quote) {
      const myPriceNumber = parseFloat(myPrice);
      const calc =
        ((myPriceNumber - quote.regularMarketPrice) /
          quote.regularMarketPrice) *
        100;
      setCurrentIncrease(calc);
    }
  }, [myPrice, quote]);

  return (
    <Box border='1px' p={2} borderColor='termos.gray' mt={4} h='600px'>
      <Tabs variant='unstyled'>
        <TabList color='white'>
          <Tab
            _selected={{ color: 'termos.orange' }}
            p={0}
            mr={6}
            mb={4}
            borderBottom='1px'
          >
            Main
          </Tab>
          <Tab
            _selected={{ color: 'termos.orange' }}
            p={0}
            mr={6}
            mb={4}
            borderBottom='1px'
          >
            Insight
          </Tab>
          <Tab
            _selected={{ color: 'termos.orange' }}
            p={0}
            mr={6}
            mb={4}
            borderBottom='1px'
          >
            ESG
          </Tab>
          <Tab
            _selected={{ color: 'termos.orange' }}
            p={0}
            mb={4}
            borderBottom='1px'
          >
            Tech
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <Flex>
              <Box mr={6}>
                <TermosSelectSmall
                  title='Confidence Score'
                  options={confidenceScores.map((t) => ({
                    value: t,
                    label: t,
                  }))}
                  value={
                    company.companyConfidence
                      ? company.companyConfidence.toString()
                      : ''
                  }
                  onChange={(e) => handleScoreSelect(e?.value)}
                />
              </Box>
              <Flex>
                <Box>
                  <Text color='white' display='inline' fontSize='16px' mr={2}>
                    My Price:
                  </Text>
                  <Input
                    color='black'
                    bgColor='termos.orange'
                    textTransform='uppercase'
                    border={0}
                    _focus={{ outline: 'none' }}
                    _hover={{ outline: 'none' }}
                    w='75px'
                    h='38px'
                    value={myPrice ? myPrice : ''}
                    onChange={(e) => {
                      setMyPrice(e.target.value);
                      inputChangeHandler(e.target.value);
                    }}
                    type='number'
                    mr={1}
                  />
                </Box>
                <Center minW='25px' mr={2}>
                  {savingMyPrice && <Spinner color='termos.orange' />}
                </Center>
                <Box>
                  <Text color='white' display='inline' fontSize='16px' mr={2}>
                    Current increase:
                  </Text>
                  <Text
                    display='inline'
                    fontSize='16px'
                    color={
                      currentIncrease > 0
                        ? 'termos.green'
                        : currentIncrease < 0
                        ? 'termos.red'
                        : 'termos.orange'
                    }
                  >
                    {currentIncrease.toFixed(2)}%
                  </Text>
                </Box>
              </Flex>
            </Flex>
            <FinancialData company={company} />
            <UpgradeDowngradeHistory company={company} />
          </TabPanel>
          <TabPanel p={0}>
            <FinanceInsight company={company} />
          </TabPanel>
          <TabPanel p={0}>
            <EsgScores company={company} />
          </TabPanel>
          <TabPanel p={0}>
            <Technical company={company} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
