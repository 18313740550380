import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { App } from './App';
import { extendTheme } from '@chakra-ui/react';
import 'focus-visible/dist/focus-visible';
import { AuthProvider } from './firebase/AuthContext';

export type TermosColors = {
  darkred: string;
  blue: string;
  darkblue: string;
  teal: string;
  orange: string;
  darkorange: string;
  green: string;
  darkgreen: string;
  yellow: string;
  red: string;
  lightgray: string;
  gray: string;
  darkgray: string;
  beige: string;
  darkbeige: string;
};

export const termosColors: TermosColors = {
  darkred: '#8F2313',
  blue: '#598ED2',
  darkblue: '#0B4378',
  teal: '#045A74',
  orange: '#FF9D2A',
  darkorange: '#26231E',
  green: '#14EA14',
  darkgreen: '#0a750a',
  yellow: '#e3c428',
  red: '#D83A37',
  lightgray: '#CCCDCB',
  gray: '#7B808C',
  darkgray: '#6F6E6F',
  beige: '#494849',
  darkbeige: '#38373D',
};

const theme = extendTheme({
  styles: {
    global: {
      p: {
        color: 'termos.orange',
      },
    },
  },
  fonts: {
    heading: 'monospace',
    body: 'monospace',
  },
  colors: {
    termos: termosColors,
  },
  components: {
    Table: {
      variants: {
        simple: {
          th: {
            paddingLeft: 0,
          },
          td: {
            paddingLeft: 0,
          },
        },
      },
    },
    Button: {
      baseStyle: {
        borderRadius: 0,
        textTransform: 'uppercase',
      },
      variants: {
        main: {
          color: '#ED7029',
        },
      },
    },
    Menu: {
      parts: ['list'],
      baseStyle: {
        list: {
          borderRadius: 0,
          padding: 0,
        },
      },
    },
    Modal: {
      parts: ['dialog'],
      baseStyle: {
        dialog: {
          borderRadius: 0,
        },
      },
    },
    Input: {
      sizes: {
        md: {
          field: {
            borderRadius: 0,
          },
          addon: {
            borderRadius: 0,
          },
        },
      },
    },
  },
});

const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </AuthProvider>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
