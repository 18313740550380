import { useQuery } from 'react-query';
import { OpenInsiderRow, OpenInsidersClient } from '../ApiClients';

const openInsidersClient = new OpenInsidersClient();

export const openInsiderRowsKey = (ticker: string) =>
  `openInsiderRowsKey-${ticker}`;

export const useGetOpenInsiderRows = (ticker: string, doFetch: boolean) =>
  useQuery<OpenInsiderRow[]>(
    openInsiderRowsKey(ticker),
    () => openInsidersClient.get(ticker),
    {
      retry: false,
      enabled: doFetch,
      cacheTime: 28800000,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
