import { Box, Text } from '@chakra-ui/layout';
import { Button, Flex } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useGetDuckDuckGoNews } from '../../hooks/news';
import parse from 'html-react-parser';
import { ArticleModal } from '../../components/ArticleModal';

interface Props {
  name: string;
  ticker: string;
}

export const News: React.FC<Props> = ({ name, ticker }) => {
  const [orderTop, setOrderTop] = useState(true);
  const { data: duckDuckGoResults } = useGetDuckDuckGoNews(
    name,
    ticker,
    orderTop
  );

  if (!duckDuckGoResults) return <>kunne ikke hente duck duck go news</>;

  return (
    <Box>
      <Button
        colorScheme='ghost'
        variant='solid'
        color={orderTop ? 'termos.orange' : 'white'}
        p={0}
        mr={8}
        onClick={() => setOrderTop(true)}
      >
        Top
      </Button>
      <Button
        colorScheme='ghost'
        variant='solid'
        p={0}
        onClick={() => setOrderTop(false)}
        color={!orderTop ? 'termos.orange' : 'white'}
      >
        New
      </Button>
      <Box
        border='1px'
        p={2}
        borderColor='termos.gray'
        mt={4}
        h='600px'
        overflowY='auto'
      >
        {duckDuckGoResults.map((d, i) => (
          <Box mb={2} key={i}>
            <Box>
              <ArticleModal
                title={d.title ? d.title : ''}
                url={d.url ? d.url : ''}
              />
            </Box>
            <Box>{parse(d.excerpt ? d.excerpt : '')}</Box>
            <Flex>
              <Text color='white' mr={2}>
                {d.relativeTime}
              </Text>
              <Text color='termos.blue'>{d.source}</Text>
            </Flex>
          </Box>
        ))}
      </Box>
    </Box>
  );
};
