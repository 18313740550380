import { Box } from '@chakra-ui/layout';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { Call, Put } from '../../ApiClients';
import { TermosSelect } from '../../components/TermosSelect';
import { useGetOptionWithQuote } from '../../hooks/yahoofinance';
import { formatUnix } from '../../utils/date';

interface Props {
  ticker: string;
}

export const Options: React.FC<Props> = ({ ticker }) => {
  const [optionsDate, setOptionsDate] = useState<string | undefined>(undefined);
  const { data: optionWithQuote, isFetching } = useGetOptionWithQuote(
    ticker,
    true,
    optionsDate
  );
  const options = optionWithQuote?.options;
  const expirationDates = optionWithQuote?.expirationDates;

  useEffect(() => {
    if (
      options &&
      options.length !== 0 &&
      options[0].expirationDate &&
      !optionsDate
    )
      setOptionsDate(options[0].expirationDate.toString());
  }, [options, optionsDate]);

  if (isFetching) return <></>;
  if (!options) return <>Cloud not get options.</>;
  if (options.length === 0) return <>No options.</>;

  return (
    <Box p={2} mt={4}>
      <TermosSelect
        title=''
        options={expirationDates?.map((d) => ({
          value: d.toString(),
          label: formatUnix(d),
        }))}
        value={optionsDate}
        onChange={(e) => setOptionsDate(e?.value)}
      />
      <Tabs variant='unstyled' mt={4}>
        <TabList color='white'>
          <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
            Calls
          </Tab>
          <Tab _selected={{ color: 'termos.orange' }} p={0}>
            Puts
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0} pt={2}>
            <Box h='550px' overflowY='auto'>
              <OptionTable options={options[0].calls} />
            </Box>
          </TabPanel>
          <TabPanel p={0} pt={2}>
            <Box h='550px' overflowY='auto'>
              <OptionTable options={options[0].puts} />
            </Box>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};

interface OptionTableProps {
  options: Call[] | Put[] | undefined;
}

const OptionTable: React.FC<OptionTableProps> = ({ options }) => {
  const columns = [
    { header: 'Name', row: 'contractSymbol' },
    { header: 'Last Trade Date', row: 'lastTradeDate' },
    { header: 'Strike', row: 'strike' },
    { header: 'Last Price', row: 'lastPrice' },
    { header: 'Bid', row: 'bid' },
    { header: 'Ask', row: 'ask' },
    { header: 'Change', row: 'change' },
    { header: '% Chg', row: 'percentChange' },
    { header: 'VolU', row: 'volume' },
    { header: 'Intr', row: 'openInterest' },
    { header: 'VolA', row: 'impliedVolatility' },
  ];

  const format = (value: any, row: string) => {
    if (row === 'lastTradeDate') return formatUnix(value);
    else if (row === 'contractSymbol') return value.substring(0, 3);
    else if (row === 'openInterest' || row === 'volume') return value;

    return value.toFixed(2);
  };

  const changeColor = (value: any, row: string) => {
    if (row === 'change' || row === 'percentChange') {
      if (value === 0) return 'termos.orange';
      else if (value < 0) return 'termos.red';
      else return 'termos.green';
    }
    return 'termos.orange';
  };

  return (
    <Table
      variant='simple'
      color='termos.orange'
      borderBottom='1px'
      borderLeft='1px'
      borderRight='1px'
      borderColor='termos.gray'
    >
      <Thead bgColor='termos.beige' position='sticky' top='0'>
        <Tr>
          {columns.map((c, i) => (
            <Th color='white' border={0} pl={2.5} key={i}>
              {c.header}
            </Th>
          ))}
        </Tr>
      </Thead>
      <Tbody>
        {options?.map((o, i) => (
          <Tr key={i} bgColor={i % 2 ? 'termos.darkorange' : 'black'}>
            {columns.map((c, i) => (
              <Td
                pl={2.5}
                border={0}
                key={i}
                color={
                  o.inTheMoney && i === 0
                    ? 'termos.green'
                    : changeColor(o[c.row as keyof Call], c.row)
                }
              >
                {format(o[c.row as keyof Call], c.row)}
              </Td>
            ))}
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};
