import React from 'react';
import { Bar } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

interface Props {
  data: ChartData<'bar', any[], string>;
}

export const options = {
  color: 'white',
  plugins: {
    title: {
      display: false,
      text: 'Chart.js Bar Chart - Stacked',
    },
    datalabels: {
      color: 'black',
      font: {
        family: 'monospace',
      },
    },
    legend: {
      display: false,
    },
  },
  responsive: true,
  maintainAspectRatio: true,
  scales: {
    x: {
      stacked: true,
      ticks: {
        color: 'white',
        font: {
          family: 'monospace',
        },
      },
    },
    y: {
      grid: {
        display: true,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        color: '#7B808C',
        borderDash: [4, 2],
      },
      stacked: true,
      ticks: {
        color: 'white',
        font: {
          family: 'monospace',
        },
      },
    },
  },
};

export const TBarChart: React.FC<Props> = ({ data }) => {
  return <Bar options={options} data={data} height='120px' width='300px' />;
};
