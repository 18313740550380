import { Box } from '@chakra-ui/layout';
import { Link } from '@chakra-ui/react';
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/table';
import React from 'react';
import { Company } from '../../ApiClients';
import { useGetSecFilings } from '../../hooks/yahoofinance';

interface Props {
  company: Company;
}

export const SecFilings: React.FC<Props> = ({ company }) => {
  const { data: quoteSummary } = useGetSecFilings(
    company.ticker ? company.ticker : ''
  );

  if (!quoteSummary || !quoteSummary.result || quoteSummary.result.length === 0)
    return <>Could not get Sec Filings</>;

  const secFilings = quoteSummary.result[0].secFilings;

  return (
    <Box mt={4}>
      <Table
        variant='simple'
        color='termos.orange'
        border='1px'
        borderColor='termos.gray'
      >
        <Thead bgColor='termos.beige'>
          <Tr>
            <Th color='white' border={0} pl={2.5}>
              Type
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Title
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Date
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {secFilings &&
            secFilings.filings?.map((f, i) => (
              <Tr key={i} bgColor={i % 2 ? 'termos.darkorange' : 'black'}>
                <Td pl={2.5} border={0}>
                  {f.type}
                </Td>
                <Td pl={2.5} border={0}>
                  <Link ey={i} href={f.edgarUrl} isExternal>
                    {f.title}
                  </Link>
                </Td>
                <Td pl={2.5} w='114px' border={0}>
                  {f.date}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  );
};
