import { Box, Text } from '@chakra-ui/layout';
import { Tag } from '@chakra-ui/react';
import React from 'react';
import { Company, Fundamental } from '../../ApiClients';
import { CompanyRating } from '../../components/CompanyRating';
import { TTooltip } from '../../components/TTable';
import { formatYearSpecial } from '../../utils/date';
import { formatNumber } from '../../utils/number';

interface Props {
  company: Company;
  fundamental: Fundamental | undefined;
}

export const Infos: React.FC<Props> = ({ company, fundamental }) => {
  return (
    <Box border='1px' p={2} borderColor='termos.gray'>
      <Row name='Sector' value={company.sector?.value} />
      <Row name='Industry' value={company.industry?.value} />
      <Row
        name='Market Cap'
        value={`${formatNumber(fundamental?.marketcap)} (${
          company.scaleMarketcap?.value
        })`}
      />
      <Row name='Employees' value={formatNumber(company.employees)} />
      <Row
        name='First price date'
        value={formatYearSpecial(company.firstPricedate)}
      />
      <Row name='Exchange' value={company.exchange?.value} />
      <Row name='Location' value={company.location} />
      {company.companyIndices && company.companyIndices.length > 0 && (
        <Box mb={3}>
          <Text display='inline-block' color='white'>
            Indices:
          </Text>{' '}
          {company.companyIndices?.map((c) => (
            <Tag
              key={c.id}
              size='sm'
              bgColor='termos.orange'
              p='1px'
              ml={1}
              _hover={{ cursor: 'pointer' }}
            >
              <TTooltip label={c.name}>{c.shortName}</TTooltip>
            </Tag>
          ))}
        </Box>
      )}
      <Text display='inline-block' color='white'>
        Rating:
      </Text>{' '}
      {company.ticker && <CompanyRating ticker={company.ticker} />}
    </Box>
  );
};

const Row: React.FC<{ name: string; value: any }> = ({ name, value }) => {
  return (
    <Box color='termos.orange' mb={3}>
      <Text color='white' mr={1} display='inline'>
        {name}:
      </Text>
      {value}
    </Box>
  );
};
