
export const toCamelCase = (str: string) => {
    let returnStr = "";
    let cont = false;
    for (let i = 0; i <= str.length - 1; i++)
    {
        if (cont)
        {
            cont = false;
            continue;
        };

        if (i === 0)
            returnStr += str[i].toLowerCase();
        else if (str[i] === '_')
        {
            returnStr += str[i + 1].toUpperCase();
            cont = true;
        }
        else
            returnStr += str[i];
    }

    return returnStr;
}