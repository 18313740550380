import { Box, Text } from '@chakra-ui/layout';
import Select, { ActionMeta, InputActionMeta, SingleValue } from 'react-select';

const customStyles = {
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: 'var(--chakra-colors-termos-darkbeige)',
    color: 'var(--chakra-colors-termos-orange)',
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    '&:hover': {
      backgroundColor: 'var(--chakra-colors-termos-darkblue)',
    },
    color: 'var(--chakra-colors-termos-orange)',
    backgroundColor:
      state.isSelected || state.isFocused
        ? 'var(--chakra-colors-termos-darkblue)'
        : '',
  }),
  dropdownIndicator: (base: any) => ({
    ...base,
    height: '100%',
    backgroundColor: 'var(--chakra-colors-termos-beige)',
    color: 'var(--chakra-colors-termos-lightgray)',
    borderLeft: '3px solid var(--chakra-colors-black)',
  }),
};

interface Props {
  title: string;
  options:
    | {
        value: string | undefined;
        label: string | undefined;
      }[]
    | undefined;
  value?: string;
  onChange?:
    | ((
        newValue: SingleValue<{
          value: string | undefined;
          label: string | undefined;
        }>,
        actionMeta: ActionMeta<{
          value: string | undefined;
          label: string | undefined;
        }>
      ) => void)
    | undefined;
  onInputChange?:
    | ((newValue: string, actionMeta: InputActionMeta) => void)
    | undefined;
}

export const TermosSelect: React.FC<Props> = ({
  title,
  options,
  value,
  onChange,
  onInputChange,
}) => {
  return (
    <Box>
      <Text color='white'>{title}</Text>
      <Select
        placeholder=''
        options={options}
        components={{
          IndicatorSeparator: () => null,
        }}
        styles={{
          control: (base: any, state: any) => ({
            ...base,
            backgroundColor: 'var(--chakra-colors-termos-orange)',
            borderRadius: 0,
            border: 0,
            borderColor: state.isFocused ? null : null,
            boxShadow: state.isFocused ? null : null,
            '&:hover': {
              borderColor: state.isFocused ? null : null,
            },
            width: '350px',
          }),
          menu: (base: any) => ({
            ...base,
            borderRadius: 0,
            marginTop: 0,
            width: '311px',
          }),
          ...customStyles,
        }}
        value={options?.filter((o) => o.value === value)}
        onChange={onChange}
        onInputChange={onInputChange}
      />
    </Box>
  );
};
