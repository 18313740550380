import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  Filler,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels,
  Filler
);

const options = {
  elements: {
    point: {
      radius: 0,
    },
  },
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    hover: {
      mode: 'nearest',
      intersect: false,
    },
    tooltip: {
      interaction: {
        mode: 'index',
        intersect: false,
      },
      backgroundColor: 'white',
      titleColor: 'black',
      titleFont: {
        family: 'monospace',
      },
      bodyColor: 'black',
      displayColors: false,
      callbacks: {
        title: (tooltipItems: any) => {
          let title = tooltipItems[0].parsed.y;
          return `Change ${title}%`;
        },
        label: (tooltipItems: any) => {
          return tooltipItems.label;
        },
      },
    },
  },
  scales: {
    x: {
      grid: {
        display: true,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        color: '#7B808C',
        borderDash: [4, 2],
      },
      ticks: {
        color: 'white',
        font: {
          family: 'monospace',
        },
      },
    },
    y: {
      grid: {
        display: true,
        drawBorder: true,
        drawOnChartArea: true,
        drawTicks: true,
        color: '#7B808C',
        borderDash: [4, 2],
      },
      ticks: {
        color: 'white',
        font: {
          family: 'monospace',
        },
        callback: function (value: any, index: any, values: any) {
          return value + '%';
        },
        precision: 2,
      },
    },
  },
};

interface TLineChartProps {
  data: ChartData<'line', any[], string>;
}

export const TLineChart: React.FC<TLineChartProps> = ({ data }) => {
  return <Line options={options} data={data} height='250px' width='900px' />;
};
