import { useQuery } from 'react-query';
import {
  CommoditiesPerformance,
  CommoditiesPrice,
  InvestingComClient,
  MajorIndicePerformance,
  MajorIndicePrice,
} from '../ApiClients';

const investingComClient = new InvestingComClient();

const noRefetch = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const useGetMajorIndicePrices = () =>
  useQuery<MajorIndicePrice[]>(
    'majorIndicePrices',
    () => investingComClient.getMajorIndicePrices(),
    noRefetch
  );

export const useGetMajorIndicePerformances = () =>
  useQuery<MajorIndicePerformance[]>(
    'majorIndicePerformances',
    () => investingComClient.getMajorIndicePerformances(),
    noRefetch
  );

export const useGetCommoditiesPrice = () =>
  useQuery<CommoditiesPrice[]>(
    'commoditiesPrice',
    () => investingComClient.getCommoditiesPrice(),
    noRefetch
  );

export const useGetCommoditiesPerformance = () =>
  useQuery<CommoditiesPerformance[]>(
    'commoditiesPerformance',
    () => investingComClient.getCommoditiesPerformance(),
    noRefetch
  );
