import { Admin } from './pages/admin/Admin';
import { CompanyPage } from './pages/company/CompanyPage';
import { Dashboard } from './pages/dashboard/Dashboard';
import { Home } from './pages/home/Home';
import { Portfolios } from './pages/portfolio/Portfolios';
import { Screen } from './pages/screen/Screen';
import { WatchlistCompanyNav } from './pages/watchlist/WatchlistCompanyNav';
import { Watchlists } from './pages/watchlist/Watchlists';

export const HOME = 'HOME';
export const ADM = 'ADM';
export const SCRN = 'SCRN';
export const DASH = 'DASH';
export const EQ = 'EQ';
export const WATCH = 'WATCH';
export const WATCHID = 'WATCHID';
export const WATCHIDS = 'WATCHIDS';
export const WATCHNAV = 'WATCHNAV';
export const PORT = 'PORT';
export const PORTID = 'PORTID';

export const routes = [
  {
    path: '/',
    key: HOME,
    component: Home,
    canSearch: true,
  },
  {
    path: '/admin',
    key: ADM,
    component: Admin,
    canSearch: true,
  },
  {
    path: '/screen',
    key: SCRN,
    component: Screen,
    canSearch: true,
  },
  {
    path: '/dashboard',
    key: DASH,
    component: Dashboard,
    canSearch: true,
  },
  {
    path: '/company/:ticker',
    key: EQ,
    component: CompanyPage,
    canSearch: false,
  },
  {
    path: '/watchlists',
    key: WATCH,
    component: Watchlists,
    canSearch: true,
  },
  {
    path: '/watchlists/:id',
    key: WATCHID,
    component: Watchlists,
    canSearch: false,
  },
  {
    path: '/watchlists/:id/:watchlistCompanyId',
    key: WATCHIDS,
    component: Watchlists,
    canSearch: false,
  },
  {
    path: '/watchlists/nav/:id/:watchlistCompanyId',
    key: WATCHNAV,
    component: WatchlistCompanyNav,
    canSearch: false,
  },
  {
    path: '/portfolios',
    key: PORT,
    component: Portfolios,
    canSearch: true,
  },
  {
    path: '/portfolios/:id',
    key: PORTID,
    component: Portfolios,
    canSearch: false,
  },
];
