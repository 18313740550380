import React from 'react';
import { RouteComponentProps } from 'react-router';
import { Company } from './Company';

interface RouteParams {
  ticker: string;
}

type Props = RouteComponentProps<RouteParams>;

export const CompanyPage: React.FC<Props> = (props) => {
  const { ticker } = props.match.params;
  return <Company ticker={ticker} />;
};
