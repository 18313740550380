import { Box } from '@chakra-ui/layout';
import { Thead, Tr, Tbody } from '@chakra-ui/react';
import React from 'react';
import { CcFundamental } from '../../ApiClients';
import { TinyTd, TTable, TTh, TTooltip, TTr } from '../../components/TTable';
import { useGetFundamentalKeys } from '../../hooks/fundamental';
import { formatYearSpecial } from '../../utils/date';
import { formatNumber } from '../../utils/number';
import { fundamentalColorRules, selectedKeys } from './utils';

interface Props {
  fundamentals: CcFundamental[] | undefined;
}

export const CcFundamentals: React.FC<Props> = ({ fundamentals }) => {
  const { data: fundamentalKeys } = useGetFundamentalKeys();

  if (!fundamentalKeys) return <>fant ikke fundamental keys</>;

  return (
    <Box pb={16} maxW='794px'>
      <TTable hasBorder>
        <Thead bgColor='termos.beige'>
          <Tr>
            <TTh>Year</TTh>
            {fundamentals?.map((f, i) => (
              <TTh key={i}>
                {`${formatYearSpecial(f.calendardate)} (${f.dimension})`}
              </TTh>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {selectedKeys.map((sk, i) => (
            <TTr key={i} i={i}>
              <TinyTd>
                <TTooltip
                  label={
                    <Box p={2}>
                      <Box mb={2}>
                        {fundamentalKeys.find((fk) => fk.value === sk)?.name}
                      </Box>
                      <Box>
                        {
                          fundamentalKeys.find((fk) => fk.value === sk)
                            ?.description
                        }
                      </Box>
                    </Box>
                  }
                >
                  {sk}
                </TTooltip>
              </TinyTd>
              {fundamentals?.map((f, j) => (
                <TinyTd
                  key={j}
                  color={fundamentalColorRules(
                    sk,
                    f[sk as keyof CcFundamental] as number
                  )}
                >
                  {formatNumber(f[sk as keyof CcFundamental] as number)}
                </TinyTd>
              ))}
            </TTr>
          ))}
        </Tbody>
      </TTable>
    </Box>
  );
};

/*
ROWS NOT USED:

"cor"
"sgna"
"rnd"
"opex"
"intexp"
"taxexp"
"netincdis"
"consolinc"
"netincnci"
"netinc"
"prefdivis"
"netinccmn"
"shareswa"
"shareswadil"
"capex"
"ncfbus"
"ncfinv"
"ncff"
"ncfdebt"
"ncfcommon"
"ncfdiv"
"ncfi"
"ncfo"
"ncfx"
"ncf"
"sbcomp"
"depamor"
"assets"
"cashneq"
"investments"
"investmentsc"
"investmentsnc"
"deferredrev"
"deposits"
"ppnenet"
"inventory"
"taxassets"
"receivables"
"payables"
"intangibles"
"liabilities"
"equity"
"retearn"
"accoci"
"assetsc"
"assetsnc"
"liabilitiesc"
"liabilitiesnc"
"taxliabilities"
"debt"
"debtc"
"debtnc"
"ebt"
"ebit"
"fxusd"
"equityusd"
"epsusd"
"revenueusd"
"netinccmnusd"
"cashnequsd"
"debtusd"
"ebitusd"
"ebitdausd"
"sharesbas"
"dps"
"sharefactor"
"marketcap"
"ev"
"invcap"
"equityavg"
"assetsavg"
"invcapavg"
"tangibles"
"roa"
"fcf"
"roic"
"gp"
"opinc"
"grossmargin"
"ebitdamargin"
"ros"
"assetturnover"
"payoutratio"
"evebitda"
"evebit"
"pe1"
"sps"
"ps1"
"workingcapital"
"fcfps"
"bvps"
"tbvps"
"datekey"
"reportperiod"
"lastupdated"
"dimension"

*/
