import { Box, Flex, Text } from '@chakra-ui/layout';
import React from 'react';
import { termosColors } from '../../..';
import { Company } from '../../../ApiClients';
import { ColorTextedLabel } from '../../../components/ColorTextedLabel';
import {
  useGetFinanceInsight,
  useGetRecommendationTrend,
} from '../../../hooks/yahoofinance';
import { RecommendationTrendChart } from './RecommendationTrendChart';

interface Props {
  company: Company;
}

export const Technical: React.FC<Props> = ({ company }) => {
  const { data: financeInsight } = useGetFinanceInsight(
    company.ticker ? company.ticker : ''
  );

  const { data: quoteSummary } = useGetRecommendationTrend(
    company.ticker ? company.ticker : ''
  );

  if (!quoteSummary || !quoteSummary.result || quoteSummary.result.length === 0)
    return <>Could not get Recommendation Trend and/or finance insight</>;

  const recommendationTrend = quoteSummary.result[0].recommendationTrend;

  const technicalEvents = financeInsight?.instrumentInfo?.technicalEvents;
  const instrumentInfo = financeInsight?.instrumentInfo;

  const colorTerms = (term: string | undefined) => {
    if (!term) return termosColors.orange;

    if (term === 'Bullish') return termosColors.green;
    else if (term === 'Bearish') return termosColors.red;
    else return termosColors.orange;
  };

  return (
    <Box>
      <Box>
        <Text color='white' display='inline'>
          Technical Events
        </Text>{' '}
        ({technicalEvents?.sector})
      </Box>
      <Flex mt={4}>
        <Box mr={24}>
          <Text color='white'>Short term</Text>
          <ColorTextedLabel
            label='Direction:'
            text={technicalEvents?.shortTermOutlook?.direction}
            color={colorTerms(technicalEvents?.shortTermOutlook?.direction)}
          />
          <ColorTextedLabel
            label='Sector:'
            text={technicalEvents?.shortTermOutlook?.sectorDirection}
            color={colorTerms(
              technicalEvents?.shortTermOutlook?.sectorDirection
            )}
          />
          <ColorTextedLabel
            label='Index:'
            text={technicalEvents?.shortTermOutlook?.indexDirection}
            color={colorTerms(
              technicalEvents?.shortTermOutlook?.indexDirection
            )}
          />
        </Box>
        <Box mr={24}>
          <Text color='white'>Intermediate term</Text>
          <ColorTextedLabel
            label='Direction:'
            text={technicalEvents?.intermediateTermOutlook?.direction}
            color={colorTerms(
              technicalEvents?.intermediateTermOutlook?.direction
            )}
          />
          <ColorTextedLabel
            label='Sector:'
            text={technicalEvents?.intermediateTermOutlook?.sectorDirection}
            color={colorTerms(
              technicalEvents?.intermediateTermOutlook?.sectorDirection
            )}
          />
          <ColorTextedLabel
            label='Index:'
            text={technicalEvents?.intermediateTermOutlook?.indexDirection}
            color={colorTerms(
              technicalEvents?.intermediateTermOutlook?.indexDirection
            )}
          />
        </Box>
        <Box>
          <Text color='white'>Long term</Text>
          <ColorTextedLabel
            label='Direction:'
            text={technicalEvents?.longTermOutlook?.direction}
            color={colorTerms(technicalEvents?.longTermOutlook?.direction)}
          />
          <ColorTextedLabel
            label='Sector:'
            text={technicalEvents?.longTermOutlook?.sectorDirection}
            color={colorTerms(
              technicalEvents?.longTermOutlook?.sectorDirection
            )}
          />
          <ColorTextedLabel
            label='Index:'
            text={technicalEvents?.longTermOutlook?.indexDirection}
            color={colorTerms(technicalEvents?.longTermOutlook?.indexDirection)}
          />
        </Box>
      </Flex>
      <Box mt={4}>
        <Box>
          <ColorTextedLabel
            label='Key Technicals'
            text={`(${instrumentInfo?.keyTechnicals?.provider})`}
          />
        </Box>
        <Box mt={2}>
          <ColorTextedLabel
            label='Support:'
            text={instrumentInfo?.keyTechnicals?.support}
          />
          <ColorTextedLabel
            label='Resistance:'
            text={instrumentInfo?.keyTechnicals?.resistance}
          />
          <ColorTextedLabel
            label='Stop Loss:'
            text={instrumentInfo?.keyTechnicals?.stopLoss}
          />
        </Box>
      </Box>
      <Box mt={2}>
        <RecommendationTrendChart recommendationTrend={recommendationTrend} />
      </Box>
    </Box>
  );
};
