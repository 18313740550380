import { Box } from '@chakra-ui/layout';
import React from 'react';
import { useGetWatchlists } from '../../hooks/watchlists';
import { CompanyDetails } from '../screen/CompanyDetails';

interface Props {
  currentWatchlistId: number;
  watchlistCompanyId: number;
}

export const WatchlistCompany: React.FC<Props> = ({
  currentWatchlistId,
  watchlistCompanyId,
}) => {
  const { data: watchlists } = useGetWatchlists();
  const watchlist = watchlists?.find((w) => w.id === currentWatchlistId);
  const watchlistCompany = watchlist?.companies?.find(
    (wc) => wc.id === watchlistCompanyId
  );

  return (
    <Box color='termos.orange'>
      {watchlistCompany?.company && (
        <CompanyDetails company={watchlistCompany?.company} />
      )}
    </Box>
  );
};
