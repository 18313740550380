import { Tr, Tbody, Link } from '@chakra-ui/react';
import React from 'react';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { PortfolioCompanyViewModel } from '../../ApiClients';
import { TTable, TStickyHead, TTh, TTr, TinyTd } from '../../components/TTable';
import { routes, EQ } from '../../routes';
import { formatDate } from '../../utils/date';
import { colorNumberAroundZero } from '../../utils/number';
import { ThRatios, TinyTdRatios } from '../company/Ratios';

interface Props {
  portfolioCompanies: PortfolioCompanyViewModel[] | undefined;
}

export const PortfolioCompanies: React.FC<Props> = ({ portfolioCompanies }) => {
  return (
    <TTable>
      <TStickyHead>
        <Tr>
          <TTh>Weight</TTh>
          <TTh>Name</TTh>
          <TTh>Curr</TTh>
          <TTh>Returns %</TTh>
          <ThRatios />
          <TTh>As of</TTh>
        </Tr>
      </TStickyHead>
      <Tbody>
        {portfolioCompanies?.map((pc, i) => (
          <PCTTrs key={i} i={i} pc={pc} />
        ))}
      </Tbody>
    </TTable>
  );
};

interface PCTTrsProps {
  i: number;
  pc: PortfolioCompanyViewModel;
}

const PCTTrs: React.FC<PCTTrsProps> = ({ i, pc }) => {
  const fundamentals = pc.company?.fundamentals ? pc.company?.fundamentals : [];
  const fundamental = fundamentals[0];
  return (
    <TTr i={i}>
      <TinyTd>{`${pc.percentOfPortfolio}%`}</TinyTd>
      <TinyTd>
        <Link
          as={RouterLink}
          to={generatePath(routes.find((r) => r.key === EQ)?.path ?? '', {
            ticker: pc?.company?.ticker,
          })}
          isExternal
        >
          {`${pc.company?.ticker} - ${pc.company?.name}`}
        </Link>
      </TinyTd>
      <TinyTd>{pc.company?.currency?.value}</TinyTd>
      <TinyTd color={colorNumberAroundZero(pc?.totalPercentChange)}>
        <>{pc?.totalPercentChange}%</>
      </TinyTd>
      <TinyTdRatios fundamental={fundamental} company={pc.company} />
      <TinyTd>
        {`${formatDate(fundamental.calendardate, 'dd.MM.yy')} - ${
          fundamental.dimension
        }`}
      </TinyTd>
    </TTr>
  );
};
