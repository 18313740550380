import { Flex, Text } from '@chakra-ui/layout';
import Select, { ActionMeta, SingleValue } from 'react-select';

const customStyles = {
  control: (base: any, state: any) => ({
    ...base,
    backgroundColor: 'var(--chakra-colors-termos-orange)',
    borderRadius: 0,
    border: 0,
    borderColor: state.isFocused ? null : null,
    boxShadow: state.isFocused ? null : null,
    '&:hover': {
      borderColor: state.isFocused ? null : null,
    },
    fontSize: '16px',
    width: '35px',
    fontWeight: 'bold',
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    marginTop: 0,
    width: '35px',
  }),
  menuList: (base: any) => ({
    ...base,
    padding: 0,
    backgroundColor: 'var(--chakra-colors-termos-darkbeige)',
    color: 'var(--chakra-colors-termos-orange)',
  }),
  option: (styles: any, state: any) => ({
    ...styles,
    '&:hover': {
      backgroundColor: 'var(--chakra-colors-termos-darkblue)',
    },
    color: 'var(--chakra-colors-termos-orange)',
    backgroundColor:
      state.isSelected || state.isFocused
        ? 'var(--chakra-colors-termos-darkblue)'
        : '',
  }),
};

interface Props {
  title: string;
  options:
    | {
        value: string | undefined;
        label: string | undefined;
      }[]
    | undefined;
  value?: string;
  onChange?:
    | ((
        newValue: SingleValue<{
          value: string | undefined;
          label: string | undefined;
        }>,
        actionMeta: ActionMeta<{
          value: string | undefined;
          label: string | undefined;
        }>
      ) => void)
    | undefined;
}

export const TermosSelectSmall: React.FC<Props> = ({
  title,
  options,
  value,
  onChange,
}) => {
  return (
    <Flex>
      <Text color='white' fontSize='16px' mr={2} pt='6px'>
        {title}:
      </Text>
      <Select
        placeholder=''
        options={options}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null,
        }}
        styles={customStyles}
        value={options?.filter((o) => o.value === value)}
        onChange={onChange}
        isSearchable={false}
      />
    </Flex>
  );
};
