import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Input, Button, Center, Box, Text } from '@chakra-ui/react';
import { useAuthState } from './firebase/AuthContext';
import { authMethods } from './firebase/authmethods';

export const Signin: React.FC = () => {
  const auth = useAuthState();
  const history = useHistory();
  const [errors, setErrors] = useState([]);
  const [inputs, setInputs] = useState({ email: '', password: '' });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = (e: any) => {
    setIsSubmitting(true);
    e.preventDefault();
    const redirect = () => history.push('/');
    authMethods.signin(inputs.email, inputs.password, setErrors, redirect);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setInputs((prev: any) => ({ ...prev, [name]: value }));
  };

  if (auth.user && auth.user.emailVerified) return <></>;

  return (
    <Box bgColor='black'>
      <Center h='100vh'>
        <form onSubmit={handleSubmit}>
          <Box mb={4}>
            <Input
              id='1'
              name='email'
              color='black'
              bgColor='termos.orange'
              border={0}
              _focus={{ outline: 'none' }}
              _hover={{ outline: 'none' }}
              w='250px'
              value={inputs.email}
              onChange={handleChange}
            />
          </Box>
          <Box>
            <Input
              id='2'
              name='password'
              color='black'
              bgColor='termos.orange'
              border={0}
              _focus={{ outline: 'none' }}
              _hover={{ outline: 'none' }}
              w='250px'
              value={inputs.password}
              onChange={handleChange}
              type='password'
            />
          </Box>
          <Button
            color='white'
            variant='outline'
            _hover={{
              color: 'termos.orange',
              borderColor: 'termos.orange',
            }}
            type='submit'
            mt='4'
            isLoading={isSubmitting}
          >
            GO
          </Button>
          {errors.length > 0
            ? errors.map((error: any, i: any) => (
                <Text key={i} style={{ color: 'red' }}>
                  {error}
                </Text>
              ))
            : null}
        </form>
      </Center>
    </Box>
  );
};
