import { useQuery } from 'react-query';
import { BondsClient, ETF } from '../ApiClients';

const bondsClient = new BondsClient();

const noRefetch = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const useGetUSTreasuryBondsRates = () =>
  useQuery<ETF[]>(
    'USTreasuryBondsRates',
    () => bondsClient.getUSTreasuryBondsRates(),
    noRefetch
  );
