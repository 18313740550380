import { Box, Center } from '@chakra-ui/layout';
import { Tr, Tbody } from '@chakra-ui/table';
import React, { useState } from 'react';
import { colorNumberChange, toPercent } from '../../utils/number';
import {
  TTable,
  TStickyHead,
  TTh,
  TTr,
  TinyTd,
  TTooltip,
} from '../../components/TTable';
import { useGetETFBonds } from '../../hooks/etf';
import { Link } from '@chakra-ui/react';
import { useGetUSTreasuryBondsRates } from '../../hooks/bond';
import { useGetCC, useGetForex } from '../../hooks/forex';

export const Intrest: React.FC = () => {
  const [showPrice, setShowPrice] = useState(true);
  return (
    <Box>
      <Center
        color='white'
        fontSize='16px'
        _hover={{ cursor: 'pointer', color: 'termos.orange' }}
        onClick={() => setShowPrice((prev) => !prev)}
      >
        Intrest
      </Center>

      <Box h='680px' overflowY='auto' border='1px' borderColor='termos.gray'>
        {showPrice ? <SmallPrice /> : <SmallPerformance />}
      </Box>
    </Box>
  );
};

const SmallPrice: React.FC = () => {
  const { data: bonds } = useGetUSTreasuryBondsRates();
  const { data: etfBonds } = useGetETFBonds();
  const { data: forex } = useGetForex();
  const { data: currency } = useGetCC();

  return (
    <TTable>
      <TStickyHead>
        <Tr>
          <TTh>Name</TTh>
          <TTh>Price</TTh>
          <TTh>Change</TTh>
        </Tr>
      </TStickyHead>
      <Tbody>
        {bonds?.map((x, i) => (
          <TTr key={i} i={i}>
            <TinyTd>
              <TTooltip label={`${x.quote?.symbol} - ${x.quote?.shortName}`}>
                <Link
                  href={`https://finance.yahoo.com/quote/${x.quote?.symbol}/chart`}
                  isExternal
                >
                  {x.name}
                </Link>
              </TTooltip>
            </TinyTd>
            <TinyTd>{x.quote?.regularMarketPrice.toFixed(3)}</TinyTd>
            <TinyTd
              color={colorNumberChange(x.quote?.regularMarketChangePercent)}
            >
              {x.quote?.regularMarketChangePercent.toFixed(2)}
            </TinyTd>
          </TTr>
        ))}
        {etfBonds?.map((x, i) => (
          <TTr key={i} i={i}>
            <TinyTd>
              <TTooltip label={`${x.quote?.symbol} - ${x.quote?.shortName}`}>
                <Link
                  href={`https://finance.yahoo.com/quote/${x.quote?.symbol}/chart`}
                  isExternal
                >
                  {x.name}
                </Link>
              </TTooltip>
            </TinyTd>
            <TinyTd>{x.quote?.regularMarketPrice.toFixed(2)}</TinyTd>
            <TinyTd
              color={colorNumberChange(x.quote?.regularMarketChangePercent)}
            >
              {x.quote?.regularMarketChangePercent.toFixed(2)}
            </TinyTd>
          </TTr>
        ))}
        {currency?.map((x, i) => (
          <TTr key={i} i={i + 1}>
            <TinyTd>{x.name}</TinyTd>
            <TinyTd>{x.value}</TinyTd>
            <TinyTd color={colorNumberChange(x.change)}>{x.change}</TinyTd>
          </TTr>
        ))}
        {forex?.map((x, i) => (
          <TTr key={i} i={i + 1}>
            <TinyTd>
              <TTooltip label={`${x.quote?.symbol} - ${x.quote?.shortName}`}>
                <Link
                  href={`https://finance.yahoo.com/quote/${x.quote?.symbol}/chart`}
                  isExternal
                >
                  {x.name}
                </Link>
              </TTooltip>
            </TinyTd>
            <TinyTd>{x.quote?.regularMarketPrice.toFixed(2)}</TinyTd>
            <TinyTd
              color={colorNumberChange(x.quote?.regularMarketChangePercent)}
            >
              {x.quote?.regularMarketChangePercent.toFixed(2)}
            </TinyTd>
          </TTr>
        ))}
      </Tbody>
    </TTable>
  );
};

const SmallPerformance: React.FC = () => {
  const { data: bonds } = useGetUSTreasuryBondsRates();
  const { data: etfBonds } = useGetETFBonds();
  const { data: forex } = useGetForex();
  const { data: currency } = useGetCC();

  return (
    <TTable>
      <TStickyHead>
        <Tr>
          <TTh>Name</TTh>
          <TTh>1d</TTh>
          <TTh>1m</TTh>
          <TTh>3m</TTh>
        </Tr>
      </TStickyHead>
      <Tbody>
        {bonds?.map((x, i) => (
          <TTr key={i} i={i}>
            <TinyTd>
              <TTooltip label={`${x.quote?.symbol} - ${x.quote?.shortName}`}>
                <Link
                  href={`https://finance.yahoo.com/quote/${x.quote?.symbol}/chart`}
                  isExternal
                >
                  {x.name}
                </Link>
              </TTooltip>
            </TinyTd>
            <TinyTd
              color={colorNumberChange(x.quote?.regularMarketChangePercent)}
            >
              {x.quote?.regularMarketChangePercent.toFixed(2)}
            </TinyTd>
            <TinyTd
              color={colorNumberChange(
                toPercent(x.performance?.trailingReturns?.oneMonth?.raw)
              )}
            >
              {toPercent(
                x.performance?.trailingReturns?.oneMonth?.raw
              )?.toFixed(2)}
            </TinyTd>
            <TinyTd
              color={colorNumberChange(
                toPercent(x.performance?.trailingReturns?.threeMonth?.raw)
              )}
            >
              {toPercent(
                x.performance?.trailingReturns?.threeMonth?.raw
              )?.toFixed(2)}
            </TinyTd>
          </TTr>
        ))}
        {etfBonds?.map((x, i) => (
          <TTr key={i} i={i}>
            <TinyTd>
              <TTooltip label={`${x.quote?.symbol} - ${x.quote?.shortName}`}>
                <Link
                  href={`https://finance.yahoo.com/quote/${x.quote?.symbol}/chart`}
                  isExternal
                >
                  {x.name}
                </Link>
              </TTooltip>
            </TinyTd>
            <TinyTd
              color={colorNumberChange(x.quote?.regularMarketChangePercent)}
            >
              {x.quote?.regularMarketChangePercent.toFixed(2)}
            </TinyTd>
            <TinyTd
              color={colorNumberChange(
                toPercent(x.performance?.trailingReturns?.oneMonth?.raw)
              )}
            >
              {toPercent(
                x.performance?.trailingReturns?.oneMonth?.raw
              )?.toFixed(2)}
            </TinyTd>
            <TinyTd
              color={colorNumberChange(
                toPercent(x.performance?.trailingReturns?.threeMonth?.raw)
              )}
            >
              {toPercent(
                x.performance?.trailingReturns?.threeMonth?.raw
              )?.toFixed(2)}
            </TinyTd>
          </TTr>
        ))}
        {currency?.map((x, i) => (
          <TTr key={i} i={i + 1}>
            <TinyTd>{x.name}</TinyTd>
            <TinyTd color={colorNumberChange(x.change)}>{x.change}</TinyTd>
            <TinyTd>-</TinyTd>
            <TinyTd>-</TinyTd>
          </TTr>
        ))}
        {forex?.map((x, i) => (
          <TTr key={i} i={i}>
            <TinyTd>
              <TTooltip label={`${x.quote?.symbol} - ${x.quote?.shortName}`}>
                <Link
                  href={`https://finance.yahoo.com/quote/${x.quote?.symbol}/chart`}
                  isExternal
                >
                  {x.name}
                </Link>
              </TTooltip>
            </TinyTd>
            <TinyTd
              color={colorNumberChange(x.quote?.regularMarketChangePercent)}
            >
              {x.quote?.regularMarketChangePercent.toFixed(2)}
            </TinyTd>
            <TinyTd>-</TinyTd>
            <TinyTd>-</TinyTd>
          </TTr>
        ))}
      </Tbody>
    </TTable>
  );
};
