import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Text,
} from '@chakra-ui/react';
import { Company } from '../../../ApiClients';
import { useGetFinanceInsight } from '../../../hooks/yahoofinance';
import { formatUnixSpecial } from '../../../utils/date';

interface FinanceInsightProps {
  company: Company;
}

export const FinanceInsight: React.FC<FinanceInsightProps> = ({ company }) => {
  const { data: financeInsight } = useGetFinanceInsight(
    company.ticker ? company.ticker : ''
  );

  return (
    <Box p={2} color='white'>
      <Box border='1px' borderColor='termos.gray' p={2}>
        <Box>Upsell</Box>
        <Box>
          Published:{' '}
          {formatUnixSpecial(
            financeInsight?.upsell?.msBullishBearishSummariesPublishDate
          )}
        </Box>
        <Box>Report Type: {financeInsight?.upsell?.upsellReportType}</Box>

        <Popover placement='top-start'>
          <PopoverTrigger>
            <Button colorScheme='ghost' variant='solid' color='termos.orange'>
              Bullish Summary
            </Button>
          </PopoverTrigger>
          <PopoverContent bgColor='termos.beige' color='termos.orange'>
            <PopoverHeader fontWeight='semibold'>Bullish Summary</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton color='white' />
            <PopoverBody>
              {financeInsight?.upsell?.msBullishSummary?.map((t, i) => (
                <Text key={i}>{t}</Text>
              ))}
            </PopoverBody>
          </PopoverContent>
        </Popover>
        <Popover placement='top-start'>
          <PopoverTrigger>
            <Button colorScheme='ghost' variant='solid' color='termos.orange'>
              Bearish Summary
            </Button>
          </PopoverTrigger>
          <PopoverContent bgColor='termos.beige' color='termos.orange'>
            <PopoverHeader fontWeight='semibold'>Bearish Summary</PopoverHeader>
            <PopoverArrow />
            <PopoverCloseButton color='white' />
            <PopoverBody>
              {financeInsight?.upsell?.msBearishSummary?.map((t, i) => (
                <Text key={i}>{t}</Text>
              ))}
            </PopoverBody>
          </PopoverContent>
        </Popover>
      </Box>
      <Box mt={4} fontSize='16px' border='1px' borderColor='termos.gray' p={2}>
        Number of matching research reports:{' '}
        <Text display='inline' color='termos.orange'>
          {financeInsight?.reports?.length}
        </Text>
      </Box>
      <Box mt={4} border='1px' borderColor='termos.gray' p={2}>
        <Flex>
          <Box mr={6}>
            <Text mt='18px' color='white'>
              Innovativeness:
            </Text>
            <Text color='white'>Hiring:</Text>
            <Text color='white'>Sustainability:</Text>
            <Text color='white'>Insider Sentiments:</Text>
            <Text color='white'>Earnings Reports:</Text>
            <Text color='white'>Dividends:</Text>
          </Box>
          <Box mr={6}>
            <Text color='white'>Company</Text>
            <Text>
              {financeInsight?.companySnapshot?.company?.innovativeness.toFixed(
                2
              )}
            </Text>
            <Text>
              {financeInsight?.companySnapshot?.company?.hiring.toFixed(2)}
            </Text>
            <Text>
              {financeInsight?.companySnapshot?.company?.sustainability.toFixed(
                2
              )}
            </Text>
            <Text>
              {financeInsight?.companySnapshot?.company?.insiderSentiments.toFixed(
                2
              )}
            </Text>
            <Text>
              {financeInsight?.companySnapshot?.company?.earningsReports.toFixed(
                2
              )}
            </Text>
            <Text>
              {financeInsight?.companySnapshot?.company?.innovativeness.toFixed(
                2
              )}
            </Text>
          </Box>
          <Box>
            <Text color='white'>Sector</Text>
            <Text>
              {financeInsight?.companySnapshot?.sector?.innovativeness.toFixed(
                2
              )}
            </Text>
            <Text>
              {financeInsight?.companySnapshot?.sector?.hiring.toFixed(2)}
            </Text>
            <Text>
              {financeInsight?.companySnapshot?.sector?.sustainability.toFixed(
                2
              )}
            </Text>
            <Text>
              {financeInsight?.companySnapshot?.sector?.insiderSentiments.toFixed(
                2
              )}
            </Text>
            <Text>
              {financeInsight?.companySnapshot?.sector?.earningsReports.toFixed(
                2
              )}
            </Text>
            <Text>
              {financeInsight?.companySnapshot?.sector?.dividends.toFixed(2)}
            </Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
