import { DeleteIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  useDisclosure,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  generatePath,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import { PortfoliosClient } from '../../ApiClients';
import { DeleteModal } from '../../components/DeleteModal';
import { TermosSelect } from '../../components/TermosSelect';
import { useGetPortfolios } from '../../hooks/portfolios';
import { PORT, PORTID, routes } from '../../routes';
import { AddPortfolioCompany } from './AddPortfolioCompany';
import { CreatePortfolio } from './CreatePortfolio';
import { PortfolioBreakdown } from './PortfolioBreakdown';
import { PortfolioOverviewCompanies } from './PortfolioOverviewCompanies';
import { PortfolioOverview } from './PortfolioOverview';
import { PortfolioCompanies } from './PortfolioCompanies';
import { PortfolioAnalysis } from './PortfolioAnalysis';

const portfoliosClient = new PortfoliosClient();

interface RouteParams {
  id: string;
}

type Props = RouteComponentProps<RouteParams>;

export const Portfolios: React.FC<Props> = (props) => {
  const { id } = props.match.params;
  const { data: portfolios, refetch } = useGetPortfolios();
  const [currentPortfolioId, setCurrentPortfolioId] = useState(
    id ? parseInt(id) : 0
  );
  const history = useHistory();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activePage, setActivePage] = useState(0);

  useEffect(() => {
    document.title = `Termos - Portfolios`;
  }, []);

  useEffect(() => {
    if (!id && portfolios && portfolios.length > 0) {
      const portfolio = portfolios[0];
      setCurrentPortfolioId(portfolio.id);
      history.push(
        generatePath(routes.find((r) => r.key === PORTID)?.path ?? '', {
          id: portfolio.id,
        })
      );
    }
  }, [id, portfolios, history]);

  const handleDeletePortfolio = () => {
    portfoliosClient.delete(currentPortfolioId).then(() => {
      setCurrentPortfolioId(0);
      onClose();
      refetch();
      history.push(routes.find((r) => r.key === PORT)?.path ?? '');
    });
  };

  const handleRefetchPortfolios = () => refetch();

  return (
    <Box>
      <Flex
        h='55px'
        bgColor='termos.darkbeige'
        mb={2}
        border='1px'
        borderColor='termos.gray'
        p={2}
      >
        <Box color='white' fontSize='16px' mr={6}>
          Portfolios
        </Box>
        <Box w={4} />
        <CreatePortfolio refetchPortfolios={handleRefetchPortfolios} />
        <Box w={8} />
        <TermosSelect
          title=''
          options={portfolios?.map((p) => ({
            value: p.id.toString(),
            label: p.name,
          }))}
          value={currentPortfolioId.toString()}
          onChange={(e) => {
            setCurrentPortfolioId(e?.value ? parseInt(e.value) : 0);
            history.push(
              generatePath(routes.find((r) => r.key === PORTID)?.path ?? '', {
                id: e?.value ? parseInt(e.value) : 0,
              })
            );
          }}
        />
        <Box w={8} />
        <IconButton
          aria-label='Discard portfolio'
          icon={<DeleteIcon />}
          colorScheme='ghost'
          variant='solid'
          color='white'
          _hover={{ color: 'termos.orange' }}
          onClick={onOpen}
          disabled={currentPortfolioId === 0}
        />
        <Box w={4} />
        <AddPortfolioCompany
          currentPortfolioId={currentPortfolioId}
          refetchPortfolios={handleRefetchPortfolios}
        />
        <Box w={8} />
        <Divider orientation='vertical' />
        <Box w={8} />
        <Button
          colorScheme='ghost'
          variant='solid'
          color={activePage === 0 ? 'termos.orange' : 'white'}
          p={0}
          mr={8}
          onClick={() => setActivePage(0)}
        >
          Overview
        </Button>
        <Box w={4} />
        <Button
          colorScheme='ghost'
          variant='solid'
          color={activePage === 1 ? 'termos.orange' : 'white'}
          p={0}
          mr={8}
          onClick={() => setActivePage(1)}
        >
          Companies
        </Button>
        <Box w={4} />
        <Button
          colorScheme='ghost'
          variant='solid'
          color={activePage === 2 ? 'termos.orange' : 'white'}
          p={0}
          mr={8}
          onClick={() => setActivePage(2)}
        >
          Breakdown
        </Button>
        <Box w={4} />
        <Button
          colorScheme='ghost'
          variant='solid'
          color={activePage === 3 ? 'termos.orange' : 'white'}
          p={0}
          mr={8}
          onClick={() => setActivePage(3)}
        >
          Analysis
        </Button>
      </Flex>
      {activePage === 0 && (
        <Box>
          <PortfolioOverview
            portfolio={portfolios?.find((p) => p.id === currentPortfolioId)}
          />
          <Box>
            <PortfolioOverviewCompanies
              refetchPortfolios={handleRefetchPortfolios}
              portfolioCompanies={
                portfolios?.find((p) => p.id === currentPortfolioId)
                  ?.portfolioCompanies
              }
            />
          </Box>
        </Box>
      )}
      {activePage === 1 && (
        <PortfolioCompanies
          portfolioCompanies={
            portfolios?.find((p) => p.id === currentPortfolioId)
              ?.portfolioCompanies
          }
        />
      )}
      {activePage === 2 && (
        <PortfolioBreakdown
          portfolio={portfolios?.find((p) => p.id === currentPortfolioId)}
        />
      )}
      {activePage === 3 && (
        <PortfolioAnalysis portfolioId={currentPortfolioId} />
      )}
      <DeleteModal
        id='deleteportfolio'
        isOpen={isOpen}
        onClose={onClose}
        heading='Delete portfolio'
        description={`Do you want to delete "${
          portfolios?.find((p) => p.id === currentPortfolioId)?.name
        }"?`}
        onDelete={handleDeletePortfolio}
      />
    </Box>
  );
};
