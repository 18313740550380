import { useInfiniteQuery, useQuery } from 'react-query';
import { FilterMetric, ScreenCompanyRequest, ScreenCompanyResponse, ScreensClient } from '../ApiClients';

const screensClient = new ScreensClient();

export const screenFilterMetricsKey = () => 'screenFilterMetrics';

export const useScreenFilterMetrics = () => useQuery<FilterMetric[]>(screenFilterMetricsKey(), () => screensClient.getFilterMetrics(), { refetchOnWindowFocus: false });

// export const screenFilterCompaniesKey = (request: ScreenCompanyRequest) => 'screen'; // `screen-${request.pageNumber}`;//`${JSON.stringify(request)}`;

export const useScreenFilterCompanies = (searchId: string, doSearch: boolean, request: ScreenCompanyRequest) => 
useInfiniteQuery<ScreenCompanyResponse>(
    searchId, 
    () => screensClient.getCompanies(request), 
    { enabled: doSearch, getNextPageParam: (lastPage, pages) => pages.length + 1, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false }
);
