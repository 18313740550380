import { useQuery } from 'react-query';
import { CcFundamental, CcFundamentalsClient } from '../ApiClients';

const ccfundamentalsClient = new CcFundamentalsClient();

export const ccFundamentalKey = (companyCategoryId: number) =>
  `ccFundamentals-${companyCategoryId}`;

export const useGetCcFundamentals = (companyCategoryId: number) =>
  useQuery<CcFundamental[]>(ccFundamentalKey(companyCategoryId), () =>
    ccfundamentalsClient.get(companyCategoryId)
  );
