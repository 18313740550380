import React from 'react';
import { TermosMultiSelect } from '../../components/TermosMultiSelect';
import { useGetCompanyCategoryValues } from '../../hooks';

interface Props {
  category: string | undefined;
  onChange: (value: string) => void;
}

export const CompanyCategorySelect: React.FC<Props> = ({
  category,
  onChange,
}) => {
  const { data: companyCategoryValues } = useGetCompanyCategoryValues(
    category ? category : ''
  );

  return (
    <TermosMultiSelect
      title=''
      options={companyCategoryValues?.map((cc) => ({
        value: cc.id.toString(),
        label: cc.value,
      }))}
      onChange={(opts) =>
        onChange(opts ? opts.map((o) => o.value).toString() : '')
      }
    />
  );
};
