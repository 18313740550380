import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from '@chakra-ui/react';
import { Company } from '../../../ApiClients';
import { useGetEsgScores } from '../../../hooks/yahoofinance';

interface EsgScoresProps {
  company: Company;
}

export const EsgScores: React.FC<EsgScoresProps> = ({ company }) => {
  const { data: quoteSummary } = useGetEsgScores(
    company.ticker ? company.ticker : ''
  );

  if (!quoteSummary || !quoteSummary.result || quoteSummary.result.length === 0)
    return <>Could not get financial Data</>;

  const esgScores = quoteSummary.result[0].esgScores;

  const scoreColor = (score: number | undefined) => {
    if (score) {
      if (score <= 20) return 'termos.green';
      else if (score > 20 && score <= 30) return 'termos.orange';
      else if (score > 30) return 'termos.red';
      else return 'termos.orange';
    }

    return 'termos.orange';
  };

  const controversyColor = (score: number | undefined) => {
    if (score) {
      if (score <= 1.6) return 'termos.green';
      else if (score > 1.6 && score <= 2.4) return 'termos.orange';
      else if (score > 2.4) return 'termos.red';
      else return 'termos.orange';
    }

    return 'termos.orange';
  };

  return (
    <Box p={2} color='white'>
      <Text color='white' mb={2} fontSize='16px' display='inline' mr={4}>
        Environment, Social and Governance (ESG) (Last updated on{' '}
        {esgScores?.ratingMonth}/{esgScores?.ratingYear})
      </Text>
      <Popover placement='top-start'>
        <PopoverTrigger>
          <Button
            colorScheme='ghost'
            variant='solid'
            color='termos.orange'
            p={0}
            minW={0}
            fontSize='20px'
          >
            🔞
          </Button>
        </PopoverTrigger>
        <PopoverContent bgColor='termos.beige' color='termos.orange'>
          <PopoverHeader fontWeight='semibold'>
            Product Involvement Areas
          </PopoverHeader>
          <PopoverArrow />
          <PopoverCloseButton color='white' />
          <PopoverBody>
            <Text color={esgScores?.adult ? 'termos.red' : 'termos.orange'}>
              adult
            </Text>
            <Text color={esgScores?.alcoholic ? 'termos.red' : 'termos.orange'}>
              alcoholic
            </Text>
            <Text
              color={esgScores?.animalTesting ? 'termos.red' : 'termos.orange'}
            >
              animalTesting
            </Text>
            <Text color={esgScores?.catholic ? 'termos.red' : 'termos.orange'}>
              catholic
            </Text>
            <Text
              color={
                esgScores?.controversialWeapons ? 'termos.red' : 'termos.orange'
              }
            >
              controversialWeapons
            </Text>
            <Text color={esgScores?.smallArms ? 'termos.red' : 'termos.orange'}>
              smallArms
            </Text>
            <Text
              color={esgScores?.furLeather ? 'termos.red' : 'termos.orange'}
            >
              furLeather
            </Text>
            <Text color={esgScores?.gambling ? 'termos.red' : 'termos.orange'}>
              gambling
            </Text>
            <Text color={esgScores?.gmo ? 'termos.red' : 'termos.orange'}>
              gmo
            </Text>
            <Text
              color={
                esgScores?.militaryContract ? 'termos.red' : 'termos.orange'
              }
            >
              militaryContract
            </Text>
            <Text color={esgScores?.nuclear ? 'termos.red' : 'termos.orange'}>
              nuclear
            </Text>
            <Text
              color={esgScores?.pesticides ? 'termos.red' : 'termos.orange'}
            >
              pesticides
            </Text>
            <Text color={esgScores?.palmOil ? 'termos.red' : 'termos.orange'}>
              palmOil
            </Text>
            <Text color={esgScores?.coal ? 'termos.red' : 'termos.orange'}>
              coal
            </Text>
            <Text color={esgScores?.tobacco ? 'termos.red' : 'termos.orange'}>
              tobacco
            </Text>
          </PopoverBody>
        </PopoverContent>
      </Popover>
      <Flex borderBottom='1px' borderColor='termos.gray'>
        <Box mr={6}>
          <Text color='white'>Total ESG Risk score</Text>
          <Box>
            <Text
              fontSize='32px'
              display='inline'
              mr={1}
              color={scoreColor(esgScores?.totalEsg?.raw)}
            >
              {esgScores?.totalEsg?.fmt}
            </Text>
            <Text
              borderLeft='1px'
              borderColor='termos.gray'
              heigt='5px'
              display='inline'
              pl={1}
            >
              {esgScores?.percentile?.fmt}th
            </Text>
          </Box>
        </Box>
        <Box mr={6}>
          <Text color='white'>Environment Risk Score</Text>
          <Text
            fontSize='24px'
            color={scoreColor(esgScores?.environmentScore?.raw)}
          >
            {esgScores?.environmentScore?.fmt}
          </Text>
        </Box>
        <Box mr={6}>
          <Text color='white'>Social Risk Score</Text>
          <Text fontSize='24px' color={scoreColor(esgScores?.socialScore?.raw)}>
            {esgScores?.socialScore?.fmt}
          </Text>
        </Box>
        <Box mr={6}>
          <Text color='white'>Governance Risk Score</Text>
          <Text
            fontSize='24px'
            color={scoreColor(esgScores?.governanceScore?.raw)}
          >
            {esgScores?.governanceScore?.fmt}
          </Text>
        </Box>
      </Flex>
      <Box borderBottom='1px' borderColor='termos.gray' mt={4}>
        <Text color='white' fontSize='16px'>
          Peer performance: {esgScores?.peerGroup} ({esgScores?.peerCount})
        </Text>
        <Table
          variant='simple'
          color='termos.orange'
          style={{ tableLayout: 'fixed' }}
          border='1px'
          borderColor='termos.gray'
        >
          <Thead bgColor='termos.beige'>
            <Tr>
              <Th color='white' border={0} pl={2.5}></Th>
              <Th color='white' border={0} pl={2.5}>
                Min
              </Th>
              <Th color='white' border={0} pl={2.5}>
                Avg
              </Th>
              <Th color='white' border={0} pl={2.5}>
                Max
              </Th>
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td border={0} pl={2.5}>
                Total
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerEsgScorePerformance?.min)}
              >
                {esgScores?.peerEsgScorePerformance?.min.toFixed(2)}
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerEsgScorePerformance?.avg)}
              >
                {esgScores?.peerEsgScorePerformance?.avg.toFixed(2)}
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerEsgScorePerformance?.max)}
              >
                {esgScores?.peerEsgScorePerformance?.max.toFixed(2)}
              </Td>
            </Tr>
            <Tr bgColor='termos.darkorange'>
              <Td border={0} pl={2.5}>
                Environment
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerEnvironmentPerformance?.min)}
              >
                {esgScores?.peerEnvironmentPerformance?.min.toFixed(2)}
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerEnvironmentPerformance?.avg)}
              >
                {esgScores?.peerEnvironmentPerformance?.avg.toFixed(2)}
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerEnvironmentPerformance?.max)}
              >
                {esgScores?.peerEnvironmentPerformance?.max.toFixed(2)}
              </Td>
            </Tr>
            <Tr>
              <Td border={0} pl={2.5}>
                Social
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerSocialPerformance?.min)}
              >
                {esgScores?.peerSocialPerformance?.min.toFixed(2)}
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerSocialPerformance?.avg)}
              >
                {esgScores?.peerSocialPerformance?.avg.toFixed(2)}
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerSocialPerformance?.max)}
              >
                {esgScores?.peerSocialPerformance?.max.toFixed(2)}
              </Td>
            </Tr>
            <Tr bgColor='termos.darkorange'>
              <Td border={0} pl={2.5}>
                Governance
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerGovernancePerformance?.min)}
              >
                {esgScores?.peerGovernancePerformance?.min.toFixed(2)}
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerGovernancePerformance?.avg)}
              >
                {esgScores?.peerGovernancePerformance?.avg.toFixed(2)}
              </Td>
              <Td
                border={0}
                pl={2.5}
                color={scoreColor(esgScores?.peerGovernancePerformance?.max)}
              >
                {esgScores?.peerGovernancePerformance?.max.toFixed(2)}
              </Td>
            </Tr>
          </Tbody>
        </Table>
      </Box>
      <Flex borderColor='termos.gray' mt={4}>
        <Box mr={24}>
          <Box>
            <Text color='white' fontSize='24px' mr={2} display='inline'>
              Controversy Level
            </Text>
            <Text
              fontSize='24px'
              display='inline'
              color={controversyColor(esgScores?.highestControversy)}
            >
              {esgScores?.highestControversy.toFixed(2)}
            </Text>
          </Box>
          <Box>
            <Text color='white' fontSize='16px' mr={2} display='inline'>
              Peer Average
            </Text>
            <Text
              fontSize='16px'
              display='inline'
              color={controversyColor(
                esgScores?.peerHighestControversyPerformance?.avg
              )}
            >
              {esgScores?.peerHighestControversyPerformance?.avg.toFixed(2)}
            </Text>
          </Box>
        </Box>
        <Box>
          <Text color='white' fontSize='16px'>
            Related Controversy
          </Text>
          {esgScores?.relatedControversy?.map((c, i) => (
            <Text key={i}>{c}</Text>
          ))}
        </Box>
      </Flex>
    </Box>
  );
};
