import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Text } from '@chakra-ui/layout';
import {
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  useDisclosure,
  Modal,
  Button,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tooltip,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import {
  useGetWhaleWisdomFundRows,
  useGetWhaleWisdomStock,
} from '../../hooks/whalewisdom';
import { formatNumber } from '../../utils/number';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import { EQ, routes } from '../../routes';
import { intrestingFunds } from '../../utils/constants';
import { useGetMajorHoldersBreakdown } from '../../hooks/yahoofinance';

interface Props {
  ticker: string;
  doFetch: boolean;
}

export const WhaleWisdoms: React.FC<Props> = ({ ticker, doFetch }) => {
  const { data: whaleWisdomStock } = useGetWhaleWisdomStock(ticker, doFetch);

  return (
    <Box mt={4}>
      <Box mb={2}>
        <Text color='white' display='inline'>
          The top
        </Text>{' '}
        {whaleWisdomStock?.numberOfOwners}{' '}
        <Text color='white' display='inline'>
          institutions own
        </Text>{' '}
        {whaleWisdomStock?.topNumberPercentOwners} %{' '}
        <Text color='white' display='inline'>
          of
        </Text>{' '}
        {ticker.toUpperCase()}
        <Text color='white' display='inline'>
          .
        </Text>
      </Box>
      <MajorHoldersBreakdown ticker={ticker} />
      <Table
        variant='simple'
        color='termos.orange'
        border='1px'
        borderColor='termos.gray'
        style={{ tableLayout: 'fixed' }}
      >
        <Thead bgColor='termos.beige'>
          <Tr>
            <Th color='white' border={0} pl={2.5}>
              Type
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Shares Held or Principal Amt
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Market Value
            </Th>
            <Th color='white' border={0} pl={2.5}>
              % of Portfolio
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Prior % of Portfolio
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Change in Shares
            </Th>
            <Th color='white' border={0} pl={2.5}>
              % Ownership
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Source Date
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Date Reported
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Qtr 1st Owned
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {whaleWisdomStock &&
            whaleWisdomStock.rows?.map((wwr, i) => (
              <React.Fragment key={i}>
                <Tr>
                  <Td
                    pl={2.5}
                    colSpan={10}
                    border={0}
                    bgColor={i % 2 ? 'termos.darkorange' : 'black'}
                    color={
                      intrestingFunds.includes(
                        wwr.permalink ? wwr.permalink : ''
                      )
                        ? 'termos.green'
                        : 'termos.orange'
                    }
                  >
                    {wwr.name && wwr.permalink && (
                      <WhaleWisdomFund
                        name={wwr.name}
                        permalink={wwr.permalink}
                        ticker={ticker.toUpperCase()}
                      />
                    )}
                  </Td>
                </Tr>
                <Tr key={i} bgColor={i % 2 ? 'termos.darkorange' : 'black'}>
                  <Td pl={2.5} border={0}>
                    {wwr.securityType}
                  </Td>
                  <Td pl={2.5} border={0}>
                    {formatNumber(wwr.currentShares)}
                  </Td>
                  <Td pl={2.5} border={0}>
                    {formatNumber(wwr.currentMarketValue)}
                  </Td>
                  <Td pl={2.5} border={0}>
                    {wwr.currentPercentOfPortfolio}
                  </Td>
                  <Td pl={2.5} border={0}>
                    {wwr.previousPercentOfPortfolio}
                  </Td>
                  <Td
                    pl={2.5}
                    border={0}
                    color={
                      wwr.sharesChange && wwr.sharesChange > 0
                        ? 'termos.green'
                        : 'termos.red'
                    }
                  >
                    {formatNumber(wwr.sharesChange)}
                  </Td>
                  <Td pl={2.5} border={0}>
                    {wwr.percentOwnership}
                  </Td>
                  <Td pl={2.5} border={0}>
                    {wwr.sourceDate}
                  </Td>
                  <Td pl={2.5} border={0}>
                    {wwr.filingDate}
                  </Td>
                  <Td pl={2.5} border={0}>
                    {wwr.quarterFirstOwned}
                  </Td>
                </Tr>
              </React.Fragment>
            ))}
        </Tbody>
      </Table>
    </Box>
  );
};

interface MajorHoldersBreakdownProps {
  ticker: string;
}

const MajorHoldersBreakdown: React.FC<MajorHoldersBreakdownProps> = ({
  ticker,
}) => {
  const { data: quoteSummary } = useGetMajorHoldersBreakdown(ticker);

  if (!quoteSummary || !quoteSummary.result || quoteSummary.result.length === 0)
    return <>Could not get Major Holders Breakdown</>;

  const majorHoldersBreakdown = quoteSummary.result[0].majorHoldersBreakdown;

  return (
    <Box mb={4}>
      Insiders{' '}
      <Text color='white' display='inline'>
        hold
      </Text>{' '}
      {majorHoldersBreakdown?.insidersPercentHeld?.fmt}
      <Text color='white' display='inline'>
        .
      </Text>{' '}
      {majorHoldersBreakdown?.institutionsCount?.fmt} institutions{' '}
      <Text color='white' display='inline'>
        hold
      </Text>{' '}
      {majorHoldersBreakdown?.institutionsPercentHeld?.fmt}
      <Text color='white' display='inline'>
        .
      </Text>
    </Box>
  );
};

interface FundProps {
  name: string;
  permalink: string;
  ticker: string;
}

const WhaleWisdomFund: React.FC<FundProps> = ({ name, permalink, ticker }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [doSearch, setDoSearch] = useState(false);
  const { data: whaleWisdomFundRows } = useGetWhaleWisdomFundRows(
    doSearch,
    permalink
  );

  return (
    <>
      <Link
        onClick={() => {
          onOpen();
          setDoSearch(true);
        }}
      >
        {name}
      </Link>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          borderWidth={2}
          borderColor='termos.gray'
          maxW='1600px'
          mt={0}
        >
          <Box bgColor='termos.gray' h={9}>
            <Link
              href={`https://whalewisdom.com/filer/${permalink}#tabholdings_tab_link`}
              isExternal
            >
              <Text
                color='white'
                fontSize='md'
                fontWeight='bold'
                ml='28px'
                mt='4px'
              >
                {name} <ExternalLinkIcon mx='2px' />
              </Text>
            </Link>
          </Box>

          <ModalBody bgColor='black' color='termos.orange'>
            <Box h='700px' overflowY='auto'>
              <Table
                variant='simple'
                color='termos.orange'
                border='1px'
                borderColor='termos.gray'
              >
                <Thead bgColor='termos.beige' position='sticky' top='0'>
                  <Tr>
                    <Th color='white' border={0} pl={2.5}>
                      Ticker
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Sector
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Shares Held or Principal Amt
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Market Value
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      % of Portfolio
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Prior % of Portfolio
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Change in Shares
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      % Change
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      % Ownership
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Source Date
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Date Reported
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Qtr 1st Owned
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Estimated Avg Price Paid
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Quarter End Price
                    </Th>
                    <Th color='white' border={0} pl={2.5}>
                      Recent Price
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {whaleWisdomFundRows?.map((wwr, i) => (
                    <Tr
                      key={i}
                      bgColor={
                        wwr.symbol === ticker
                          ? 'termos.darkblue'
                          : i % 2
                          ? 'termos.darkorange'
                          : 'black'
                      }
                    >
                      <Td pl={2.5} border={0}>
                        <Tooltip
                          label={wwr.name ? wwr.name : ''}
                          bgColor='termos.beige'
                        >
                          <Link
                            as={RouterLink}
                            to={generatePath(
                              routes.find((r) => r.key === EQ)?.path ?? '',
                              {
                                ticker: wwr.symbol,
                              }
                            )}
                            isExternal
                          >
                            {wwr.symbol} <ExternalLinkIcon mx='2px' />
                          </Link>
                        </Tooltip>
                      </Td>
                      <Td pl={2.5} border={0}>
                        {wwr.sector}
                      </Td>
                      <Td pl={2.5} border={0}>
                        {formatNumber(wwr.currentShares)}
                      </Td>
                      <Td pl={2.5} border={0}>
                        {formatNumber(wwr.currentMarketValue)}
                      </Td>
                      <Td pl={2.5} border='1px'>
                        {wwr.currentPercentOfPortfolio}
                      </Td>
                      <Td pl={2.5} border={0}>
                        {wwr.previousPercentOfPortfolio}
                      </Td>
                      <Td
                        pl={2.5}
                        border={0}
                        color={
                          wwr.sharesChange && wwr.sharesChange > 0
                            ? 'termos.green'
                            : 'termos.red'
                        }
                      >
                        {formatNumber(wwr.sharesChange)}
                      </Td>
                      <Td pl={2.5} border={0}>
                        {wwr.percentSharesChange}
                      </Td>
                      <Td pl={2.5} border='1px'>
                        {wwr.percentOwnership}
                      </Td>
                      <Td pl={2.5} border={0}>
                        {wwr.sourceDate}
                      </Td>
                      <Td pl={2.5} border={0}>
                        {wwr.filingDate}
                      </Td>
                      <Td pl={2.5} border={0}>
                        {wwr.quarterFirstOwned}
                      </Td>
                      <Td pl={2.5} border={0}>
                        {wwr.avgPrice}
                      </Td>
                      <Td pl={2.5} border={0}>
                        {wwr.quarterEndPrice}
                      </Td>
                      <Td pl={2.5} border={0}>
                        {wwr.recentPrice}
                      </Td>
                    </Tr>
                  ))}
                </Tbody>
              </Table>
            </Box>
          </ModalBody>

          <ModalFooter bgColor='black'>
            <Button
              bgColor='termos.gray'
              color='white'
              onClick={() => {
                onClose();
                setDoSearch(false);
              }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
