import { Box, Text } from '@chakra-ui/layout';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Button,
} from '@chakra-ui/react';
import React from 'react';

interface Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  heading: string;
  description: string;
  onDelete: () => void;
  deleteButtonText?: string;
}

export const DeleteModal: React.FC<Props> = ({
  id,
  isOpen,
  onClose,
  heading,
  description,
  onDelete,
  deleteButtonText = 'Delete',
}) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} id={id}>
        <ModalOverlay />
        <ModalContent borderWidth={2} borderColor='termos.gray'>
          <Box bgColor='termos.gray' h={9}>
            <Text
              color='white'
              fontSize='md'
              fontWeight='bold'
              ml='22px'
              mt='4px'
            >
              {heading}
            </Text>
          </Box>
          <ModalBody bgColor='black' color='termos.orange'>
            {description}
          </ModalBody>

          <ModalFooter bgColor='black'>
            <Button
              bgColor='termos.gray'
              color='white'
              onClick={onClose}
              mr={4}
            >
              Close
            </Button>
            <Button bgColor='termos.gray' color='white' onClick={onDelete}>
              {deleteButtonText}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
