import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Text } from '@chakra-ui/react';
import React, { useState } from 'react';
import { termosColors } from '../..';
import { AdminPortfoliosClient } from '../../ApiClients';

const adminPortfoliosClient = new AdminPortfoliosClient();

export const Portfolio: React.FC = () => {
  const [changes, setChanges] = useState({
    isLoading: false,
    ok: false,
    err: false,
  });

  const calcChanges = () => {
    setChanges({
      isLoading: true,
      ok: false,
      err: false,
    });
    adminPortfoliosClient
      .calculateChanges()
      .then(() => {
        setChanges((prev) => ({
          ...prev,
          isLoading: false,
          ok: true,
        }));
      })
      .catch(() => {
        setChanges((prev) => ({
          ...prev,
          isLoading: false,
          err: true,
        }));
      });
  };

  return (
    <Box p={6}>
      <Box border='1px' borderColor='black' p={2} w='100%'>
        <Text color='black' fontWeight='bold' mb={2}>
          Calculate portfolio changes (daily)
        </Text>
        <Button onClick={calcChanges} isLoading={changes.isLoading} mr={4}>
          GO
        </Button>
        {changes.ok && <CheckIcon w={6} h={6} color={termosColors.darkgreen} />}
        {changes.err && <CloseIcon w={5} h={5} color={termosColors.darkred} />}
      </Box>
    </Box>
  );
};
