import { Box, Flex, Text } from '@chakra-ui/layout';
import React from 'react';
import { Company, Fundamental } from '../../ApiClients';
import { ColorTextedLabel } from '../../components/ColorTextedLabel';
import { TinyTd, TTh } from '../../components/TTable';
import { formatDateSpecial } from '../../utils/date';
import { plvColor, plvText } from './Prices';
import { fundamentalColorRules } from './utils';

interface Props {
  fundamental: Fundamental | undefined;
  company?: Company;
}

export const Ratios: React.FC<Props> = ({ fundamental }) => {
  return (
    <Box border='1px' p={2} borderColor='termos.gray'>
      <Box mb={1}>
        <Text color='white' display='inline' mr={2} mb={1}>
          Date:
        </Text>
        {formatDateSpecial(fundamental?.calendardate, 'dd.MM.yyyy')}
      </Box>
      <Box mb={1}>
        <Text color='white' display='inline' mr={2}>
          Dimension:
        </Text>
        {fundamental?.dimension}
      </Box>
      <Box
        color={fundamentalColorRules('gnddiff', fundamental?.gnddiff)}
        mb={1}
      >
        GNDD: {fundamental?.gnddiff}
      </Box>
      <Box color={fundamentalColorRules('de', fundamental?.de)} mb={1}>
        DE: {fundamental?.de}
      </Box>
      <Box
        color={fundamentalColorRules('currentratio', fundamental?.currentratio)}
        mb={1}
      >
        CR: {fundamental?.currentratio}
      </Box>
      <Box color={fundamentalColorRules('roe', fundamental?.roe)} mb={1}>
        ROE: {fundamental?.roe}
      </Box>
      <Box
        color={fundamentalColorRules('divyield', fundamental?.divyield)}
        mb={1}
      >
        DY: {fundamental?.divyield}
      </Box>
      <Box
        color={fundamentalColorRules('netmargin', fundamental?.netmargin)}
        mb={1}
      >
        PM: {fundamental?.netmargin}
      </Box>
    </Box>
  );
};

export const FlatlistRatios: React.FC<Props> = ({ fundamental, company }) => {
  const Spacer = (
    <Box pl={1} pr={1} color='white'>
      /
    </Box>
  );
  return (
    <Box border='1px' p={2} borderColor='termos.gray'>
      <Flex>
        <Box color={fundamentalColorRules('gnddiff', fundamental?.gnddiff)}>
          GNDD: {fundamental?.gnddiff}
        </Box>
        {Spacer}
        <Box color={fundamentalColorRules('de', fundamental?.de)}>
          DE: {fundamental?.de}
        </Box>
        {Spacer}
        <Box
          color={fundamentalColorRules(
            'currentratio',
            fundamental?.currentratio
          )}
        >
          CR: {fundamental?.currentratio}
        </Box>
        {Spacer}
        <Box color={fundamentalColorRules('roe', fundamental?.roe)} mr={1}>
          ROE: {fundamental?.roe}
        </Box>
        {Spacer}
        <Box color={fundamentalColorRules('divyield', fundamental?.divyield)}>
          DY: {fundamental?.divyield}
        </Box>
        {Spacer}
        <Box color={fundamentalColorRules('netmargin', fundamental?.netmargin)}>
          PM: {fundamental?.netmargin}
        </Box>
      </Flex>
      {company && (
        <Flex>
          <ColorTextedLabel
            label='Plv:'
            text={company.plv}
            color={plvColor(company.plv)}
          />
          {company.plv && Spacer}
          <Box color={plvColor(company.plv)}>{plvText(company.plv)}</Box>
        </Flex>
      )}
    </Box>
  );
};

export const ThRatios: React.FC = () => {
  return (
    <>
      <TTh>GND</TTh>
      <TTh>DE</TTh>
      <TTh>CR</TTh>
      <TTh>ROE</TTh>
      <TTh>DY</TTh>
      <TTh>PM</TTh>
      <TTh>PLV</TTh>
    </>
  );
};

export const TinyTdRatios: React.FC<Props> = ({ fundamental, company }) => {
  return (
    <>
      <TinyTd color={fundamentalColorRules('gnddiff', fundamental?.gnddiff)}>
        {fundamental?.gnddiff}
      </TinyTd>
      <TinyTd color={fundamentalColorRules('de', fundamental?.de)}>
        {fundamental?.de}
      </TinyTd>
      <TinyTd
        color={fundamentalColorRules('currentratio', fundamental?.currentratio)}
      >
        {fundamental?.currentratio}
      </TinyTd>
      <TinyTd color={fundamentalColorRules('roe', fundamental?.roe)}>
        {fundamental?.roe}
      </TinyTd>
      <TinyTd color={fundamentalColorRules('divyield', fundamental?.divyield)}>
        {fundamental?.divyield}
      </TinyTd>
      <TinyTd
        color={fundamentalColorRules('netmargin', fundamental?.netmargin)}
      >
        {fundamental?.netmargin}
      </TinyTd>
      <TinyTd color={plvColor(company?.plv)}>
        {company?.plv ? plvText(company.plv) : '-'}
      </TinyTd>
    </>
  );
};
