import { Box } from '@chakra-ui/layout';
import { Collapse } from '@chakra-ui/react';
import React from 'react';
import { Company } from '../../ApiClients';

interface Props {
  company: Company;
  isOpen: boolean;
}

export const Description: React.FC<Props> = ({ company, isOpen }) => {
  return (
    <Box maxW='1630px' mb={4}>
      <Collapse in={isOpen} animateOpacity>
        <Box border='1px' p={2} borderColor='termos.gray'>
          {company.description}
        </Box>
      </Collapse>
    </Box>
  );
};
