import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  ReactNode,
} from 'react';
import firebase from 'firebase/app';
import 'firebase/auth';

type Auth = {
  user: firebase.User | null;
  isLoading: boolean;
};

const AuthContext = createContext<Auth>({ user: null, isLoading: true });

type AuthProviderProps = {
  children: ReactNode | ReactNode[];
};

const AuthProvider = ({ children }: AuthProviderProps) => {
  const auth = useAuthProvider();
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

const useAuthState = (): Auth => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthState must be used within an AuthProvider');
  }
  return context;
};

const useAuthProvider = (): Auth => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let unsubscribe = () => {};

    if (!firebase.apps.length) {
      fetch(`${window.location.origin}/api/firebaseconfig/getconfig`)
        .then((response) => response.json())
        .then((config) => {
          firebase.initializeApp(config);
          firebase.analytics();

          unsubscribe = firebase.auth().onAuthStateChanged((user) => {
            if (user) {
              setUser(user);
            } else {
              setUser(null);
            }
            setIsLoading(false);
          });
        });
    }

    return () => unsubscribe();
  }, []);

  return { user, isLoading };
};

export { AuthProvider, useAuthState };
