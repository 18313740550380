import isValid from 'date-fns/isValid';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import toDate from 'date-fns/toDate';
import nb from 'date-fns/locale/nb';

export const parseDate = (date: string | number | Date): Date => {
  if (typeof date === 'string') return parseISO(date);
  return toDate(date);
};

export const formatDate = (
  date: string | number | Date,
  formatString: string
): string => {
  if (!isValid(parseDate(date))) return date.toString();
  return format(parseDate(date), formatString, { locale: nb });
};

export const formatDateSpecial = (date: any, formatString: string): string => {
  if (date === undefined || date === null) return '-';
  else if (!isValid(parseDate(date))) return date.toString();
  return format(parseDate(date), formatString, { locale: nb });
};

export const formatYearSpecial = (date: any): string => {
  if (date === undefined || date === null) return '-';
  else if (!isValid(parseDate(date))) return date.toString();
  return format(parseDate(date), 'yyyy', { locale: nb });
};

export const formatUnix = (date: number) => {
  return format(new Date(date * 1000), 'dd.MM.yyyy');
};

export const formatUnixSpecial = (date: number | undefined) => {
  if (!date) return '-';
  return format(new Date(date), 'dd.MM.yyyy');
};
