import { Box, Center, Flex, Link } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { ColorTextedLabel } from '../../components/ColorTextedLabel';
import { useGetDarkIndexClient } from '../../hooks/darkindex';
import { formatDateSpecial } from '../../utils/date';
import { formatNumber } from '../../utils/number';

export const DarkIndex: React.FC = () => {
  const { data: darkIndex } = useGetDarkIndexClient();

  const colorDix = (dix: number | undefined) => {
    if (dix === undefined || !darkIndex) return 'termos.orange';
    const equalizerHigh = darkIndex.dixAvg - darkIndex.dixHigh / 2;
    const equalizerLow = darkIndex.dixAvg - darkIndex.dixLow / 2;

    if (dix > darkIndex.dixAvg && dix < darkIndex.dixHigh + equalizerHigh)
      return 'termos.orange';
    if (dix >= darkIndex.dixHigh + equalizerHigh) return 'termos.green';

    if (dix < darkIndex.dixAvg && dix > darkIndex.dixLow + equalizerLow)
      return 'termos.orange';
    if (dix <= darkIndex.dixLow + equalizerLow) return 'termos.red';
  };

  const colorGex = (gex: number | undefined) => {
    if (gex === undefined || !darkIndex) return 'termos.orange';
    const equalizerHigh = darkIndex.gexAvg - darkIndex.gexHigh / 2;
    const equalizerLow = darkIndex.gexAvg - darkIndex.gexLow / 2;

    if (gex > darkIndex.gexAvg && gex < darkIndex.gexHigh + equalizerHigh)
      return 'termos.orange';
    if (gex >= darkIndex.gexHigh + equalizerHigh) return 'termos.red';

    if (gex < darkIndex.gexAvg && gex > darkIndex.gexLow + equalizerLow)
      return 'termos.orange';
    if (gex <= darkIndex.gexLow + equalizerLow) return 'termos.green';
  };

  const toolTipLabel = (
    <Center>
      <Flex>
        <Box mr={8}>
          <ColorTextedLabel label='Dix high:' text={`${darkIndex?.dixHigh}%`} />
          <ColorTextedLabel label='Dix avg:' text={`${darkIndex?.dixAvg}%`} />
          <ColorTextedLabel label='Dix low:' text={`${darkIndex?.dixLow}%`} />
        </Box>
        <Box>
          <ColorTextedLabel
            label='Gex high:'
            text={formatNumber(darkIndex?.gexHigh)}
          />
          <ColorTextedLabel
            label='Gex avg:'
            text={formatNumber(darkIndex?.gexAvg)}
          />
          <ColorTextedLabel
            label='Gex low:'
            text={formatNumber(darkIndex?.gexLow)}
          />
        </Box>
      </Flex>
    </Center>
  );

  return (
    <Box>
      <Center
        color='white'
        fontSize='16px'
        border='1px'
        borderColor='termos.gray'
      >
        <Link href='https://squeezemetrics.com/monitor/dix' isExternal>
          <Tooltip
            color='termos.orange'
            bgColor='termos.beige'
            label={toolTipLabel}
            placement='top'
            shouldWrapChildren
          >
            Dark Index - {formatDateSpecial(darkIndex?.date, 'dd.MM')}
          </Tooltip>
        </Link>
      </Center>
      <Box
        h='100px'
        overflowY='auto'
        border='1px'
        borderColor='termos.gray'
        p={2}
        fontSize='13px'
      >
        <Center>
          <Flex>
            <Box mr={8}>
              <ColorTextedLabel
                label='Dix:'
                text={`${darkIndex?.dix}%`}
                color={colorDix(darkIndex?.dix)}
              />
              <ColorTextedLabel
                label='Dix 1w:'
                text={`${darkIndex?.dixOneWeekAvg}%`}
                color={colorDix(darkIndex?.dixOneWeekAvg)}
              />
              <ColorTextedLabel
                label='Dix 1m:'
                text={`${darkIndex?.dixOneMonthAvg}%`}
                color={colorDix(darkIndex?.dixOneMonthAvg)}
              />
              <ColorTextedLabel
                label='Dix 1y:'
                text={`${darkIndex?.dixOneYearAvg}%`}
                color={colorDix(darkIndex?.dixOneYearAvg)}
              />
            </Box>
            <Box>
              <ColorTextedLabel
                label='Gex:'
                text={formatNumber(darkIndex?.gex)}
                color={colorGex(darkIndex?.gex)}
              />
              <ColorTextedLabel
                label='Gex 1w:'
                text={formatNumber(darkIndex?.gexOneWeekAvg)}
                color={colorGex(darkIndex?.gexOneWeekAvg)}
              />
              <ColorTextedLabel
                label='Gex 1m:'
                text={formatNumber(darkIndex?.gexOneMonthAvg)}
                color={colorGex(darkIndex?.gexOneMonthAvg)}
              />
              <ColorTextedLabel
                label='Gex 1y:'
                text={formatNumber(darkIndex?.gexOneYearAvg)}
                color={colorGex(darkIndex?.gexOneYearAvg)}
              />
            </Box>
          </Flex>
        </Center>
      </Box>
    </Box>
  );
};
