import { Box, Flex, Text } from '@chakra-ui/react';
import React from 'react';
import { ArticleModal } from '../../components/ArticleModal';
import { useGetReutersBusiness } from '../../hooks/news';
import { formatDate } from '../../utils/date';

export const LatestNews: React.FC = () => {
  const { data: articles } = useGetReutersBusiness();
  return (
    <Box
      color='termos.orange'
      p={2}
      border='1px'
      borderColor='termos.gray'
      h='750px'
      overflowY='auto'
    >
      {articles?.map((a, i) => (
        <Box mb={2} key={i}>
          <Box>
            <ArticleModal
              title={a.title ? a.title : ''}
              url={`https://www.reuters.com${a.canonical_url}`}
            />
          </Box>
          <Box>{a.description}</Box>
          <Flex>
            <Text color='white' mr={2}>
              {`${formatDate(a.updated_time, 'dd.MM.yyyy HH:mm')} CET`}
            </Text>
            <Text color='termos.blue'>Reuters</Text>
          </Flex>
        </Box>
      ))}
    </Box>
  );
};
