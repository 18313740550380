import { DeleteIcon } from '@chakra-ui/icons';
import {
  Button,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerBody,
  Input,
  DrawerFooter,
  useDisclosure,
  IconButton,
  Box,
  Text,
  Heading,
  InputGroup,
  InputRightAddon,
  Flex,
  Divider,
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { VscGraphLine } from 'react-icons/vsc';
import { Company, PriceAlertsClient, PriceAlertType } from '../../ApiClients';
import { useGetPriceAlertForCompany } from '../../hooks/pricealert';
import { useGetOptionWithQuote } from '../../hooks/yahoofinance';

const priceAlertsClient = new PriceAlertsClient();

interface Props {
  company: Company;
}

export const CreatePriceAlert: React.FC<Props> = ({ company }) => {
  const { data: priceAlerts, refetch } = useGetPriceAlertForCompany(company.id);
  const { data: optionWithQuote } = useGetOptionWithQuote(
    company.ticker ? company.ticker : '',
    true
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [target, setTarget] = useState('');
  const [type, setType] = useState<PriceAlertType | undefined>(undefined);
  const quote = optionWithQuote?.quote;

  useEffect(() => {
    if (target !== '' && quote) {
      var price = quote.regularMarketPrice;
      if (parseFloat(target) >= price) {
        setType(PriceAlertType.Above);
      } else {
        setType(PriceAlertType.Below);
      }
    } else {
      setType(undefined);
    }
  }, [target, quote]);

  const handleCreatePriceAlert = () => {
    if (type === undefined) return;
    priceAlertsClient
      .create({ companyId: company.id, target: parseFloat(target), type: type })
      .then(() => {
        setTarget('');
        setType(undefined);
        refetch();
      });
  };

  const getType = (target: number) => {
    if (quote) {
      var price = quote.regularMarketPrice;
      if (target >= price) {
        return 'above';
      } else {
        return 'below';
      }
    } else {
      return '';
    }
  };

  const handleDeletePriceAlert = (id: number) => {
    priceAlertsClient.delete(id).then(() => refetch());
  };

  return (
    <>
      <IconButton
        aria-label='Add to watchlist'
        icon={
          <>
            <VscGraphLine />
            {priceAlerts && priceAlerts.length > 0
              ? `(${priceAlerts.length})`
              : ''}
          </>
        }
        colorScheme='ghost'
        variant='solid'
        color={'white'}
        size='lg'
        onClick={onOpen}
        _hover={{ color: 'termos.orange' }}
      />
      <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          bgColor='black'
          border='1px'
          borderColor='termos.gray'
          color='white'
        >
          <DrawerCloseButton />
          <DrawerHeader>Price alert</DrawerHeader>

          <DrawerBody>
            <Box mb={4}>
              <Text color='white'>
                Price{' '}
                {type !== undefined
                  ? type === PriceAlertType.Above
                    ? 'above'
                    : 'below'
                  : ''}
              </Text>
              <InputGroup size='sm'>
                <Input
                  color='black'
                  bgColor='termos.orange'
                  border={0}
                  _focus={{ outline: 'none' }}
                  _hover={{ outline: 'none' }}
                  w='162px'
                  type='text'
                  value={target}
                  onChange={(e) => setTarget(e.target.value)}
                />
                <InputRightAddon
                  color='black'
                  bgColor='termos.orange'
                  borderColor='termos.orange'
                  children={company.currency?.value}
                />
              </InputGroup>
              <Box mt={4}>
                <Button
                  variant='outline'
                  _hover={{
                    color: 'termos.orange',
                    borderColor: 'termos.orange',
                  }}
                  onClick={handleCreatePriceAlert}
                >
                  Create
                </Button>
              </Box>
            </Box>
            <Divider />
            <Box mt={4}>
              <Heading as='h4' size='md' mb={2}>
                Active
              </Heading>
              {priceAlerts?.map((pa) => (
                <Flex
                  key={pa.id}
                  p={2}
                  border='1px'
                  borderColor='termos.gray'
                  justifyContent='space-between'
                  mb={2}
                >
                  <Box color='termos.orange' mt='5px'>{`Price ${getType(
                    pa.target
                  )} ${pa.target} ${company.currency?.value}`}</Box>
                  <IconButton
                    aria-label='Add to watchlist'
                    icon={<DeleteIcon />}
                    colorScheme='ghost'
                    variant='solid'
                    color={'white'}
                    size='sm'
                    _hover={{ color: 'termos.orange' }}
                    p={0}
                    onClick={() => handleDeletePriceAlert(pa.id)}
                  />
                </Flex>
              ))}
              {!priceAlerts || priceAlerts.length === 0
                ? 'no active alerts'
                : ''}
            </Box>
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};
