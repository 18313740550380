import React from 'react';
import {
  Chart as ChartJS,
  Tooltip,
  Legend,
  ChartData,
  ArcElement,
} from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

interface TPieChartProps {
  data: ChartData<'pie', any[], string>;
  labelSymbol?: string;
}

export const TPieChart: React.FC<TPieChartProps> = ({ data, labelSymbol }) => {
  return (
    <Pie
      data={data}
      options={{
        plugins: {
          datalabels: {
            color: 'black',
            font: {
              family: 'monospace',
            },
            formatter: (value: any) => {
              return `${value}${labelSymbol ? labelSymbol : ''}`;
            },
          },
          legend: {
            labels: {
              color: '#FF9D2A',
              font: {
                family: 'monospace',
              },
            },
          },
          tooltip: {
            backgroundColor: 'white',
            bodyFont: {
              family: 'monospace',
            },
            bodyColor: 'black',
            displayColors: false,
            callbacks: {
              label: (tooltipItems: any) => {
                return tooltipItems.label;
              },
            },
          },
        },
      }}
    />
  );
};
