import { AddIcon, CheckIcon, DeleteIcon, ViewIcon } from '@chakra-ui/icons';
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  IconButton,
  Checkbox,
  Box,
  Button,
  Input,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { WatchlistCompany, WatchlistsClient } from '../../ApiClients';
import { useGetWatchlists } from '../../hooks/watchlists';

interface Props {
  companyId: number;
}

const watchlistsClient = new WatchlistsClient();

export const AddToWatchlist: React.FC<Props> = ({ companyId }) => {
  const { data: watchlists, refetch } = useGetWatchlists();
  const [addingNewList, setAddingNewList] = useState(false);
  const [newListName, setNewListName] = useState('');

  const companyInAnyWatchlist = () => {
    if (!watchlists) return false;

    const watchlistCompanies = watchlists.flatMap((w) =>
      w.companies ? w.companies : []
    );
    return watchlistCompanies.some((c) => c.companyId === companyId);
  };

  const handleAddNewWatchlist = () => {
    watchlistsClient.create(newListName).then(() => {
      setAddingNewList(false);
      setNewListName('');
      refetch();
    });
  };

  const handleCheck = (
    id: number,
    watchlistCompanies: WatchlistCompany[] | undefined
  ) => {
    if (
      !watchlistCompanies ||
      !watchlistCompanies.some((wc) => wc.companyId === companyId)
    ) {
      watchlistsClient.addCompany(id, companyId).then(() => {
        refetch();
      });
    } else {
      var watchlistCompany = watchlistCompanies.find(
        (wc) => wc.companyId === companyId
      );
      if (watchlistCompany) {
        watchlistsClient.removeCompany(watchlistCompany.id).then(() => {
          refetch();
        });
      }
    }
  };

  return (
    <Box>
      <Popover>
        <PopoverTrigger>
          <IconButton
            aria-label='Add to watchlist'
            icon={<ViewIcon />}
            colorScheme='ghost'
            variant='solid'
            color={companyInAnyWatchlist() ? 'termos.orange' : 'white'}
            size='lg'
          />
        </PopoverTrigger>
        <PopoverContent bgColor='termos.beige' color='termos.orange'>
          <PopoverArrow />
          <PopoverCloseButton color='white' />
          <PopoverHeader color='white' fontSize='16px'>
            Add to watchlist
          </PopoverHeader>
          <PopoverBody>
            <Box mt={2} mb={4}>
              {watchlists?.map((w) => (
                <Box key={w.id} mb={3}>
                  <Checkbox
                    isChecked={w.companies?.some(
                      (c) => c.companyId === companyId
                    )}
                    onChange={() => handleCheck(w.id, w.companies)}
                  >
                    {w.name}
                  </Checkbox>
                </Box>
              ))}
            </Box>
            {addingNewList ? (
              <Box>
                <Input
                  color='black'
                  bgColor='termos.orange'
                  border={0}
                  _focus={{ outline: 'none' }}
                  _hover={{ outline: 'none' }}
                  maxW='186px'
                  minW='185px'
                  onChange={(e) => setNewListName(e.target.value)}
                  value={newListName}
                  type='text'
                  mr={3}
                />
                <IconButton
                  aria-label='Discard new watchlist'
                  icon={<DeleteIcon />}
                  colorScheme='ghost'
                  variant='solid'
                  color='white'
                  onClick={() => {
                    setNewListName('');
                    setAddingNewList(false);
                  }}
                  mr={2}
                />
                <IconButton
                  aria-label='Add new watchlist'
                  icon={<CheckIcon />}
                  colorScheme='ghost'
                  variant='solid'
                  color='termos.orange'
                  onClick={handleAddNewWatchlist}
                />
              </Box>
            ) : (
              <Box>
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme='ghost'
                  variant='solid'
                  onClick={() => setAddingNewList(true)}
                  pl={0}
                  fontSize='15px'
                >
                  New watchlist
                </Button>
              </Box>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  );
};
