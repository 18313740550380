export const formatNumber = (n: number | undefined) => {
  if (!n) return '-';

  if (n < 1000 && n > -1000) return n.toString();

  return new Intl.NumberFormat('en-GB', {
    notation: 'compact',
    compactDisplay: 'short',
  }).format(n);
};

export const colorNumberChange = (value: number | undefined) => {
  if (value === undefined) return 'termos.orange';

  if (value >= 2.5) return 'termos.green';
  else if (value <= -2.5) return 'termos.red';
  else return 'termos.orange';
};

export const toPercent = (value: number | undefined) => {
  if (value === undefined) return undefined;

  return value * 100;
};

export const colorNumberAroundZero = (value: number | undefined) => {
  if (value === undefined) return 'termos.orange';

  if (value > 0) return 'termos.green';
  else if (value < 0) return 'termos.red';
  else return 'termos.orange';
};

export const formatThousandSpace = (value: number | undefined) => {
  return value?.toLocaleString('no').replaceAll(',', '.');
};
