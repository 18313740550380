import { Box } from '@chakra-ui/react';
import { Company, Fundamental } from '../../ApiClients';
import { formatDateSpecial } from '../../utils/date';
import React from 'react';
import { useGetOptionWithQuote } from '../../hooks/yahoofinance';
import { ColorTextedLabel } from '../../components/ColorTextedLabel';

export const plvColor = (value: number | undefined) => {
  if (!value) return 'white';

  if (value < 0.5) return 'termos.red';
  else if (value >= 0.5 && value < 1) return 'termos.red';
  else if (value >= 1 && value < 2) return 'termos.orange';
  else if (value >= 2 && value < 3) return 'termos.green';
  else if (value >= 3) return 'termos.green';
};

export const plvText = (value: number | undefined) => {
  if (!value) return '';

  if (value < 0.5) return 'VERY Over-Valued';
  else if (value >= 0.5 && value < 1) return 'Over-Valued';
  else if (value >= 1 && value < 2) return 'Fairly-Valued';
  else if (value >= 2 && value < 3) return 'Under-Valued';
  else if (value >= 3) return 'VERY Under-Valued';
};

interface Props {
  company: Company;
  fundamental: Fundamental | undefined;
  doFetch?: boolean;
}

export const Prices: React.FC<Props> = ({
  company,
  fundamental,
  doFetch = true,
}) => {
  const { data: optionWithQuote } = useGetOptionWithQuote(
    company.ticker ? company.ticker : '',
    doFetch
  );
  const quote = optionWithQuote?.quote;

  return (
    <Box border='1px' p={2} borderColor='termos.gray' w='180px'>
      <Box mb={1}>
        <ColorTextedLabel label='Price:' text={fundamental?.price} />
      </Box>
      <Box mb={1}>
        <ColorTextedLabel label='GND:' text={fundamental?.gnd} />
      </Box>
      <Box mb={1}>
        <ColorTextedLabel
          label='Plv:'
          text={company.plv}
          color={plvColor(company.plv)}
        />
        <Box color={plvColor(company.plv)}>{plvText(company.plv)}</Box>
      </Box>
      <Box mb={4}>
        <ColorTextedLabel
          label='at'
          text={formatDateSpecial(fundamental?.calendardate, 'dd.MM.yyyy')}
        />
      </Box>
      {quote && (
        <Box mb={4}>
          <Box mb={1}>
            <ColorTextedLabel
              label='52wk High:'
              text={quote.fiftyTwoWeekHigh.toFixed(2)}
            />
          </Box>
          <Box mb={1}>
            <ColorTextedLabel
              label='52wk Low:'
              text={quote.fiftyTwoWeekLow.toFixed(2)}
            />
          </Box>
        </Box>
      )}
      <Box>
        <ColorTextedLabel label='My Price:' text={company?.myPrice} />
      </Box>
    </Box>
  );
};
