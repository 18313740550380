import { useQuery } from 'react-query';
import {
  WhaleWisdomStock,
  WhaleWisdomsClient,
  WhaleWisdomFundRow,
} from '../ApiClients';

const whaleWisdomsClient = new WhaleWisdomsClient();

export const whaleWisdomStockKey = (ticker: string) =>
  `whaleWisdomStockKey-${ticker}`;

export const useGetWhaleWisdomStock = (ticker: string, doFetch: boolean) =>
  useQuery<WhaleWisdomStock>(
    whaleWisdomStockKey(ticker),
    () => whaleWisdomsClient.getStock(ticker),
    {
      retry: false,
      enabled: doFetch,
      cacheTime: 28800000,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const whaleWisdomFundRowsKey = (permalink: string) =>
  `whaleWisdomFundRowsKey-${permalink}`;

export const useGetWhaleWisdomFundRows = (
  doSearch: boolean,
  permalink: string
) =>
  useQuery<WhaleWisdomFundRow[]>(
    whaleWisdomFundRowsKey(permalink),
    () => whaleWisdomsClient.getFund(permalink),
    {
      retry: false,
      enabled: doSearch,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
