import { Box, Text } from '@chakra-ui/layout';
import React from 'react';
import { Company } from '../../ApiClients';
import { ColorTextedLabel } from '../../components/ColorTextedLabel';
import {
  useGetAssetProfile,
  useGetDefaultKeyStatistics,
} from '../../hooks/yahoofinance';

interface Props {
  company: Company;
}

export const Risk: React.FC<Props> = ({ company }) => {
  const { data: qsAsset } = useGetAssetProfile(
    company.ticker ? company.ticker : ''
  );

  const { data: qaDefault } = useGetDefaultKeyStatistics(
    company.ticker ? company.ticker : ''
  );

  if (
    !qsAsset ||
    !qaDefault ||
    !qsAsset.result ||
    qsAsset.result.length === 0 ||
    !qaDefault.result ||
    qaDefault.result.length === 0
  )
    return <>Could not get risk</>;

  const assetProfile = qsAsset.result[0].assetProfile;
  const defaultKeyStatistics = qaDefault.result[0].defaultKeyStatistics;

  const riskColor = (value: number | undefined) => {
    if (value) {
      if (value < 3.5) return 'termos.green';
      else if (value >= 3.5 && value < 7) return 'termos.orange';
      else return 'termos.red';
    } else {
      return 'termos.orange';
    }
  };

  return (
    <Box
      pt={4}
      border='1px'
      p={2}
      borderColor='termos.gray'
      mt={4}
      h='600px'
      overflowY='auto'
    >
      <Box
        fontSize='16px'
        color='white'
        border='1px'
        p={2}
        borderColor='termos.gray'
        mb={2}
      >
        Beta (5Y Monthly):{' '}
        <Text color='termos.orange' display='inline'>
          {defaultKeyStatistics?.beta?.fmt}
        </Text>
      </Box>
      <Box fontSize='16px' color='white'>
        Governance
      </Box>
      <Box border='1px' p={2} borderColor='termos.gray'>
        <Box fontSize='16px'>
          <ColorTextedLabel
            label='Overall:'
            text={assetProfile?.overallRisk}
            color={riskColor(assetProfile?.overallRisk)}
          />
        </Box>
        <Box>
          <ColorTextedLabel
            label='Audit:'
            text={assetProfile?.auditRisk}
            color={riskColor(assetProfile?.auditRisk)}
          />
        </Box>
        <Box>
          <ColorTextedLabel
            label='Board:'
            text={assetProfile?.boardRisk}
            color={riskColor(assetProfile?.boardRisk)}
          />
        </Box>
        <Box>
          <ColorTextedLabel
            label='Compensation:'
            text={assetProfile?.compensationRisk}
            color={riskColor(assetProfile?.compensationRisk)}
          />
        </Box>
        <Box>
          <ColorTextedLabel
            label='Share Holder Rights:'
            text={assetProfile?.shareHolderRightsRisk}
            color={riskColor(assetProfile?.shareHolderRightsRisk)}
          />
        </Box>
        <Box pt={2} color='white'>
          Corporate governance scores courtesy of Institutional Shareholder
          Services (ISS). Scores indicate decile rank relative to index or
          region. A decile score of 1 indicates lower governance risk, while a
          10 indicates higher governance risk.
        </Box>
      </Box>
      <Box fontSize='16px' color='white' mt={2}>
        Shorting
      </Box>
      <Box border='1px' p={2} borderColor='termos.gray'>
        <ColorTextedLabel
          label='Shares:'
          text={defaultKeyStatistics?.sharesShort?.fmt}
        />
        <ColorTextedLabel
          label='Percent:'
          text={defaultKeyStatistics?.shortPercentOfFloat?.fmt}
        />
        <ColorTextedLabel
          label='Ratio:'
          text={`${defaultKeyStatistics?.shortRatio?.fmt} (days-to-cover)`}
        />
        <ColorTextedLabel
          label='Date:'
          text={defaultKeyStatistics?.dateShortInterest?.fmt}
        />
        <ColorTextedLabel
          label='Previous month shares:'
          text={defaultKeyStatistics?.sharesShortPriorMonth?.fmt}
        />
        <ColorTextedLabel
          label='Previous month date:'
          text={defaultKeyStatistics?.sharesShortPreviousMonthDate?.fmt}
        />
        <Box pt={2} color='white'>
          10-30% short intrest with 8-10 days to cover.
        </Box>
      </Box>
    </Box>
  );
};
