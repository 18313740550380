import { useQuery } from 'react-query';
import { Article, DuckDuckGoResult, NewsClient } from '../ApiClients';

const newsClient = new NewsClient();

export const duckduckGoNewsKey = (ticker: string, orderTop: boolean) =>
  `duckduckGoNewsKey-${ticker}-${orderTop}`;

export const useGetDuckDuckGoNews = (
  name: string,
  ticker: string,
  orderTop: boolean
) =>
  useQuery<DuckDuckGoResult[]>(
    duckduckGoNewsKey(ticker, orderTop),
    () => newsClient.getDuckDuckgo(name, ticker, orderTop),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );

export const articleKey = (url: string) => `article-${url}`;

export const useGetArticle = (doSearch: boolean, url: string) =>
  useQuery<string>(articleKey(url), () => newsClient.getArticle(url), {
    enabled: doSearch,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });

export const useGetReutersBusiness = () =>
  useQuery<Article[]>(
    'ReutersBusiness',
    () => newsClient.getReutersBusiness(),
    {
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    }
  );
