import {
  Box,
  Button,
  Center,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Radio,
  RadioGroup,
  Stack,
  Text,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { CompaniesClient, CompanyRatingRequest } from '../ApiClients';
import { useGetCompany } from '../hooks';
import { formatDate } from '../utils/date';

const companiesClient = new CompaniesClient();

interface Props {
  ticker: string;
}

export const CompanyRating: React.FC<Props> = ({ ticker }) => {
  const { data: company, refetch } = useGetCompany(ticker);
  const [defaultRatings, setDefaultRatings] = useState<CompanyRatingRequest[]>([
    { label: 'Fundamental', value: undefined },
    { label: 'Insider', value: undefined },
    { label: 'Ownership', value: undefined },
    { label: 'Management', value: undefined },
    { label: 'Business', value: undefined },
    { label: 'Price', value: undefined },
    { label: 'Intrinsic', value: undefined },
    { label: 'IsPublic', value: undefined },
    { label: 'Compare', value: undefined },
    { label: 'Growth', value: undefined },
  ]);

  if (!company) return <></>;

  const bgColor = () => {
    if (!company.companyRatingTotal) return 'termos.blue';
    else if (company.companyRatingTotal < 5) return 'termos.red';
    else if (company.companyRatingTotal >= 5 && company.companyRatingTotal < 8)
      return 'termos.orange';
    else if (company.companyRatingTotal >= 8) return 'termos.green';
  };

  const handleDefaultRatingsChange = (
    defaultRating: CompanyRatingRequest,
    newVal: string
  ) => {
    const idx = defaultRatings.indexOf(defaultRating);
    const filterRatings = defaultRatings.filter(
      (dr) => dr.label !== defaultRating.label
    );
    const newDefaultRating = { ...defaultRating, value: parseInt(newVal) };
    filterRatings.splice(idx, 0, newDefaultRating);
    setDefaultRatings(filterRatings);
  };

  const handleSaveNewRatings = () => {
    companiesClient
      .setRatings({ companyId: company.id, companyRatings: defaultRatings })
      .then(() => refetch());
  };

  const handleRatingChane = (companyRatingId: number, newVal: string) => {
    companiesClient
      .updateRating({
        companyId: company.id,
        companyRatingId: companyRatingId,
        value: parseInt(newVal),
      })
      .then(() => refetch());
  };

  return (
    <>
      <Popover placement='right'>
        <PopoverTrigger>
          <Box display='inline-block'>
            <Center
              bgColor={bgColor()}
              borderRadius='50%'
              h='16px'
              w='16px'
              border='1px'
              borderColor='black'
              _hover={{ cursor: 'pointer' }}
            >
              <Box as='span' color='black' fontSize='11px' fontWeight='bold'>
                {company.companyRatingTotal ? company.companyRatingTotal : 'N'}
              </Box>
            </Center>
          </Box>
        </PopoverTrigger>
        <PopoverContent borderRadius={0}>
          <PopoverArrow />
          <PopoverCloseButton color='white' />
          <PopoverHeader
            bgColor='termos.beige'
            color='white'
            fontWeight='bold'
            fontSize='16px'
          >
            Company Rating
            {company.companyRatings && company.companyRatings.length > 0
              ? ` - ${formatDate(
                  company.companyRatings[0].updatedUtc,
                  'dd.MM.yyyy'
                )}`
              : ''}
          </PopoverHeader>
          <PopoverBody bgColor='termos.beige' color='white'>
            {company.companyRatings?.map((cr) => (
              <Flex key={cr.id} mb={4} justifyContent='space-between'>
                <Text
                  color={
                    cr.value === undefined
                      ? 'white'
                      : cr.value === 1
                      ? 'termos.green'
                      : 'termos.red'
                  }
                  mr={2}
                  fontWeight='bold'
                  fontSize='16px'
                >
                  {cr.label}
                </Text>
                <RadioGroup
                  onChange={(e) => handleRatingChane(cr.id, e)}
                  value={cr.value}
                  mr={9}
                >
                  <Stack direction='row'>
                    <Radio value={1}>Ye</Radio>
                    <Radio value={0}>No</Radio>
                  </Stack>
                </RadioGroup>
              </Flex>
            ))}
            {(!company.companyRatings ||
              company.companyRatings.length === 0) && (
              <>
                {defaultRatings.map((dr, i) => (
                  <Flex key={i} mb={4} justifyContent='space-between'>
                    <Text
                      color={
                        dr.value === undefined
                          ? 'white'
                          : dr.value === 1
                          ? 'termos.green'
                          : 'termos.red'
                      }
                      mr={2}
                      fontWeight='bold'
                      fontSize='16px'
                    >
                      {dr.label}
                    </Text>
                    <RadioGroup
                      onChange={(e) => handleDefaultRatingsChange(dr, e)}
                      value={dr.value}
                      mr={9}
                    >
                      <Stack direction='row'>
                        <Radio value={1}>Ye</Radio>
                        <Radio value={0}>No</Radio>
                      </Stack>
                    </RadioGroup>
                  </Flex>
                ))}
                <Button
                  color='white'
                  variant='unstyled'
                  border='1px'
                  p={1}
                  _hover={{ color: 'termos.orange' }}
                  onClick={handleSaveNewRatings}
                >
                  Save new Ratings
                </Button>
              </>
            )}
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </>
  );
};
