import { Input } from '@chakra-ui/input';
import { Box, Flex, Text } from '@chakra-ui/layout';
import React from 'react';
import { TermosSelect } from '../../components/TermosSelect';
import { useScreenFilterMetrics } from '../../hooks/screen';
import { ScreenFilter } from '../../ApiClients';
import { CompanyCategorySelect } from './CompanyCategorySelect';
import { CompanyIndexSelect } from './CompanyIndexSelect';

interface Props {
  filter: ScreenFilter;
  handleFilterUpdate: (filter: ScreenFilter) => void;
}

export const Filter: React.FC<Props> = ({ filter, handleFilterUpdate }) => {
  const { data: filterMetrics } = useScreenFilterMetrics();

  const operators = [
    { value: 'gt', label: 'Greater than' },
    { value: 'lt', label: 'Less than' },
    { value: 'eq', label: 'Equals' },
    { value: 'nt', label: 'Not Equals' },
    { value: 'bt', label: 'Between' },
    { value: 'ct', label: 'Contains' },
  ];

  const handleMetricChange = (
    option: {
      value: string | undefined;
      label: string | undefined;
    } | null
  ) => {
    const filterMetricModel = filterMetrics?.find(
      (fm) => fm.value === option?.value
    )?.model;
    handleFilterUpdate({
      ...filter,
      operator:
        filterMetricModel === 'company_category' ||
        filterMetricModel === 'company_index'
          ? 'eq'
          : filter.operator,
      filterMetricValue: option?.value,
      filterMetricType: filterMetrics?.find((fm) => fm.value === option?.value)
        ?.type,
      filterMetricModel: filterMetricModel,
    });
  };

  const handleOperatorChange = (
    option: {
      value: string | undefined;
      label: string | undefined;
    } | null
  ) => {
    handleFilterUpdate({
      ...filter,
      operator: option?.value,
    });
  };

  const handleInputFromChange = (value: string) => {
    handleFilterUpdate({
      ...filter,
      inputFrom: value,
    });
  };

  const handleInputToChange = (value: string) => {
    handleFilterUpdate({
      ...filter,
      inputTo: value,
    });
  };

  // console.log(filter);

  const inputType = filterMetrics?.find(
    (fm) => fm.value === filter.filterMetricValue
  )?.type;

  return (
    <Flex mt={4}>
      <Box>
        <TermosSelect
          title='Metric'
          options={filterMetrics?.map((fm) => ({
            value: fm.value,
            label: fm.name,
          }))}
          value={filter.filterMetricValue}
          onChange={handleMetricChange}
        />
      </Box>
      {filter.filterMetricValue !== '' && (
        <Box ml={6}>
          <TermosSelect
            title='Operator'
            options={operators}
            value={filter.operator}
            onChange={handleOperatorChange}
          />
        </Box>
      )}
      {filter.operator !== '' && (
        <Box ml={6} mt={5}>
          {filter.filterMetricModel === 'company_category' ? (
            <CompanyCategorySelect
              category={filter.filterMetricValue}
              onChange={handleInputFromChange}
            />
          ) : filter.filterMetricModel === 'company_index' ? (
            <CompanyIndexSelect onChange={handleInputFromChange} />
          ) : (
            <Input
              color='black'
              bgColor='termos.orange'
              textTransform='uppercase'
              border={0}
              _focus={{ outline: 'none' }}
              _hover={{ outline: 'none' }}
              maxW='186px'
              minW='185px'
              onChange={(e) => handleInputFromChange(e.target.value)}
              value={filter.inputFrom}
              type={inputType === 'number' ? 'text' : inputType}
            />
          )}
        </Box>
      )}
      {filter.operator === 'bt' && (
        <>
          <Box ml={6} mt={8}>
            <Text>and</Text>
          </Box>
          <Box ml={6} mt={5}>
            <Input
              color='black'
              bgColor='termos.orange'
              textTransform='uppercase'
              border={0}
              _focus={{ outline: 'none' }}
              _hover={{ outline: 'none' }}
              maxW='186px'
              minW='185px'
              onChange={(e) => handleInputToChange(e.target.value)}
              value={filter.inputTo}
              type={inputType === 'number' ? 'text' : inputType}
            />
          </Box>
        </>
      )}
    </Flex>
  );
};
