import {
  Box,
  Input,
  Portal,
  Link,
  Text,
  useDisclosure,
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  DrawerHeader,
  DrawerFooter,
  Button,
  DrawerBody,
  IconButton,
  Flex,
  Divider,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { BrowserRouter as Router, generatePath, Route } from 'react-router-dom';
import { EQ, routes } from './routes';
import { Link as RouterLink } from 'react-router-dom';
import { useGetUsMarketStatus } from './hooks/marketstatus';
import { useEffectOnce, useSearchCompany } from './hooks';
import { useGetNotifications } from './hooks/notification';
import { BellIcon } from '@chakra-ui/icons';
import { css } from '@emotion/react';
import { NotificationsClient } from './ApiClients';
import { formatDate } from './utils/date';
import { useAuthState } from './firebase/AuthContext';
import { Signin } from './Signin';

const notificationsClient = new NotificationsClient();

export const App: React.FC = () => {
  const auth = useAuthState();

  if (auth.isLoading) return <Box bgColor='black' h='100vh' w='100vw'></Box>;

  if (!auth.isLoading && !auth.user) return <Signin />;

  return (
    <Box bgColor='black' h='100%' w='100%' minH='100vh'>
      <Router>
        <TopMenu />
        <Box>
          <MainSearch />
          <Box p={6} pb={0}>
            {routes.map((r, i) => (
              <Route key={i} exact path={r.path} component={r.component} />
            ))}
          </Box>
        </Box>
      </Router>
    </Box>
  );
};

const TopMenu: React.FC = () => {
  const { data: usMarketStatus, refetch } = useGetUsMarketStatus();
  useEffectOnce(() => {
    const interval = setInterval(() => {
      refetch();
    }, 300000);
    return () => clearInterval(interval);
  });
  return (
    <Flex
      bgColor='termos.darkbeige'
      w='100%'
      h={9}
      justifyContent='space-between'
    >
      <Text color='termos.orange' pt='6px' pl={3}>
        {usMarketStatus}
      </Text>
      <Notifications />
    </Flex>
  );
};

const Notifications: React.FC = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: notifications, refetch } = useGetNotifications();
  const unseen = notifications
    ? notifications.filter((n) => !n.seen)
    : undefined;

  const handleNotificationClick = (id: number) => {
    notificationsClient.markAsSeen(id).then(() => {
      onClose();
      refetch();
    });
  };

  return (
    <>
      <IconButton
        css={css`
          position: relative !important;
        `}
        py='2'
        colorScheme='ghost'
        aria-label='Notifications'
        size='lg'
        h={9}
        bgColor='termos.darkbeige'
        mr={8}
        onClick={onOpen}
        icon={
          <>
            <BellIcon color='white' />
            {unseen && unseen.length > 0 && (
              <Box
                as='span'
                color='black'
                position='absolute'
                top='6px'
                right='10px'
                fontSize='10px'
                bgColor='termos.orange'
                borderRadius='50%'
                h='15px'
                w='15px'
                zIndex={1}
              >
                {unseen.length}
              </Box>
            )}
          </>
        }
      />
      <Drawer isOpen={isOpen} placement='right' onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent
          bgColor='black'
          border='1px'
          borderColor='termos.gray'
          color='white'
        >
          <DrawerCloseButton />
          <DrawerHeader>Notifications</DrawerHeader>
          <Divider />
          <DrawerBody p={0}>
            {notifications?.map((n) => (
              <Link
                key={n.id}
                as={RouterLink}
                to={n.actionUrl ? n.actionUrl : '/'}
                _hover={{
                  textDecoration: '',
                }}
                onClick={() => handleNotificationClick(n.id)}
              >
                <Box
                  p={2}
                  _hover={{
                    backgroundColor: 'termos.beige',
                  }}
                >
                  <Text
                    color={n.seen ? 'white' : 'termos.orange'}
                    fontSize='16px'
                  >
                    {n.text}
                  </Text>
                  <Text color={n.seen ? 'white' : 'termos.orange'}>
                    {n.subText}
                  </Text>
                  <Text color='white'>
                    {formatDate(n.createdUtc, 'dd.MM.yyyy')}
                  </Text>
                </Box>
                <Divider />
              </Link>
            ))}
          </DrawerBody>

          <DrawerFooter>
            <Button variant='outline' mr={3} onClick={onClose}>
              Close
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

const MainSearch: React.FC = () => {
  const ref = React.useRef<HTMLDivElement>(null);
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [inputValue, setInputValue] = useState('');
  const { data: companies } = useSearchCompany(inputValue);
  const [inputHasFocus, setInputHasFocus] = useState(false);

  function handler({ key }: any) {
    // console.log(`${key} key pressed!`);
    if (key === 'F1') {
      inputRef.current?.focus();
      setInputHasFocus(true);
    } else if (key === 'Escape') {
      setInputValue('');
      inputRef.current?.blur();
      setInputHasFocus(false);
    } else if (key === 'Enter') {
      setTimeout(function () {
        setInputValue('');
        inputRef.current?.blur();
        setInputHasFocus(false);
      }, 0);
    }
  }

  useEventListener('keydown', handler);

  const routesFilter = routes.filter(
    (r) => r.key.toLowerCase().includes(inputValue.toLowerCase()) && r.canSearch
  );

  const functions = [
    {key: 'HOME', description: 'Homepage'},
    {key: 'DASH', description: 'Indices & News'},
    {key: 'SCRN', description: 'Equity Screener'},
    {key: 'WATCH', description: 'Watchlist'},
    {key: 'PORT', description: 'Portfolio'},
  ];

  return (
    <>
      <Box w='100%' h={9} pl={2} mt={4} mb={4} ref={ref}>
        <Input
          borderWidth={1}
          borderColor='termos.teal'
          h={9}
          maxW='1300px'
          color='white'
          textTransform='uppercase'
          _focus={{ outline: 'none' }}
          _hover={{ outline: 'none' }}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          ref={inputRef}
          placeholder='start search'
          onFocus={(e) => setInputHasFocus(true)} 
          onBlur={(e) => setInputHasFocus(false)}
        />
      </Box>
      <Portal containerRef={ref}>
        {inputValue.length === 0 && inputHasFocus && (
          <Box
          bgColor='termos.darkbeige'
          borderWidth={2}
          borderColor='termos.gray'
          maxW='1305px'
          minH={48}
          color='white'
          position='absolute'
          minW='1300px'
          zIndex={2}
          >
            <Box borderBottom='1px' borderColor='black'>
              <Text fontSize='24px' pl={2}>
                Page Functions
              </Text>
              {functions.map((f, i) => (
                <Text
                  key={i}
                  display='block'
                  fontSize='16px'
                  pl={2}
                  mb={2}
                  color='white'
                >
                  {`${f.key} - ${f.description}`}
                </Text>
              ))}
            </Box>
          </Box>
        )}
        {inputValue.length > 0 && (
          <Box
            bgColor='termos.darkbeige'
            borderWidth={2}
            borderColor='termos.gray'
            maxW='1305px'
            minH={48}
            color='white'
            position='absolute'
            minW='1300px'
            zIndex={2}
          >
            {routesFilter.length > 0 && (
              <Box borderBottom='1px' borderColor='black'>
                <Text fontSize='24px' pl={2}>
                  Pages
                </Text>
                {routesFilter.map((r, i) => (
                  <Link
                    key={i}
                    as={RouterLink}
                    to={r.path}
                    onClick={() => setInputValue('')}
                    _focus={{ border: 0, backgroundColor: 'termos.gray' }}
                    _hover={{ backgroundColor: 'termos.gray' }}
                    display='block'
                    fontSize='16px'
                    pl={2}
                    mb={2}
                  >
                    {r.key}
                  </Link>
                ))}
              </Box>
            )}
            {companies && companies.length > 0 && (
              <Box>
                <Text fontSize='24px' pl={2}>
                  Securities
                </Text>
                {companies?.map((c) => (
                  <Link
                    key={c.id}
                    as={RouterLink}
                    to={generatePath(
                      routes.find((r) => r.key === EQ)?.path ?? '',
                      {
                        ticker: c?.ticker,
                      }
                    )}
                    onClick={() => setInputValue('')}
                    _focus={{ border: 0, backgroundColor: 'termos.gray' }}
                    _hover={{ backgroundColor: 'termos.gray' }}
                    display='block'
                    fontSize='16px'
                    pl={2}
                    mb={2}
                  >
                    {`${c.ticker} - ${c.name} (EQ)`}
                  </Link>
                ))}
              </Box>
            )}
          </Box>
        )}
      </Portal>
    </>
  );
};

function useEventListener(eventName: any, handler: any, element = window) {
  // Create a ref that stores handler
  const savedHandler = React.useRef<any>();

  // Update ref.current value if handler changes.
  // This allows our effect below to always get latest handler ...
  // ... without us needing to pass it in effect deps array ...
  // ... and potentially cause effect to re-run every render.
  React.useEffect(() => {
    savedHandler.current = handler;
  }, [handler]);

  React.useEffect(
    () => {
      // Make sure element supports addEventListener
      // On
      const isSupported = element && element.addEventListener;
      if (!isSupported) return;

      // Create event listener that calls handler function stored in ref
      const eventListener = (event: any) => savedHandler.current(event);

      // Add event listener
      element.addEventListener(eventName, eventListener);

      // Remove event listener on cleanup
      return () => {
        element.removeEventListener(eventName, eventListener);
      };
    },
    [eventName, element] // Re-run if eventName or element changes
  );
}
