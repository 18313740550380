import { useQuery } from 'react-query';
import { Currency, Forex, ForexClient } from '../ApiClients';

const forexClient = new ForexClient();

const noRefetch = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const useGetForex = () =>
  useQuery<Forex[]>('forex', () => forexClient.get(), noRefetch);

export const useGetCC = () =>
  useQuery<Currency[]>('currency', () => forexClient.getCC(), noRefetch);
