import { ExternalLinkIcon } from '@chakra-ui/icons';
import { Box, Link } from '@chakra-ui/layout';
import React from 'react';
import { Company } from '../../ApiClients';

interface Props {
  company: Company;
}

const otherLinks = [
  {
    name: 'Yahoo Finance',
    link: 'https://finance.yahoo.com/quote/termos',
  },
  { name: 'Seeking Alpha', link: 'https://seekingalpha.com/symbol/termos' },
  { name: 'Twitter', link: 'http://twitter.com/search?q=$termos' },
  { name: 'Open Insider', link: 'http://openinsider.com/search?q=termos' },
  { name: 'Whale Wisdom', link: 'https://whalewisdom.com/stock/termos' },
  {
    name: 'Trading View Chart',
    link: 'https://www.tradingview.com/chart/?symbol=termos',
  },
  {
    name: 'Moodys',
    link: 'https://www.google.com/search?q=moodys+rating+termos',
  },
  {
    name: 'Finra Bonds',
    link: 'https://finra-markets.morningstar.com/BondCenter/Default.jsp',
  },
  {
    name: 'Nordnet',
    link: 'https://www.google.com/search?q=nordnet+termos',
  },
];

export const Links: React.FC<Props> = ({ company }) => {
  const handleOpenAll = () => {
    window.open(company.companySite, '_blank');
    window.open(company.secFilings, '_blank');
    for (const ol of otherLinks) {
      if (company.ticker)
        window.open(ol.link.replace('termos', company.ticker), '_blank');
    }
  };

  return (
    <Box border='1px' p={2} borderColor='termos.gray' minW='190px' maxW='200px'>
      <Link href={company.companySite} isExternal display='block' mb={1}>
        Company site <ExternalLinkIcon mx='2px' />
      </Link>
      <Link href={company.secFilings} isExternal display='block' mb={1}>
        SEC Filings <ExternalLinkIcon mx='2px' />
      </Link>
      {otherLinks.map((ol, i) => (
        <Link
          href={company.ticker ? ol.link.replace('termos', company.ticker) : ''}
          isExternal
          display='block'
          mb={1}
          key={i}
        >
          {ol.name} <ExternalLinkIcon mx='2px' />
        </Link>
      ))}
      <Link onClick={handleOpenAll} color='white'>
        Open all <ExternalLinkIcon mx='2px' />
      </Link>
    </Box>
  );
};
// '_blank'
