import React, { useEffect } from 'react';
import { Box, Center, Flex, Heading, Image, Text } from '@chakra-ui/react';

export const Home: React.FC = () => {
  useEffect(() => {
    document.title = `Termos - Home`;
  }, []);
  const date = new Date();
  const dayName = date.toLocaleString('default', { weekday: 'long' });
  const day = date.toLocaleString('default', { day: 'numeric' });
  const month = date.toLocaleString('default', { month: 'short' });
  return (
    <Box>
      <Flex justifyContent='space-between'>
        <Heading color='white' fontSize={48}>
          Termos
        </Heading>
        <Text
          color='white'
          fontSize='24px'
          mt={5}
        >{`${dayName}, ${month} ${day}, ${date.getFullYear()}`}</Text>
      </Flex>
      <Center>
        <Box w='600px'>
          <Image src='/mr_market.png' alt='Mr. Market' w='500px' h='400px' />
          <Text color='white' fontSize='24px' float='right'>
            Mr. Market says hi.
          </Text>
        </Box>
      </Center>
    </Box>
  );
};
