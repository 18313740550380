import { Button } from '@chakra-ui/button';
import { useDisclosure } from '@chakra-ui/hooks';
import { QuestionOutlineIcon } from '@chakra-ui/icons';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Box,
  Text,
  Flex,
  Center,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useScreenFilterMetrics } from '../../hooks';

export const HelpModal: React.FC = () => {
  const { data: filterMetrics } = useScreenFilterMetrics();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [activeDescTitle, setActiveDescTitle] = useState<string | undefined>(
    ''
  );
  const [activeDesc, setActiveDesc] = useState<string | undefined>('');

  return (
    <>
      <Button
        onClick={onOpen}
        leftIcon={<QuestionOutlineIcon />}
        colorScheme='ghost'
        variant='solid'
      >
        Help
      </Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderWidth={2} borderColor='termos.gray' maxW='80vw'>
          <Box bgColor='termos.gray' h={9}>
            <Text color='white'>Filter descriptions</Text>
          </Box>
          <ModalBody bgColor='black' color='termos.orange'>
            <Center>
              <Flex w='100%'>
                <Box
                  borderWidth={2}
                  borderColor='termos.gray'
                  overflow='hidden'
                  flex='1'
                >
                  <Box p={2} bgColor='termos.gray' color='white'>
                    Metrics
                  </Box>
                  <Box maxH='500px' overflowY='scroll'>
                    {filterMetrics?.map((fm, i) => (
                      <Box key={i}>
                        <Button
                          colorScheme='ghost'
                          color='termos.orange'
                          size='xs'
                          onClick={() => {
                            setActiveDesc(fm.description);
                            setActiveDescTitle(fm.name);
                          }}
                        >
                          {fm.name}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </Box>
                <Box borderWidth={2} borderColor='termos.gray' flex='1'>
                  <Box p={2} bgColor='termos.gray' color='white'>
                    {activeDescTitle}
                  </Box>
                  <Box p={2}>{activeDesc}</Box>
                </Box>
              </Flex>
            </Center>
          </ModalBody>
          <ModalFooter bgColor='black'>
            <Button bgColor='termos.gray' color='white' onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
