import { Input } from '@chakra-ui/input';
import { Box, Flex } from '@chakra-ui/layout';
import { TagCloseButton, TagLabel } from '@chakra-ui/react';
import { Tag } from '@chakra-ui/tag';
import React, { FormEvent, useState } from 'react';
import { CompanyTag, CompanyTagsClient } from '../../ApiClients';
import { TermosSelect } from '../../components/TermosSelect';
import { useGetCompanyTags } from '../../hooks/companytags';

const companyTagsClient = new CompanyTagsClient();

interface Props {
  companyId: number;
  tags: CompanyTag[] | undefined;
  refetchCompany: () => void;
}

export const CompanyTags: React.FC<Props> = ({
  companyId,
  tags,
  refetchCompany,
}) => {
  const [newTag, setNewTag] = useState('');
  const { data: companyTags, refetch } = useGetCompanyTags();

  const handleSubmitTagInput = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSubmitTag(newTag);
  };

  const handleSubmitTagSelect = (value: string | undefined) => {
    if (value) handleSubmitTag(value);
  };

  const handleSubmitTag = (value: string) => {
    if (value !== '') {
      try {
        companyTagsClient.create(companyId, value).then((res) => {
          setNewTag('');
          refetchCompany();
          refetch();
        });
      } catch {
        console.log('Noe gikk galt');
      }
    }
  };

  const handleRemoveTag = (value: string | undefined) => {
    if (value) {
      try {
        companyTagsClient.remove(companyId, value).then((res) => {
          setNewTag('');
          refetchCompany();
          refetch();
        });
      } catch {
        console.log('Noe gikk galt');
      }
    }
  };

  return (
    <Box
      pt={4}
      border='1px'
      p={2}
      borderColor='termos.gray'
      mt={4}
      h='600px'
      overflowY='auto'
    >
      <Flex>
        <TermosSelect
          title=''
          options={companyTags?.map((t) => ({
            value: t,
            label: t,
          }))}
          onChange={(e) => handleSubmitTagSelect(e?.value)}
        />
        <Box w={4} />
        <form onSubmit={handleSubmitTagInput}>
          <Input
            color='black'
            bgColor='termos.orange'
            textTransform='uppercase'
            border={0}
            _focus={{ outline: 'none' }}
            _hover={{ outline: 'none' }}
            maxW='186px'
            minW='185px'
            onChange={(e) => setNewTag(e.target.value)}
            value={newTag}
            type={'text'}
          />
        </form>
      </Flex>
      <Box h={4} />
      <Box>
        {!tags || tags.length <= 0 ? (
          <>ingen tags</>
        ) : (
          tags.map((t) => (
            <Tag key={t.id} bgColor='termos.orange' mr={4}>
              <TagLabel>{t.value}</TagLabel>
              <TagCloseButton onClick={() => handleRemoveTag(t.value)} />
            </Tag>
          ))
        )}
      </Box>
    </Box>
  );
};
