import { DeleteIcon } from '@chakra-ui/icons';
import { Grid, Box, Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { VscDebugStepInto } from 'react-icons/vsc';
import {
  generatePath,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import { WatchlistsClient } from '../../ApiClients';
import { DeleteModal } from '../../components/DeleteModal';
import { TermosSelect } from '../../components/TermosSelect';
import { useGetWatchlists } from '../../hooks/watchlists';
import { routes, WATCH, WATCHID, WATCHIDS, WATCHNAV } from '../../routes';
import { WatchlistList } from './Watchlist';
import { WatchlistCompany } from './WatchlistCompany';

const watchlistsClient = new WatchlistsClient();

interface RouteParams {
  id: string;
  watchlistCompanyId: string;
}

type Props = RouteComponentProps<RouteParams>;

export const Watchlists: React.FC<Props> = (props) => {
  const { id, watchlistCompanyId } = props.match.params;
  const { data: watchlists, refetch } = useGetWatchlists();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentWatchlistId, setCurrentWatchlistId] = useState(
    id ? parseInt(id) : 0
  );
  const [currentWatchlistCompanyId, setCurrentWatchlistCompanyId] = useState(
    watchlistCompanyId ? parseInt(watchlistCompanyId) : 0
  );
  const history = useHistory();

  useEffect(() => {
    document.title = `Termos - Watchlists`;
  }, []);

  useEffect(() => {
    if (!id && watchlists) {
      const watchlist = watchlists[0];
      setCurrentWatchlistId(watchlist.id);
    }
  }, [id, watchlists]);

  useEffect(() => {
    if (currentWatchlistId !== 0) {
      if (watchlistCompanyId) {
        history.push(
          generatePath(routes.find((r) => r.key === WATCHIDS)?.path ?? '', {
            id: currentWatchlistId,
            watchlistCompanyId: watchlistCompanyId,
          })
        );
      } else {
        history.push(
          generatePath(routes.find((r) => r.key === WATCHID)?.path ?? '', {
            id: currentWatchlistId,
          })
        );
      }

      if (currentWatchlistCompanyId === 0) {
        const watchlistCompanies = watchlists?.find(
          (w) => w.id === currentWatchlistId
        )?.companies;
        if (watchlistCompanies && watchlistCompanies.length > 0)
          setCurrentWatchlistCompanyId(watchlistCompanies[0].id);
      }
    }
  }, [
    currentWatchlistId,
    history,
    currentWatchlistCompanyId,
    watchlists,
    watchlistCompanyId,
  ]);

  const handleClickCompany = (watchlistCompanyId: number) => {
    setCurrentWatchlistCompanyId(watchlistCompanyId);
    history.push(
      generatePath(routes.find((r) => r.key === WATCHIDS)?.path ?? '', {
        id: currentWatchlistId,
        watchlistCompanyId: watchlistCompanyId,
      })
    );
  };

  const handleDeleteWatchlist = () => {
    watchlistsClient.delete(currentWatchlistId).then(() => {
      setCurrentWatchlistId(0);
      setCurrentWatchlistCompanyId(0);
      onClose();
      refetch();
      history.push(routes.find((r) => r.key === WATCH)?.path ?? '');
    });
  };

  return (
    <Box>
      <Flex
        h='55px'
        bgColor='termos.darkbeige'
        mb={2}
        border='1px'
        borderColor='termos.gray'
        p={2}
      >
        <Box color='white' fontSize='16px' mr={6}>
          Watchlists
        </Box>
        <TermosSelect
          title=''
          options={watchlists?.map((w) => ({
            value: w.id.toString(),
            label: w.name,
          }))}
          value={currentWatchlistId.toString()}
          onChange={(e) => {
            setCurrentWatchlistId(e?.value ? parseInt(e.value) : 0);
            setCurrentWatchlistCompanyId(0);
            history.push(
              generatePath(routes.find((r) => r.key === WATCHID)?.path ?? '', {
                id: e?.value ? parseInt(e.value) : 0,
              })
            );
          }}
        />
        <Box w={8} />
        <IconButton
          aria-label='Discard watchlist'
          icon={<DeleteIcon />}
          colorScheme='ghost'
          variant='solid'
          color='white'
          _hover={{ color: 'termos.orange' }}
          onClick={onOpen}
          disabled={currentWatchlistId === 0}
        />
        <Box w={4} />
        <IconButton
          aria-label='Step into watchlist'
          icon={<VscDebugStepInto size={21} />}
          colorScheme='ghost'
          variant='solid'
          color='white'
          _hover={{ color: 'termos.orange' }}
          onClick={() =>
            history.push(
              generatePath(routes.find((r) => r.key === WATCHNAV)?.path ?? '', {
                id: currentWatchlistId,
                watchlistCompanyId: currentWatchlistCompanyId,
              })
            )
          }
          disabled={currentWatchlistId === 0}
        />
      </Flex>
      <Grid templateColumns='1fr 6fr' gap={1} h='650px'>
        <Box
          w='570px'
          h='645px'
          overflowY='auto'
          border='1px'
          p={2}
          borderColor='termos.gray'
          color='termos.orange'
        >
          <WatchlistList
            currentWatchlistId={currentWatchlistId}
            handleClickCompany={handleClickCompany}
            currentWatchlistCompanyId={currentWatchlistCompanyId}
          />
        </Box>
        <Box
          w='100%'
          h='645px'
          overflowY='auto'
          border='1px'
          p={2}
          borderColor='termos.gray'
        >
          <WatchlistCompany
            currentWatchlistId={currentWatchlistId}
            watchlistCompanyId={currentWatchlistCompanyId}
          />
        </Box>
      </Grid>
      <DeleteModal
        id='deletewatchlist'
        isOpen={isOpen}
        onClose={onClose}
        heading='Delete watchlist'
        description={`Do you want to delete "${
          watchlists?.find((w) => w.id === currentWatchlistId)?.name
        }"?`}
        onDelete={handleDeleteWatchlist}
      />
    </Box>
  );
};
