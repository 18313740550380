import { Box, Link } from '@chakra-ui/layout';
import { Table, Thead, Tr, Th, Tbody } from '@chakra-ui/react';
import React from 'react';
import { TinyTd, TTooltip } from '../../components/TTable';
import { useGetOpenInsiderRows } from '../../hooks/openinsiders';
import { formatNumber } from '../../utils/number';

interface Props {
  ticker: string;
  doFetch: boolean;
}

export const OpenInsiders: React.FC<Props> = ({ ticker, doFetch }) => {
  const { data: openInsiderRows } = useGetOpenInsiderRows(ticker, doFetch);

  if (!openInsiderRows) return <>kunne ikke hente open insider rows</>;

  return (
    <Box mt={4}>
      <Table
        variant='simple'
        color='termos.orange'
        border='1px'
        borderColor='termos.gray'
      >
        <Thead bgColor='termos.beige'>
          <Tr>
            <Th color='white' border={0} pl={2.5}>
              X
            </Th>
            <Th color='white' border={0} pl={2.5}>
              F Date
            </Th>
            <Th color='white' border={0} pl={2.5}>
              T Date
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Insider Name
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Title
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Type
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Price
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Qty
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Value
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {openInsiderRows.map((oir, i) => (
            <Tr
              key={i}
              bgColor={i % 2 ? 'termos.darkorange' : 'black'}
              color={
                oir.tradeType && oir.tradeType.includes('Purchase')
                  ? 'termos.green'
                  : 'termos.orange'
              }
            >
              <TinyTd>{oir.x}</TinyTd>
              <TinyTd>
                <TTooltip label={oir.filingDate}>
                  {oir.filingDate?.split(' ')[0]}
                </TTooltip>
              </TinyTd>
              <TinyTd>{oir.tradeDate}</TinyTd>
              <TinyTd>
                <Link
                  href={`https://duckduckgo.com/?q=${oir.insiderName?.replace(
                    ' ',
                    '+'
                  )}`}
                  isExternal
                >
                  {oir.insiderName}
                </Link>
              </TinyTd>
              <TinyTd>
                <TTooltip label={oir.title}>
                  {oir.title?.substring(0, 9)}
                </TTooltip>
              </TinyTd>
              <TinyTd>
                <TTooltip label={oir.tradeType}>
                  {oir.tradeType?.substring(0, 1)}
                </TTooltip>
              </TinyTd>
              <TinyTd>{oir.price}</TinyTd>
              <TinyTd>
                {oir.qty
                  ? formatNumber(
                      parseInt(oir.qty.replaceAll('$', '').replaceAll(',', ''))
                    )
                  : '-'}
              </TinyTd>
              <TinyTd>
                {oir.value
                  ? formatNumber(
                      parseInt(
                        oir.value.replaceAll('$', '').replaceAll(',', '')
                      )
                    )
                  : '-'}
              </TinyTd>
            </Tr>
          ))}
        </Tbody>
      </Table>
    </Box>
  );
};
