import { Box, Text } from '@chakra-ui/layout';
import { Link } from '@chakra-ui/react';
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/table';
import React from 'react';
import { Company } from '../../ApiClients';
import { useGetAssetProfile } from '../../hooks/yahoofinance';

interface Props {
  company: Company;
}

export const Management: React.FC<Props> = ({ company }) => {
  const { data: quoteSummary } = useGetAssetProfile(
    company.ticker ? company.ticker : ''
  );

  if (!quoteSummary || !quoteSummary.result || quoteSummary.result.length === 0)
    return <>Could not get management</>;

  const assetProfile = quoteSummary.result[0].assetProfile;

  return (
    <Box mt={4}>
      <Text>
        Exercised is the value of options exercised during the fiscal year.{' '}
      </Text>
      <Table
        variant='simple'
        color='termos.orange'
        border='1px'
        borderColor='termos.gray'
      >
        <Thead bgColor='termos.beige'>
          <Tr>
            <Th color='white' border={0} pl={2.5}>
              Name
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Title
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Age
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Year
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Pay
            </Th>
            <Th color='white' border={0} pl={2.5}>
              Ex. Val.
            </Th>
            <Th color='white' border={0} pl={2.5}>
              un Ex. Val.
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {assetProfile &&
            assetProfile.companyOfficers?.map((co, i) => (
              <Tr key={i} bgColor={i % 2 ? 'termos.darkorange' : 'black'}>
                <Td pl={2.5} border={0}>
                  <Link
                    href={`https://duckduckgo.com/?q=${co.name?.replace(
                      ' ',
                      '+'
                    )}`}
                    isExternal
                  >
                    {co.name}
                  </Link>
                </Td>
                <Td pl={2.5} border={0}>
                  {co.title}
                </Td>
                <Td pl={2.5} border={0}>
                  {co.age}
                </Td>
                <Td pl={2.5} border={0}>
                  {co.fiscalYear}
                </Td>
                <Td pl={2.5} border={0}>
                  {co.totalPay?.fmt}
                </Td>
                <Td pl={2.5} border={0}>
                  {co.exercisedValue?.fmt}
                </Td>
                <Td pl={2.5} border={0}>
                  {co.unexercisedValue?.fmt}
                </Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
    </Box>
  );
};
