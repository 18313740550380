import { EditIcon } from '@chakra-ui/icons';
import { Box, Center, Flex, Text } from '@chakra-ui/layout';
import {
  Button,
  Checkbox,
  Icon,
  Input,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from '@chakra-ui/react';
import { Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/table';
import React, { useRef, useState } from 'react';
import { InfiniteData } from 'react-query';
import {
  Company,
  CompanyCategory,
  Fundamental,
  ScreenCompanyResponse,
  ScreenFilter,
} from '../../ApiClients';
import { useScreenFilterMetrics } from '../../hooks';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { toCamelCase } from '../../utils/extensions';
import { VscChevronDown, VscChevronRight } from 'react-icons/vsc';
import { formatDateSpecial } from '../../utils/date';
import { formatNumber } from '../../utils/number';
import useOnScreen from '../../hooks/useOnScreen';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';
import { CompanyDetails } from './CompanyDetails';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import { EQ, routes } from '../../routes';
import { AddToWatchlist } from '../watchlist/AddToWatchlist';
import { TinyTd, TTr } from '../../components/TTable';

interface Props {
  data: InfiniteData<ScreenCompanyResponse> | undefined;
  currentResultsOnPage: number;
  handleSorting: (model: string, value: string) => void;
  sortingValue: string;
  descriptionFilters: ScreenFilter[];
}

type TableColumn = {
  id: string;
  idx: number;
  heading: string;
  model: string;
  value: string;
};

export const ScreenTable: React.FC<Props> = ({
  data,
  currentResultsOnPage,
  handleSorting,
  sortingValue,
  descriptionFilters,
}) => {
  const initialTableColumns = [
    {
      id: uuidv4(),
      idx: 0,
      heading: 'Ticker Symbol',
      model: 'company',
      value: 'ticker',
    },
    {
      id: uuidv4(),
      idx: 1,
      heading: 'Issuer Name',
      model: 'company',
      value: 'name',
    },
  ];
  const [tableColumns, setTableColumns] = useLocalStorage<TableColumn[]>(
    'tableColumns',
    initialTableColumns
  );

  const handleCheckTableColumn = (tableColumn: TableColumn) => {
    if (tableColumns.some((tc) => tc.value === tableColumn.value)) {
      setTableColumns(
        updateIdx(tableColumns.filter((p) => p.value !== tableColumn.value))
      );
    } else {
      setTableColumns([...tableColumns, tableColumn]);
    }
  };

  const tHeadRef = useRef<HTMLTableSectionElement>(null);
  const tHeadsVisible = useOnScreen(tHeadRef);

  const [tHeadLeft, setTHeadLeft] = useState(0);
  const [draggableId, setDraggableId] = useState('');
  const [isDragging, setIsDragging] = useState(false);

  const onDragEnd = (result: DropResult) => {
    setDraggableId('');
    setIsDragging(false);

    if (!result.destination) {
      return;
    }

    const items: TableColumn[] = reorder(
      tableColumns,
      result.source.index,
      result.destination.index
    );

    const itemsWithNewIdx = updateIdx(items);

    setTableColumns(itemsWithNewIdx);
  };

  const reorder = (
    list: TableColumn[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateIdx = (list: TableColumn[]) => {
    const result = Array.from(list);
    let newIdx = 0;
    for (let res of result) {
      res.idx = newIdx;
      newIdx++;
    }
    return result;
  };

  return (
    <Box>
      <Box mb={2}>
        <AddColoumn
          tableColumns={tableColumns}
          handleCheckTableColumn={handleCheckTableColumn}
        />
      </Box>
      <Box
        overflowX='hidden'
        position='fixed'
        top='0'
        left={6 + -tHeadLeft}
        right={6}
        bgColor='termos.beige'
        display={tHeadsVisible ? 'none' : 'inherit'}
        zIndex={1}
      >
        <Table
          variant='simple'
          color='termos.orange'
          style={{ tableLayout: 'fixed' }}
        >
          <Thead bgColor='termos.beige'>
            <Tr>
              <Th color='white' pl={2} border={0} w='210px'></Th>
              {tableColumns.map((tc, i) => (
                <Th
                  key={i}
                  border={0}
                  borderRight={i < tableColumns.length - 1 ? '1px' : '0'}
                  borderRightColor='termos.gray'
                  paddingLeft='5px'
                  onClick={() => handleSorting(tc.model, tc.value)}
                  color={tc.value === sortingValue ? 'termos.orange' : 'white'}
                  _hover={{ cursor: 'pointer' }}
                >
                  {tc.heading}
                </Th>
              ))}
            </Tr>
          </Thead>
        </Table>
      </Box>
      <Box
        overflowX='auto'
        onScroll={(e) => setTHeadLeft(e.currentTarget.scrollLeft)}
      >
        <Table
          variant='simple'
          color='termos.orange'
          style={{ tableLayout: 'fixed' }}
        >
          <DragDropContext
            onDragEnd={onDragEnd}
            onBeforeCapture={(on) => {
              setDraggableId(on.draggableId);
              setIsDragging(true);
            }}
          >
            <Droppable droppableId='table-head' direction='horizontal'>
              {(provided) => (
                <Thead bgColor='termos.beige' ref={tHeadRef}>
                  <Tr {...provided.droppableProps} ref={provided.innerRef}>
                    <Th color='white' pl={2} border={0} w='50px'></Th>
                    <Th color='white' pl={2} border={0} w='160px'></Th>
                    {tableColumns
                      .sort((a, b) => a.idx - b.idx)
                      .map((tc, i) => (
                        <Draggable key={tc.id} draggableId={tc.id} index={i}>
                          {(provided, snapshot) => (
                            <Th
                              key={i}
                              border={isDragging ? '2px' : 0}
                              borderColor={isDragging ? 'red' : ''}
                              borderRight={
                                i < tableColumns.length - 1 ? '1px' : '0'
                              }
                              borderRightColor='termos.gray'
                              paddingLeft='5px'
                              onClick={() => handleSorting(tc.model, tc.value)}
                              color={
                                tc.value === sortingValue
                                  ? 'termos.orange'
                                  : 'white'
                              }
                              _hover={{ cursor: 'pointer' }}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              bgColor={
                                snapshot.isDragging || tc.id === draggableId
                                  ? 'red'
                                  : ''
                              }
                              m={0}
                            >
                              {tc.heading}
                            </Th>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </Tr>
                </Thead>
              )}
            </Droppable>
          </DragDropContext>
          {!isDragging && (
            <Tbody>
              {data &&
                data.pages.map((page, pageIndex) => (
                  <React.Fragment key={pageIndex}>
                    {page.companies?.map((company, i) => (
                      <TrFragment
                        key={i}
                        company={company}
                        i={i}
                        pageIndex={pageIndex}
                        currentResultsOnPage={currentResultsOnPage}
                        tableColumns={tableColumns}
                        descriptionFilters={descriptionFilters}
                      />
                    ))}
                  </React.Fragment>
                ))}
            </Tbody>
          )}
        </Table>
      </Box>
    </Box>
  );
};

interface TrFragmentProps {
  company: Company;
  i: number;
  pageIndex: number;
  currentResultsOnPage: number;
  tableColumns: TableColumn[];
  descriptionFilters: ScreenFilter[];
}

const TrFragment: React.FC<TrFragmentProps> = ({
  company,
  i,
  pageIndex,
  currentResultsOnPage,
  tableColumns,
  descriptionFilters,
}) => {
  const [openDetails, setOpenDetails] = useState(false);
  return (
    <React.Fragment key={company.id}>
      <TTr i={i + pageIndex * currentResultsOnPage}>
        <TinyTd>
          <AddToWatchlist companyId={company.id} />
        </TinyTd>
        <TinyTd onClick={() => setOpenDetails((prev) => !prev)}>
          <Flex>
            <Text>({i + 1 + pageIndex * currentResultsOnPage})</Text>
            <Center>
              {!openDetails ? (
                <Icon as={VscChevronRight} w={5} h={5} color='white' />
              ) : (
                <Icon as={VscChevronDown} w={5} h={5} color='white' />
              )}
            </Center>
          </Flex>
        </TinyTd>
        {tableColumns.map((tc) => (
          <TinyTd key={tc.id}>
            <Link
              as={RouterLink}
              to={generatePath(routes.find((r) => r.key === EQ)?.path ?? '', {
                ticker: company.ticker,
              })}
              isExternal
            >
              {getValue(tc.model, tc.value, company)}
            </Link>
          </TinyTd>
        ))}
      </TTr>
      <TTr i={0} hidden={!openDetails}>
        <Td
          colSpan={tableColumns.length + 2}
          p={6}
          borderTop='1px'
          borderTopColor='termos.gray'
          borderColor='termos.gray'
        >
          {openDetails && (
            <CompanyDetails
              company={company}
              highlightWords={descriptionFilters.map((df) =>
                df.inputFrom ? df.inputFrom : ''
              )}
            />
          )}
        </Td>
      </TTr>
    </React.Fragment>
  );
};

const getValue = (model: string, value: string, company: Company) => {
  if (model === 'company') {
    const camelValue = toCamelCase(value);
    const companyValue = company[camelValue as keyof Company];
    if (typeof companyValue === 'number') {
      return formatNumber(companyValue);
    }
    return formatDateSpecial(companyValue, 'dd.MM.yyyy');
  } else if (model === 'company_category') {
    const camelValue = toCamelCase(value);
    const companyCategory = company[
      camelValue as keyof Company
    ] as CompanyCategory;
    return companyCategory?.value;
  } else if (model === 'company_index') {
    return company.companyIndices?.map((ci) => `${ci.shortName} `);
  } else if (model === 'fundamental' && company.fundamentals) {
    const fundamental = company.fundamentals[0];
    if (fundamental) {
      const fundamentalValue = fundamental[value as keyof Fundamental];
      if (typeof fundamentalValue === 'number') {
        return formatNumber(fundamentalValue);
      }
      return formatDateSpecial(fundamentalValue, 'dd.MM.yyyy');
    } else {
      return '';
    }
  }
  return '';
};

interface AddColumnProps {
  tableColumns: TableColumn[];
  handleCheckTableColumn: (tableColumn: TableColumn) => void;
}

const AddColoumn: React.FC<AddColumnProps> = ({
  tableColumns,
  handleCheckTableColumn,
}) => {
  const { data: filterMetrics } = useScreenFilterMetrics();
  const [search, setSearch] = useState('');

  const filteredMetrics = filterMetrics?.filter(
    (fm) =>
      fm.value?.toLowerCase().includes(search.toLowerCase()) ||
      fm.name?.toLowerCase().includes(search.toLowerCase())
  );

  return (
    <Popover>
      <PopoverTrigger>
        <Button leftIcon={<EditIcon />} colorScheme='ghost' variant='solid'>
          Edit table headers
        </Button>
      </PopoverTrigger>
      <PopoverContent
        borderRadius={0}
        bgColor='black'
        borderColor='termos.beige'
      >
        <PopoverHeader borderColor='termos.beige'>
          <Input
            bgColor='termos.orange'
            color='black'
            textTransform='uppercase'
            _focus={{ outline: 'none' }}
            _hover={{ outline: 'none' }}
            value={search}
            onChange={(e) => setSearch(e.target.value)}
          />
        </PopoverHeader>
        <PopoverBody overflowY='scroll' h={64} color='termos.orange'>
          {filteredMetrics?.map((fm, i) => (
            <Checkbox
              key={i}
              isChecked={
                tableColumns.find((tc) => tc.value === fm.value) ? true : false
              }
              display='block'
              mb={3}
              onChange={() =>
                handleCheckTableColumn({
                  id: uuidv4(),
                  idx: tableColumns.length,
                  heading: fm.name ?? '',
                  model: fm.model ?? '',
                  value: fm.value ?? '',
                })
              }
            >
              {fm.name}
            </Checkbox>
          ))}
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
