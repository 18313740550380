import { useQuery } from 'react-query';
import { Watchlist, WatchlistsClient } from '../ApiClients';

const watchlistsClient = new WatchlistsClient();

export const useGetWatchlists = () =>
  useQuery<Watchlist[]>('watchlists', () => watchlistsClient.getAll());

export const useGetWatchlist = (id: number) =>
  useQuery<Watchlist>(`watchlist-${id}`, () => watchlistsClient.get(id));
