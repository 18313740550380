export const fundamentalColorRules = (
  fundamentalKey: string,
  value: number | undefined
) => {
  const grn = 'termos.green';
  const red = 'termos.red';
  switch (fundamentalKey) {
    case 'gnddiff':
      return value && value < 1 ? grn : red;
    case 'de':
      return value && value < 0.5 && value > 0 ? grn : red;
    case 'currentratio':
      return value && value > 1.5 ? grn : red;
    case 'roe':
      return value && value > 0.08 ? grn : red;
    case 'divyield':
      return value && value > 0.04 ? grn : red;
    case 'netmargin':
      return value && value > 0 ? grn : red;
    case 'revenue':
      return value && value > 0 ? grn : red;
    case 'pe':
      return value && value < 20 && value > 0 ? grn : red;
    case 'ps':
      return value && value < 2 ? grn : red;
    case 'pb':
      return value && value < 3 ? grn : red;
    default:
      return 'termos.orange';
  }
};

export const selectedKeys = [
  'price',
  'gnd',
  'gnddiff',
  'de',
  'currentratio',
  'roe',
  'divyield',
  'netmargin',
  'revenue',
  'eps',
  'epsdil',
  'pe',
  'ps',
  'pb',
  'ebitdamargin',
  'ebitda',
];

export const allFundamentalKeys = [
  'gnd',
  'gnddiff',
  'de',
  'currentratio',
  'roe',
  'divyield',
  'netmargin',
  'revenue',
  'eps',
  'epsdil',
  'pe',
  'ps',
  'pb',
  'ebitdamargin',
  'ebitda',
  'cor',
  'sgna',
  'rnd',
  'opex',
  'intexp',
  'taxexp',
  'netincdis',
  'consolinc',
  'netincnci',
  'netinc',
  'prefdivis',
  'netinccmn',
  'shareswa',
  'shareswadil',
  'capex',
  'ncfbus',
  'ncfinv',
  'ncff',
  'ncfdebt',
  'ncfcommon',
  'ncfdiv',
  'ncfi',
  'ncfo',
  'ncfx',
  'ncf',
  'sbcomp',
  'depamor',
  'assets',
  'cashneq',
  'investments',
  'investmentsc',
  'investmentsnc',
  'deferredrev',
  'deposits',
  'ppnenet',
  'inventory',
  'taxassets',
  'receivables',
  'payables',
  'intangibles',
  'liabilities',
  'equity',
  'retearn',
  'accoci',
  'assetsc',
  'assetsnc',
  'liabilitiesc',
  'liabilitiesnc',
  'taxliabilities',
  'debt',
  'debtc',
  'debtnc',
  'ebt',
  'ebit',
  'fxusd',
  'equityusd',
  'epsusd',
  'revenueusd',
  'netinccmnusd',
  'cashnequsd',
  'debtusd',
  'ebitusd',
  'ebitdausd',
  'sharesbas',
  'dps',
  'sharefactor',
  'marketcap',
  'ev',
  'invcap',
  'equityavg',
  'assetsavg',
  'invcapavg',
  'tangibles',
  'roa',
  'fcf',
  'roic',
  'gp',
  'opinc',
  'grossmargin',
  'ebitdamargin',
  'ros',
  'assetturnover',
  'payoutratio',
  'evebitda',
  'evebit',
  'pe1',
  'sps',
  'ps1',
  'workingcapital',
  'fcfps',
  'bvps',
  'tbvps',
  'price',
];
