import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  IconButton,
  useDisclosure,
  Text,
  Input,
  Center,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { VscNewFolder } from 'react-icons/vsc';
import { PortfoliosClient } from '../../ApiClients';
import { TermosSelect } from '../../components/TermosSelect';
import { useGetCompanyCategoryValues } from '../../hooks';

const portfoliosClient = new PortfoliosClient();

interface Props {
  refetchPortfolios: () => void;
}

export const CreatePortfolio: React.FC<Props> = ({ refetchPortfolios }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [name, setName] = useState('');
  const { data: companyCategoryValues } =
    useGetCompanyCategoryValues('currency');
  const [currencyId, setCurrencyId] = useState(0);

  const handleCreatePortfolio = () => {
    portfoliosClient.create(name, currencyId).then(() => {
      setName('');
      onClose();
      refetchPortfolios();
    });
  };

  return (
    <>
      <IconButton
        aria-label='Create portfolio'
        icon={<VscNewFolder size={21} />}
        colorScheme='ghost'
        variant='solid'
        color='white'
        _hover={{ color: 'termos.orange' }}
        onClick={onOpen}
      />

      <Modal isOpen={isOpen} onClose={onClose} id='createportfolio'>
        <ModalOverlay />
        <ModalContent borderWidth={2} borderColor='termos.gray'>
          <Box bgColor='termos.gray' h={9}>
            <Text
              color='white'
              fontSize='md'
              fontWeight='bold'
              ml='22px'
              mt='4px'
            >
              Create portfolio
            </Text>
          </Box>
          <ModalBody bgColor='black' color='termos.orange'>
            <Center>
              <Box>
                <Text color='white'>Portfolio name</Text>
                <Input
                  color='black'
                  bgColor='termos.orange'
                  border={0}
                  _focus={{ outline: 'none' }}
                  _hover={{ outline: 'none' }}
                  w='310px'
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                  type={'text'}
                />
                <Box h={4} />
                <TermosSelect
                  title='Portfolio currency'
                  options={companyCategoryValues?.map((cc) => ({
                    value: cc.id.toString(),
                    label: cc.value,
                  }))}
                  value={currencyId.toString()}
                  onChange={(opts) =>
                    setCurrencyId(opts?.value ? parseInt(opts.value) : 0)
                  }
                />
              </Box>
            </Center>
          </ModalBody>

          <ModalFooter bgColor='black'>
            <Button
              bgColor='termos.gray'
              color='white'
              onClick={onClose}
              mr={4}
            >
              Close
            </Button>
            <Button
              bgColor='termos.gray'
              color='white'
              onClick={handleCreatePortfolio}
            >
              Create
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
