import { Box, Grid, Heading } from '@chakra-ui/react';
import React from 'react';
import { termosColors } from '../..';
import { Company } from './Company';
import { Fundamental } from './Fundamental';
import { Index } from './Index';
import { Portfolio } from './Portfolio';

export const Admin: React.FC = () => {
  return (
    <Box bgColor={termosColors.lightgray} h='700px' w='100%' p={6}>
      <Grid templateColumns='repeat(3, 1fr)' gap={1}>
        <Box border='1px' borderColor='black' p={2}>
          <Heading>Companies</Heading>
          <Company />
        </Box>
        <Box border='1px' borderColor='black' p={2}>
          <Heading>Fundamentals</Heading>
          <Fundamental />
        </Box>
        <Box border='1px' borderColor='black' p={2}>
          <Heading>Portfolios</Heading>
          <Portfolio />
          <Heading>Index</Heading>
          <Index />
        </Box>
      </Grid>
    </Box>
  );
};
