import { IconButton } from '@chakra-ui/button';
import { ArrowLeftIcon, ArrowRightIcon } from '@chakra-ui/icons';
import { Box, Center, Flex } from '@chakra-ui/layout';
import React, { useEffect, useState } from 'react';
import { VscDebugStepOut } from 'react-icons/vsc';
import {
  generatePath,
  RouteComponentProps,
  useHistory,
} from 'react-router-dom';
import { useGetWatchlist } from '../../hooks/watchlists';
import { routes, WATCHID, WATCHIDS, WATCHNAV } from '../../routes';
import { Company } from '../company/Company';

interface RouteParams {
  id: string;
  watchlistCompanyId: string;
}

type Props = RouteComponentProps<RouteParams>;

export const WatchlistCompanyNav: React.FC<Props> = (props) => {
  const history = useHistory();
  const { id, watchlistCompanyId } = props.match.params;
  const { data: watchlist } = useGetWatchlist(parseInt(id));

  const [watchlistCompanyIdx, setWatchlistCompanyIdx] = useState(-1);

  const watchlistCompany =
    watchlist?.companies &&
    watchlist?.companies.length > 0 &&
    watchlist?.companies[watchlistCompanyIdx]
      ? watchlist?.companies[watchlistCompanyIdx]
      : undefined;

  useEffect(() => {
    if (watchlistCompanyIdx === -1) {
      const wc = watchlist?.companies?.find(
        (wc) => wc.id.toString() === watchlistCompanyId
      );
      const index = wc ? watchlist?.companies?.indexOf(wc) : undefined;
      if (index !== undefined) setWatchlistCompanyIdx(index);
    } else if (watchlistCompany) {
      history.push(
        generatePath(routes.find((r) => r.key === WATCHNAV)?.path ?? '', {
          id: id,
          watchlistCompanyId: watchlistCompany.id,
        })
      );
    }
  }, [
    history,
    id,
    watchlist?.companies,
    watchlistCompany,
    watchlistCompanyId,
    watchlistCompanyIdx,
  ]);

  const nrOfCompanies = watchlist?.companies?.length
    ? watchlist?.companies?.length
    : 0;

  return (
    <Box color='termos.orange'>
      <Flex>
        <IconButton
          aria-label='Toggle description'
          icon={<ArrowLeftIcon />}
          colorScheme='ghost'
          onClick={() =>
            watchlistCompanyIdx === 0
              ? setWatchlistCompanyIdx(nrOfCompanies - 1)
              : setWatchlistCompanyIdx((prev) => prev - 1)
          }
          color='white'
          _hover={{ color: 'termos.orange' }}
        />
        <Box>
          <Center>{watchlist?.name}</Center>
          <Center>{`${watchlistCompanyIdx + 1} / ${nrOfCompanies}`}</Center>
        </Box>
        <IconButton
          aria-label='Toggle description'
          icon={<ArrowRightIcon />}
          colorScheme='ghost'
          onClick={() =>
            watchlistCompanyIdx === nrOfCompanies - 1
              ? setWatchlistCompanyIdx(0)
              : setWatchlistCompanyIdx((prev) => prev + 1)
          }
          color='white'
          _hover={{ color: 'termos.orange' }}
        />
        <Box w={4} />
        <IconButton
          aria-label='Step out of watchlist'
          icon={<VscDebugStepOut size={21} />}
          colorScheme='ghost'
          variant='solid'
          color='white'
          _hover={{ color: 'termos.orange' }}
          onClick={() => {
            if (watchlist?.companies && watchlist?.companies.length > 0) {
              history.push(
                generatePath(
                  routes.find((r) => r.key === WATCHIDS)?.path ?? '',
                  {
                    id: id,
                    watchlistCompanyId:
                      watchlist?.companies[watchlistCompanyIdx].id,
                  }
                )
              );
            } else {
              history.push(
                generatePath(
                  routes.find((r) => r.key === WATCHID)?.path ?? '',
                  {
                    id: id,
                  }
                )
              );
            }
          }}
        />
      </Flex>
      {watchlistCompany?.company && watchlistCompany?.company.ticker && (
        <Company ticker={watchlistCompany.company.ticker} />
      )}
    </Box>
  );
};
