import { Box, Center, Grid, Text } from '@chakra-ui/react';
import React from 'react';
import { termosColors, TermosColors } from '../..';
import { Portfolio, PortfolioBreakdownType } from '../../ApiClients';
import { TPieChart } from '../../components/TPieChart';
import { useGetPortfolioBreakdown } from '../../hooks/portfolios';

interface Props {
  portfolio: Portfolio | undefined;
}

export const PortfolioBreakdown: React.FC<Props> = ({ portfolio }) => {
  return (
    <Grid templateColumns='repeat(3, 1fr)' gap={1} h='600px'>
      <Box w='100%' h='100%' border='1px' borderColor='termos.gray'>
        <PBTitle title='Sector' />
        <PBPercentChart
          portfolioId={portfolio?.id}
          type={PortfolioBreakdownType.Sector}
        />
      </Box>
      <Box w='100%' h='100%' border='1px' borderColor='termos.gray'>
        <PBTitle title='Industry' />
        <PBPercentChart
          portfolioId={portfolio?.id}
          type={PortfolioBreakdownType.Industry}
        />
      </Box>
      <Box w='100%' h='100%' border='1px' borderColor='termos.gray'></Box>
    </Grid>
  );
};

const PBPercentChart: React.FC<{
  portfolioId: number | undefined;
  type: PortfolioBreakdownType;
}> = ({ portfolioId, type }) => {
  const { data: breakdown } = useGetPortfolioBreakdown(portfolioId, type);

  if (!breakdown || !breakdown.labels || !breakdown.values) return <></>;

  const backgroundColors = Object.keys(termosColors).map(
    (key) => termosColors[key as keyof TermosColors]
  );

  const data = {
    labels: breakdown.labels,
    datasets: [
      {
        data: breakdown.values,
        backgroundColor: backgroundColors,
        borderColor: 'black',
        borderWidth: 0.6,
      },
    ],
  };
  return <TPieChart data={data} labelSymbol='%' />;
};

const PBTitle: React.FC<{ title: string }> = ({ title }) => {
  return (
    <Center
      bgColor='termos.beige'
      borderBottom='1px'
      borderColor='termos.gray'
      mb={4}
    >
      <Text fontSize='20px' color='white'>
        {title}
      </Text>
    </Center>
  );
};
