import { Box } from '@chakra-ui/layout';
import { Tabs, TabList, Tab, TabPanels, TabPanel } from '@chakra-ui/tabs';
import React from 'react';
import { Company } from '../../ApiClients';
import { useGetCcFundamentals } from '../../hooks/ccfundamental';
import { CcFundamentals } from './CcFundamentals';

interface Props {
  company: Company;
}

export const CcFundamental: React.FC<Props> = ({ company }) => {
  const { data: sectorFundamentals } = useGetCcFundamentals(
    company.sector ? company.sector.id : 0
  );

  const { data: industryFundamentals } = useGetCcFundamentals(
    company.industry ? company.industry.id : 0
  );

  return (
    <Box mt={4} mb={4}>
      <Tabs variant='unstyled'>
        <TabList color='white' mb={2}>
          <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
            Sector
          </Tab>
          <Tab _selected={{ color: 'termos.orange' }} p={0} mr={6}>
            Industry
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel p={0}>
            <CcFundamentals fundamentals={sectorFundamentals} />
          </TabPanel>
          <TabPanel p={0}>
            <CcFundamentals fundamentals={industryFundamentals} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
};
