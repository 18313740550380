import { Box, Link } from '@chakra-ui/layout';
import { Tooltip } from '@chakra-ui/react';
import React from 'react';
import { ColorTextedLabel } from '../../components/ColorTextedLabel';
import { useGetShillerPe, useGetUsInflationRate } from '../../hooks/multpl';

export const Multpl: React.FC = () => {
  const { data: usInflationRate } = useGetUsInflationRate();
  const { data: shillerPe } = useGetShillerPe();

  const colorInflation = (current: number | undefined) => {
    if (current === undefined || !usInflationRate) return 'termos.orange';
    const equalizer = usInflationRate.mean;

    if (
      current > usInflationRate.mean &&
      current < usInflationRate.mean + equalizer
    )
      return 'termos.orange';
    if (current >= usInflationRate.mean + equalizer) return 'termos.red';

    if (
      current < usInflationRate.mean &&
      current > usInflationRate.mean - equalizer
    )
      return 'termos.orange';
    if (current <= usInflationRate.mean - equalizer) return 'termos.green';
  };

  const colorShillerPE = (current: number | undefined) => {
    if (current === undefined || !shillerPe) return 'termos.orange';
    const equalizer = 2;

    if (current > shillerPe.mean && current < shillerPe.mean * equalizer)
      return 'termos.orange';

    if (current >= shillerPe.mean * equalizer) return 'termos.red';

    if (current <= shillerPe.mean) return 'termos.green';
  };

  return (
    <Box>
      <Box
        h='70px'
        overflowY='auto'
        border='1px'
        borderColor='termos.gray'
        p={2}
        fontSize='13px'
      >
        <Box mb={2}>
          <Link href='https://www.multpl.com/inflation' isExternal>
            <Tooltip
              color='termos.orange'
              bgColor='termos.beige'
              label={usInflationRate?.time}
              placement='top'
              shouldWrapChildren
            >
              <ColorTextedLabel
                label='US Inflation:'
                text={`${usInflationRate?.current}%`}
                color={colorInflation(usInflationRate?.current)}
              />
            </Tooltip>
          </Link>
        </Box>
        <Box>
          <Link href='https://www.multpl.com/shiller-pe' isExternal>
            <Tooltip
              color='termos.orange'
              bgColor='termos.beige'
              label={shillerPe?.time}
              placement='top'
              shouldWrapChildren
            >
              <ColorTextedLabel
                label='Shiller PE:'
                text={shillerPe?.current}
                color={colorShillerPE(shillerPe?.current)}
              />
            </Tooltip>
          </Link>
        </Box>
      </Box>
    </Box>
  );
};
