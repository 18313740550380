import { Box, Center } from '@chakra-ui/layout';
import { Tr, Tbody } from '@chakra-ui/table';
import React, { useState } from 'react';
import {
  useGetCommoditiesPerformance,
  useGetCommoditiesPrice,
} from '../../hooks/investingcom';
import { colorNumberChange } from '../../utils/number';
import {
  TinyTd,
  TStickyHead,
  TTable,
  TTh,
  TTooltip,
  TTr,
} from '../../components/TTable';
import { Link } from '@chakra-ui/react';

export const Commodities: React.FC = () => {
  const [showPrice, setShowPrice] = useState(true);
  return (
    <Box>
      <Center
        color='white'
        fontSize='16px'
        _hover={{ cursor: 'pointer', color: 'termos.orange' }}
        onClick={() => setShowPrice((prev) => !prev)}
      >
        Commodities
      </Center>
      <Box h='680px' overflowY='auto' border='1px' borderColor='termos.gray'>
        {showPrice ? <SmallPrice /> : <SmallPerformance />}
      </Box>
    </Box>
  );
};

const url = (name: string | undefined) => {
  const base = 'https://www.investing.com/commodities';
  if (name === undefined) return base;
  return `${base}/${name
    .replaceAll(' ', '-')
    .replaceAll('#', 'no.')
    .replaceAll('/', '-')
    .replaceAll('-WTI', '')
    .toLowerCase()}`;
};

const SmallPerformance: React.FC = () => {
  const { data: commoditiesPerformance } = useGetCommoditiesPerformance();
  return (
    <TTable>
      <TStickyHead>
        <Tr>
          <TTh>Name</TTh>
          <TTh>1m</TTh>
          <TTh>1y</TTh>
          <TTh>3y</TTh>
        </Tr>
      </TStickyHead>
      <Tbody>
        {commoditiesPerformance?.map((x, i) => (
          <TTr key={i} i={i}>
            <TinyTd>
              <Link href={url(x.commodity)} isExternal>
                {x.commodity}
              </Link>
            </TinyTd>
            <TinyTd color={colorNumberChange(x.performanceMonth)}>
              {x.performanceMonth}
            </TinyTd>
            <TinyTd color={colorNumberChange(x.performanceYear)}>
              {x.performanceYear}
            </TinyTd>
            <TinyTd color={colorNumberChange(x.performance3Year)}>
              {x.performance3Year}
            </TinyTd>
          </TTr>
        ))}
      </Tbody>
    </TTable>
  );
};

const SmallPrice: React.FC = () => {
  const { data: commoditiesPrice } = useGetCommoditiesPrice();
  const { data: commoditiesPerformance } = useGetCommoditiesPerformance();
  return (
    <TTable>
      <TStickyHead>
        <Tr>
          <TTh>Name</TTh>
          <TTh>Price</TTh>
          <TTh>Change</TTh>
          <TTh>1w</TTh>
        </Tr>
      </TStickyHead>
      <Tbody>
        {commoditiesPrice?.map((x, i) => (
          <TTr key={i} i={i}>
            <TinyTd>
              <TTooltip label={`Time: ${x.time}`}>
                <Link href={url(x.commodity)} isExternal>
                  {x.commodity}
                </Link>
              </TTooltip>
            </TinyTd>
            <TinyTd>{x.last}</TinyTd>
            <TinyTd color={colorNumberChange(x.changePercent)}>
              {x.changePercent}
            </TinyTd>
            {commoditiesPerformance && (
              <TinyTd
                color={colorNumberChange(
                  commoditiesPerformance.find(
                    (m) => m.commodity === x.commodity
                  )?.performanceWeek
                )}
              >
                {
                  commoditiesPerformance.find(
                    (m) => m.commodity === x.commodity
                  )?.performanceWeek
                }
              </TinyTd>
            )}
          </TTr>
        ))}
      </Tbody>
    </TTable>
  );
};
