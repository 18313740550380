import { useQuery } from 'react-query';
import {
  FinanceInsight,
  OptionWithQuote,
  Quote,
  QuoteSummary,
  YahooFinanceClient,
} from '../ApiClients';

const yahooFinanceClient = new YahooFinanceClient();

const noRefetch = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const useGetAssetProfile = (ticker: string) =>
  useQuery<QuoteSummary>(
    `AssetProfile-${ticker}`,
    () => yahooFinanceClient.getAssetProfile(ticker),
    noRefetch
  );

export const useGetMajorHoldersBreakdown = (ticker: string) =>
  useQuery<QuoteSummary>(
    `MajorHoldersBreakdown-${ticker}`,
    () => yahooFinanceClient.getMajorHoldersBreakdown(ticker),
    noRefetch
  );

export const useGetSecFilings = (ticker: string) =>
  useQuery<QuoteSummary>(
    `SecFilings-${ticker}`,
    () => yahooFinanceClient.getSecFilings(ticker),
    noRefetch
  );

export const useGetFinancialData = (ticker: string) =>
  useQuery<QuoteSummary>(
    `FinancialData-${ticker}`,
    () => yahooFinanceClient.getFinancialData(ticker),
    noRefetch
  );

export const useGetEsgScores = (ticker: string) =>
  useQuery<QuoteSummary>(
    `EsgScores-${ticker}`,
    () => yahooFinanceClient.getEsgScores(ticker),
    noRefetch
  );

export const useGetUpgradeDowngradeHistory = (ticker: string) =>
  useQuery<QuoteSummary>(
    `UpgradeDowngradeHistory-${ticker}`,
    () => yahooFinanceClient.getUpgradeDowngradeHistory(ticker),
    noRefetch
  );

export const useGetRecommendationTrend = (ticker: string) =>
  useQuery<QuoteSummary>(
    `RecommendationTrend-${ticker}`,
    () => yahooFinanceClient.getRecommendationTrend(ticker),
    noRefetch
  );

export const useGetDefaultKeyStatistics = (ticker: string) =>
  useQuery<QuoteSummary>(
    `DefaultKeyStatistics-${ticker}`,
    () => yahooFinanceClient.getDefaultKeyStatistics(ticker),
    noRefetch
  );

export const useGetFinanceInsight = (ticker: string) =>
  useQuery<FinanceInsight>(
    `FinanceInsights-${ticker}`,
    () => yahooFinanceClient.getFinanceInsights(ticker),
    noRefetch
  );

export const useGetOptionWithQuote = (
  ticker: string,
  doFetch: boolean,
  date?: string
) =>
  useQuery<OptionWithQuote>(
    `OptionWithQuote-${ticker}${date ? `-${date}` : ''}`,
    () => yahooFinanceClient.getOptionWithQuote(ticker, date),
    {
      enabled: doFetch,
      ...noRefetch,
    }
  );

export const useGetQuote = (ticker: string) =>
  useQuery<Quote>(
    `Quote-${ticker}`,
    () => yahooFinanceClient.getQuote(ticker),
    noRefetch
  );
