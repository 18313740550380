import { Box } from '@chakra-ui/react';
import React, { useState } from 'react';
import { ColorTextedLabel } from '../../components/ColorTextedLabel';
import { TermosSelect } from '../../components/TermosSelect';
import { useGetPortfolioBenchmark } from '../../hooks/portfolios';

interface Props {
  portfolioId: number;
}

const intrestingBechmarkIndexes = [
  { label: 'S&P 500', value: '^GSPC' },
  { label: 'Dow 30', value: '^DJI' },
  { label: 'Nasdaq', value: '^IXIC' },
  { label: 'NYSE Composite Index', value: '^NYA' },
  { label: 'Russell 2000', value: '^RUT' },
];

export const PortfolioBenchmark: React.FC<Props> = ({ portfolioId }) => {
  const [benchmarkIndexSymbol, setBenchmarkIndexSymbol] = useState('');
  const { data: benchmark } = useGetPortfolioBenchmark(
    portfolioId,
    benchmarkIndexSymbol
  );

  return (
    <Box>
      <TermosSelect
        title='Select benchmark index'
        options={intrestingBechmarkIndexes?.map((x) => ({
          value: x.value,
          label: x.label,
        }))}
        value={benchmarkIndexSymbol}
        onChange={(e) => setBenchmarkIndexSymbol(e?.value ? e.value : '')}
      />
      <Box mt={4} mb={4} fontSize='20px'>
        <ColorTextedLabel
          label='Percent of days beat:'
          text={
            benchmark !== undefined ? `${benchmark.percentOutperform}%` : ''
          }
          color={
            benchmark !== undefined && benchmark.percentOutperform >= 50
              ? 'termos.green'
              : 'termos.red'
          }
        />
        <ColorTextedLabel
          label='TotalBiPercent'
          text={benchmark !== undefined ? `${benchmark.totalBiPercent}` : ''}
          color={
            benchmark !== undefined && benchmark.totalBiPercent > 0
              ? 'termos.green'
              : 'termos.red'
          }
        />
        <ColorTextedLabel
          label='TotalPortfolioPercent:'
          text={
            benchmark !== undefined ? `${benchmark.totalPortfolioPercent}` : ''
          }
          color={
            benchmark !== undefined && benchmark.totalPortfolioPercent > 0
              ? 'termos.green'
              : 'termos.red'
          }
        />
        <ColorTextedLabel
          label='P/B Outperform:'
          text={
            benchmark !== undefined
              ? `${
                  benchmark.portfolioTotalBiOutperform === 1
                    ? 'OUTPERFORM'
                    : 'UNDERPERFORM'
                }`
              : ''
          }
          color={
            benchmark !== undefined &&
            benchmark.portfolioTotalBiOutperform === 1
              ? 'termos.green'
              : 'termos.red'
          }
        />
      </Box>
    </Box>
  );
};
