import { CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { Box, Button, Text } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import { termosColors } from '../..';
import {
  AdminCcFundamentalsClient,
  AdminFundamentalsClient,
} from '../../ApiClients';

const adminFundamentalsClient = new AdminFundamentalsClient();
const adminCcFundamentalsClient = new AdminCcFundamentalsClient();

export const Fundamental: React.FC = () => {
  useEffect(() => {
    document.title = `Termos - Admin`;
  }, []);

  const [shardar, setShardar] = useState({
    isSyncing: false,
    ok: false,
    err: false,
  });

  const [gndCalc, setGndCalc] = useState({
    isCalcing: false,
    ok: false,
    err: false,
  });

  const [calcCat, setCalcCat] = useState({
    isCalcing: false,
    ok: false,
    err: false,
  });

  const syncShardar = () => {
    setShardar({
      isSyncing: true,
      ok: false,
      err: false,
    });
    adminFundamentalsClient
      .syncShardar()
      .then(() => {
        setShardar((prev) => ({
          ...prev,
          isSyncing: false,
          ok: true,
        }));
      })
      .catch(() => {
        setShardar((prev) => ({
          ...prev,
          isSyncing: false,
          err: true,
        }));
      });
  };

  const calcGnd = () => {
    setGndCalc({
      isCalcing: true,
      ok: false,
      err: false,
    });
    adminFundamentalsClient
      .calculateGrahamsNumber()
      .then(() => {
        setGndCalc((prev) => ({
          ...prev,
          isCalcing: false,
          ok: true,
        }));
      })
      .catch(() => {
        setGndCalc((prev) => ({
          ...prev,
          isCalcing: false,
          err: true,
        }));
      });
  };

  const calcCategories = () => {
    setCalcCat({
      isCalcing: true,
      ok: false,
      err: false,
    });
    adminCcFundamentalsClient
      .createFundamentals()
      .then(() => {
        setCalcCat((prev) => ({
          ...prev,
          isCalcing: false,
          ok: true,
        }));
      })
      .catch(() => {
        setCalcCat((prev) => ({
          ...prev,
          isCalcing: false,
          err: true,
        }));
      });
  };

  return (
    <Box p={6}>
      <Box border='1px' borderColor='black' p={2} w='100%' mb={8}>
        <Text color='black' fontWeight='bold' mb={2}>
          Sync companies fundamentals
        </Text>
        <Button onClick={syncShardar} isLoading={shardar.isSyncing} mr={4}>
          GO
        </Button>
        {shardar.ok && <CheckIcon w={6} h={6} color={termosColors.darkgreen} />}
        {shardar.err && <CloseIcon w={5} h={5} color={termosColors.darkred} />}
      </Box>
      <Box border='1px' borderColor='black' p={2} w='100%' mb={8}>
        <Text color='black' fontWeight='bold' mb={2}>
          Calculate GND for companies fundamentals
        </Text>
        <Button onClick={calcGnd} isLoading={gndCalc.isCalcing} mr={4}>
          GO
        </Button>
        {gndCalc.ok && <CheckIcon w={6} h={6} color={termosColors.darkgreen} />}
        {gndCalc.err && <CloseIcon w={5} h={5} color={termosColors.darkred} />}
      </Box>
      <Box border='1px' borderColor='black' p={2} w='100%'>
        <Text color='black' fontWeight='bold' mb={2}>
          Create fundamentals for company categories
        </Text>
        <Button onClick={calcCategories} isLoading={calcCat.isCalcing} mr={4}>
          GO
        </Button>
        {calcCat.ok && <CheckIcon w={6} h={6} color={termosColors.darkgreen} />}
        {calcCat.err && <CloseIcon w={5} h={5} color={termosColors.darkred} />}
      </Box>
    </Box>
  );
};
