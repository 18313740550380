import {
  Tr,
  Tbody,
  Link,
  IconButton,
  Box,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  Input,
  useDisclosure,
  Flex,
  Center,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { PortfolioCompanyViewModel, PortfoliosClient } from '../../ApiClients';
import { TTable, TStickyHead, TTh, TTr, TinyTd } from '../../components/TTable';
import { colorNumberAroundZero, formatThousandSpace } from '../../utils/number';
import { formatDateSpecial } from '../../utils/date';
import { generatePath, Link as RouterLink } from 'react-router-dom';
import { EQ, routes } from '../../routes';
import { EditIcon } from '@chakra-ui/icons';
import { useQueryClient } from 'react-query';
import { AddToWatchlist } from '../watchlist/AddToWatchlist';

const portfoliosClient = new PortfoliosClient();

interface Props {
  portfolioCompanies: PortfolioCompanyViewModel[] | undefined;
  refetchPortfolios: () => void;
}

export const PortfolioOverviewCompanies: React.FC<Props> = ({
  portfolioCompanies,
  refetchPortfolios,
}) => {
  const queryClient = useQueryClient();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [curentPc, setCurentPc] = useState<
    PortfolioCompanyViewModel | undefined
  >(undefined);
  const [units, setUnits] = useState('');
  const [gav, setGav] = useState('');

  const handlePositionClick = (pc: PortfolioCompanyViewModel) => {
    setCurentPc(pc);
    setUnits(pc.units.toString());
    setGav(pc.gav.toString());
    onOpen();
  };

  const handleUpdatePosition = () => {
    if (!curentPc) return;
    portfoliosClient
      .updatePorfolioCompanyPosition({
        portfolioId: curentPc.portfolioId,
        portfolioCompanyId: curentPc.id,
        units: parseFloat(units),
        gav: parseFloat(gav),
      })
      .then(() => {
        setCurentPc(undefined);
        setUnits('');
        setGav('');
        onClose();
        refetchPortfolios();
        queryClient.invalidateQueries();
      });
  };

  return (
    <>
      <TTable>
        <TStickyHead>
          <Tr>
            <TTh>
              <></>
            </TTh>
            <TTh>Weight</TTh>
            <TTh>Name</TTh>
            <TTh>Curr</TTh>
            <TTh>Units</TTh>
            <TTh>Gav</TTh>
            <TTh>Last price</TTh>
            <TTh>1d %</TTh>
            <TTh>Value</TTh>
            <TTh>Returns %</TTh>
            <TTh>Returns</TTh>
            <TTh>As of</TTh>
          </Tr>
        </TStickyHead>
        <Tbody>
          {portfolioCompanies?.map((pc, i) => (
            <PCTTrs
              key={i}
              i={i}
              pc={pc}
              handlePositionClick={handlePositionClick}
            />
          ))}
        </Tbody>
      </TTable>
      <Modal isOpen={isOpen} onClose={onClose} id='changeCompanyPosition'>
        <ModalOverlay />
        <ModalContent borderWidth={2} borderColor='termos.gray'>
          <Box bgColor='termos.gray' h={9}>
            <Text
              color='white'
              fontSize='md'
              fontWeight='bold'
              ml='22px'
              mt='4px'
            >
              Update position
            </Text>
          </Box>
          <ModalBody bgColor='black' color='termos.orange'>
            <Center>
              <Flex>
                <Box mr={6}>
                  <Text color='white'>Units</Text>
                  <Input
                    color='black'
                    bgColor='termos.orange'
                    border={0}
                    _focus={{ outline: 'none' }}
                    _hover={{ outline: 'none' }}
                    w='162px'
                    onChange={(e) => setUnits(e.target.value)}
                    value={units}
                    type='text'
                  />
                </Box>
                <Box>
                  <Text color='white'>Gav</Text>
                  <Input
                    color='black'
                    bgColor='termos.orange'
                    border={0}
                    _focus={{ outline: 'none' }}
                    _hover={{ outline: 'none' }}
                    w='162px'
                    onChange={(e) => setGav(e.target.value)}
                    value={gav}
                    type='text'
                  />
                </Box>
              </Flex>
            </Center>
          </ModalBody>
          <ModalFooter bgColor='black'>
            <Button
              bgColor='termos.gray'
              color='white'
              onClick={onClose}
              mr={4}
            >
              Close
            </Button>
            <Button
              bgColor='termos.gray'
              color='white'
              onClick={handleUpdatePosition}
            >
              Update
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

interface PCTTrsProps {
  i: number;
  pc: PortfolioCompanyViewModel;
  handlePositionClick: (pc: PortfolioCompanyViewModel) => void;
}

const PCTTrs: React.FC<PCTTrsProps> = ({ i, pc, handlePositionClick }) => {
  const portfolioPerformances = pc.portfolioPerformances
    ? pc.portfolioPerformances
    : [];
  const portfolioPerformance = portfolioPerformances[0];
  return (
    <TTr i={i}>
      <TinyTd>
        <Flex>
          <IconButton
            aria-label='Toggle description'
            icon={<EditIcon />}
            colorScheme='ghost'
            _hover={{ color: 'termos.orange' }}
            size='sm'
            onClick={() => handlePositionClick(pc)}
            mt='7px'
          />
          {pc?.company && <AddToWatchlist companyId={pc.company.id} />}
        </Flex>
      </TinyTd>
      <TinyTd>{`${pc.percentOfPortfolio}%`}</TinyTd>
      <TinyTd>
        <Link
          as={RouterLink}
          to={generatePath(routes.find((r) => r.key === EQ)?.path ?? '', {
            ticker: pc?.company?.ticker,
          })}
          isExternal
        >
          {`${pc.company?.ticker} - ${pc.company?.name}`}
        </Link>
      </TinyTd>
      <TinyTd>{pc.company?.currency?.value}</TinyTd>
      <TinyTd>{pc.units}</TinyTd>
      <TinyTd>{pc.gav}</TinyTd>
      <TinyTd>{pc?.price}</TinyTd>
      <TinyTd
        color={colorNumberAroundZero(portfolioPerformance?.percentChange)}
      >
        <>
          {portfolioPerformance?.percentChange !== undefined
            ? `${portfolioPerformance.percentChange}%`
            : ''}
        </>
      </TinyTd>
      <TinyTd>{formatThousandSpace(pc?.value)}</TinyTd>
      <TinyTd color={colorNumberAroundZero(pc?.totalPercentChange)}>
        <>{pc?.totalPercentChange ? `${pc?.totalPercentChange}%` : ''}</>
      </TinyTd>
      <TinyTd color={colorNumberAroundZero(pc?.totalChange)}>
        {formatThousandSpace(pc?.totalChange)}
      </TinyTd>
      <TinyTd>
        {formatDateSpecial(portfolioPerformance?.createdUtc, 'dd.MM')}
      </TinyTd>
    </TTr>
  );
};
