import { useQuery } from 'react-query';
import {
  Company,
  CompaniesClient,
  CompanyCategoryClient,
  CompanyCategory,
  CompanyIndexViewModel,
  CompanyIndicesClient,
} from '../ApiClients';

const companiesClient = new CompaniesClient();
const companyCategoryClient = new CompanyCategoryClient();
const companyIndicesClient = new CompanyIndicesClient();

export const companiesKey = () => 'companies';

export const useGetCompanies = () =>
  useQuery<Company[]>(companiesKey(), () => companiesClient.getAll());

export const companyCategory = (value: string) => `companyCategory-${value}`;

export const useGetCompanyCategoryValues = (value: string) =>
  useQuery<CompanyCategory[]>(
    companyCategory(value),
    () => companyCategoryClient.get(value),
    { refetchOnWindowFocus: false }
  );

export const companyKey = (ticker: string) => `company/${ticker}`;

export const useGetCompany = (ticker: string) =>
  useQuery<Company>(companyKey(ticker), () => companiesClient.get(ticker));

export const useSearchCompany = (search: string) =>
  useQuery<Company[]>(
    `companySearch-${search}`,
    () => companiesClient.searchCompany(search),
    {
      enabled: search !== '',
    }
  );

export const useGetCompanyIndices = () =>
  useQuery<CompanyIndexViewModel[]>(`CompanyIndices`, () =>
    companyIndicesClient.getCompanyIndices()
  );
