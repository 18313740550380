import { Box, Center } from '@chakra-ui/layout';
import { Tr, Tbody } from '@chakra-ui/table';
import React, { useState } from 'react';
import {
  useGetMajorIndicePerformances,
  useGetMajorIndicePrices,
} from '../../hooks/investingcom';
import getUnicodeFlagIcon from 'country-flag-icons/unicode';
import { colorNumberChange } from '../../utils/number';
import { formatDate } from '../../utils/date';
import {
  TTable,
  TStickyHead,
  TTh,
  TTr,
  TinyTd,
  TTooltip,
} from '../../components/TTable';
import { Link } from '@chakra-ui/react';

export const MajorIndices: React.FC = () => {
  const [showPrice, setShowPrice] = useState(true);
  return (
    <Box>
      <Center
        color='white'
        fontSize='16px'
        _hover={{ cursor: 'pointer', color: 'termos.orange' }}
        onClick={() => setShowPrice((prev) => !prev)}
      >
        Indices
      </Center>

      <Box h='680px' overflowY='auto' border='1px' borderColor='termos.gray'>
        {showPrice ? <SmallPrice /> : <SmallPerformance />}
      </Box>
    </Box>
  );
};

const SmallPerformance: React.FC = () => {
  const { data: majorIndicePerformances } = useGetMajorIndicePerformances();
  return (
    <TTable>
      <TStickyHead>
        <Tr>
          <TTh>Index</TTh>
          <TTh>1m</TTh>
          <TTh>1y</TTh>
          <TTh>3y</TTh>
        </Tr>
      </TStickyHead>
      <Tbody>
        {majorIndicePerformances?.map((x, i) => (
          <TTr key={i} i={i}>
            <TinyTd>
              <TTooltip
                label={`${x.flag && getUnicodeFlagIcon(x.flag)} ${x.name} - ${
                  x.exchange
                }`}
              >
                <Link href={`https://www.investing.com${x.url}`} isExternal>
                  {x.flag && getUnicodeFlagIcon(x.flag)}
                  {x.symbol}
                </Link>
              </TTooltip>
            </TinyTd>
            <TinyTd color={colorNumberChange(x.performanceMonth)}>
              {x.performanceMonth}
            </TinyTd>
            <TinyTd color={colorNumberChange(x.performanceYear)}>
              {x.performanceYear}
            </TinyTd>
            <TinyTd color={colorNumberChange(x.performance3Year)}>
              {x.performance3Year}
            </TinyTd>
          </TTr>
        ))}
      </Tbody>
    </TTable>
  );
};

const SmallPrice: React.FC = () => {
  const { data: majorIndicePrices } = useGetMajorIndicePrices();
  const { data: majorIndicePerformances } = useGetMajorIndicePerformances();
  return (
    <TTable>
      <TStickyHead>
        <Tr>
          <TTh>Name</TTh>
          <TTh>Price</TTh>
          <TTh>Change</TTh>
          <TTh>1w</TTh>
        </Tr>
      </TStickyHead>
      <Tbody>
        {majorIndicePrices?.map((x, i) => (
          <TTr key={i} i={i}>
            <TinyTd>
              <TTooltip
                label={`${x.flag && getUnicodeFlagIcon(x.flag)} ${x.name} - ${
                  x.exchange
                } - TIME: ${formatDate(x.time, 'dd/MM')}`}
              >
                <Link href={`https://www.investing.com${x.url}`} isExternal>
                  {x.flag && getUnicodeFlagIcon(x.flag)}
                  {x.symbol}
                </Link>
              </TTooltip>
            </TinyTd>
            <TinyTd>{x.last}</TinyTd>
            <TinyTd color={colorNumberChange(x.chgPct)}>{x.chgPct}</TinyTd>
            {majorIndicePerformances && (
              <TinyTd
                color={colorNumberChange(
                  majorIndicePerformances.find((m) => m.symbol === x.symbol)
                    ?.performanceWeek
                )}
              >
                {
                  majorIndicePerformances.find((m) => m.symbol === x.symbol)
                    ?.performanceWeek
                }
              </TinyTd>
            )}
          </TTr>
        ))}
      </Tbody>
    </TTable>
  );
};

/*const BigPerformance: React.FC = () => {
  const { data: majorIndicePerformances } = useGetMajorIndicePerformances();
  return (
    <Table
      variant='simple'
      color='termos.orange'
      style={{ tableLayout: 'fixed' }}
    >
      <Thead bgColor='termos.beige'>
        <Tr>
          <Th color='white' border={0} pl={2.5}></Th>
          <Th color='white' border={0} pl={2.5}>
            Index
          </Th>
          <Th color='white' border={0} pl={2.5}>
            1d
          </Th>
          <Th color='white' border={0} pl={2.5}>
            1w
          </Th>
          <Th color='white' border={0} pl={2.5}>
            1m
          </Th>
          <Th color='white' border={0} pl={2.5}>
            ytd
          </Th>
          <Th color='white' border={0} pl={2.5}>
            1yr
          </Th>
          <Th color='white' border={0} pl={2.5}>
            3yr
          </Th>
        </Tr>
      </Thead>
      <Tbody>
        {majorIndicePerformances?.map((m, i) => (
          <Tr key={i} bgColor={i % 2 ? 'termos.darkorange' : 'black'}>
            <Td border={0} pl={2.5}>
              {m.flag && getUnicodeFlagIcon(m.flag)}
            </Td>
            <Td border={0} pl={2.5}>
              {m.name}
            </Td>
            <Td border={0} pl={2.5} color={colorNumberChange(m.performanceDay)}>
              {m.performanceDay}%
            </Td>
            <Td
              border={0}
              pl={2.5}
              color={colorNumberChange(m.performanceWeek)}
            >
              {m.performanceWeek}%
            </Td>
            <Td
              border={0}
              pl={2.5}
              color={colorNumberChange(m.performanceMonth)}
            >
              {m.performanceMonth}%
            </Td>
            <Td border={0} pl={2.5} color={colorNumberChange(m.performanceYtd)}>
              {m.performanceYtd}%
            </Td>
            <Td
              border={0}
              pl={2.5}
              color={colorNumberChange(m.performanceYear)}
            >
              {m.performanceYear}%
            </Td>
            <Td
              border={0}
              pl={2.5}
              color={colorNumberChange(m.performance3Year)}
            >
              {m.performance3Year}%
            </Td>
          </Tr>
        ))}
      </Tbody>
    </Table>
  );
};*/
