import { Box, Tag, Flex, Text } from '@chakra-ui/react';
import { Company } from '../../../ApiClients';
import {
  useGetFinanceInsight,
  useGetFinancialData,
} from '../../../hooks/yahoofinance';

interface FinancialDataProps {
  company: Company;
}

export const FinancialData: React.FC<FinancialDataProps> = ({ company }) => {
  const { data: quoteSummary } = useGetFinancialData(
    company.ticker ? company.ticker : ''
  );

  const { data: financeInsight } = useGetFinanceInsight(
    company.ticker ? company.ticker : ''
  );

  if (!quoteSummary || !quoteSummary.result || quoteSummary.result.length === 0)
    return <>Could not get financial Data</>;

  const financialData = quoteSummary.result[0].financialData;

  const prices = [
    {
      type: 'Low',
      raw: financialData?.targetLowPrice?.raw
        ? financialData?.targetLowPrice.raw
        : 0,
    },
    {
      type: 'Average',
      raw: financialData?.targetMeanPrice?.raw
        ? financialData?.targetMeanPrice.raw
        : 0,
    },
    {
      type: 'High',
      raw: financialData?.targetHighPrice?.raw
        ? financialData?.targetHighPrice.raw
        : 0,
    },
    {
      type: 'Current',
      raw: financialData?.currentPrice?.raw
        ? financialData?.currentPrice.raw
        : 0,
    },
  ].sort((a, b) => a.raw - b.raw);

  const recRatingColor = (key: string | undefined) => {
    if (key) {
      if (key === 'BUY') return 'termos.green';
      else if (key === 'HOLD') return 'termos.orange';
      else if (key === 'SELL') return 'termos.red';
      else return 'white';
    }

    return 'white';
  };

  return (
    <Box mt={4}>
      <Box mt={4} border='1px' p={2} borderColor='termos.gray' color='white'>
        <Flex>
          <Box mr={24}>
            Recommendation rating
            <Box mt={2}>
              <Tag
                color={recRatingColor(
                  financialData?.recommendationKey?.toUpperCase()
                )}
                bgColor='termos.darkorange'
              >
                {financialData?.recommendationMean?.fmt}{' '}
                {financialData?.recommendationKey?.toUpperCase()}
              </Tag>
            </Box>
          </Box>
          <Box color='termos.orange'>
            {financeInsight?.recommendation?.provider}
            <Box mt={2}>
              <Tag
                color={recRatingColor(
                  financeInsight?.recommendation?.rating?.toUpperCase()
                )}
                bgColor='termos.darkorange'
              >
                {financeInsight?.recommendation?.targetPrice}${' '}
                {financeInsight?.recommendation?.rating?.toUpperCase()}
              </Tag>
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box mt={4} border='1px' p={2} borderColor='termos.gray' color='white'>
        Analyst price target (
        <Text color='termos.orange' display='inline'>
          {financialData?.numberOfAnalystOpinions?.fmt}
        </Text>
        )
        <Flex mt={2}>
          <Box
            pt={4}
            mr={6}
            color={prices[0].type === 'Current' ? 'termos.orange' : 'white'}
          >
            {prices[0].type} {prices[0].raw}
          </Box>
          <Box
            height={4}
            mr={6}
            color={prices[1].type === 'Current' ? 'termos.orange' : 'white'}
          >
            {prices[1].type} {prices[1].raw}
          </Box>
          <Box
            pt={8}
            mr={6}
            color={prices[2].type === 'Current' ? 'termos.orange' : 'white'}
          >
            {prices[2].type} {prices[2].raw}
          </Box>
          <Box
            pt={4}
            color={prices[3].type === 'Current' ? 'termos.orange' : 'white'}
          >
            {prices[3].type} {prices[3].raw}
          </Box>
        </Flex>
      </Box>
    </Box>
  );
};
