import { useQuery } from 'react-query';
import { DarkIndex, DarkIndexClient } from '../ApiClients';

const darkIndexClient = new DarkIndexClient();

const noRefetch = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const useGetDarkIndexClient = () =>
  useQuery<DarkIndex>(
    'darkIndexClient',
    () => darkIndexClient.get(),
    noRefetch
  );
