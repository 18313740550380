import { useQuery } from 'react-query';
import { ETF, ETFClient } from '../ApiClients';

const etfClient = new ETFClient();

const noRefetch = {
  refetchOnMount: false,
  refetchOnReconnect: false,
  refetchOnWindowFocus: false,
};

export const useGetETFIndustries = () =>
  useQuery<ETF[]>('etfIndustries', () => etfClient.getIndustries(), noRefetch);

export const useGetETFSectors = () =>
  useQuery<ETF[]>('etfSectors', () => etfClient.getSectors(), noRefetch);

export const useGetETFBonds = () =>
  useQuery<ETF[]>('etfBonds', () => etfClient.getBonds(), noRefetch);
