import { CloseIcon } from '@chakra-ui/icons';
import { Box } from '@chakra-ui/layout';
import { Flex, IconButton, useDisclosure } from '@chakra-ui/react';
import React, { useEffect, useState } from 'react';
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
  DraggableProvidedDragHandleProps,
} from 'react-beautiful-dnd';
import {
  Watchlist,
  WatchlistCompany,
  WatchlistsClient,
} from '../../ApiClients';
import { CompanyRating } from '../../components/CompanyRating';
import { DeleteModal } from '../../components/DeleteModal';
import { useGetWatchlists } from '../../hooks/watchlists';
import { FlatlistRatios } from '../company/Ratios';

interface Props {
  currentWatchlistId: number;
  handleClickCompany: (watchlistCompanyId: number) => void;
  currentWatchlistCompanyId: number;
}

const watchlistsClient = new WatchlistsClient();

export const WatchlistList: React.FC<Props> = ({
  currentWatchlistId,
  handleClickCompany,
  currentWatchlistCompanyId,
}) => {
  const { data: watchlists, refetch } = useGetWatchlists();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [removeWaatchlistCompany, setRemoveWatchlistCompany] = useState<
    WatchlistCompany | undefined
  >(undefined);

  const [watchlist, setWatchlist] = useState<Watchlist | undefined>(undefined);

  useEffect(() => {
    setWatchlist(watchlists?.find((w) => w.id === currentWatchlistId));
  }, [watchlists, currentWatchlistId]);

  const handleRemoveCompany = () => {
    if (removeWaatchlistCompany) {
      watchlistsClient.removeCompany(removeWaatchlistCompany.id).then(() => {
        setRemoveWatchlistCompany(undefined);
        refetch();
        onClose();
      });
    }
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination || !watchlist?.companies) {
      return;
    }

    const items: WatchlistCompany[] = reorder(
      watchlist.companies,
      result.source.index,
      result.destination.index
    );

    const itemsWithNewIdx = updateIdx(items);

    setWatchlist((prev) =>
      prev
        ? {
            ...prev,
            companies: itemsWithNewIdx,
          }
        : undefined
    );
    watchlistsClient
      .updateCompaniesIdx({ ...watchlist, companies: itemsWithNewIdx })
      .then(() => {
        refetch();
      });
  };

  const reorder = (
    list: WatchlistCompany[],
    startIndex: number,
    endIndex: number
  ) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const updateIdx = (list: WatchlistCompany[]) => {
    const result = Array.from(list);
    let newIdx = 0;
    for (let res of result) {
      res.idx = newIdx;
      newIdx++;
    }
    return result;
  };

  return (
    <Box color='termos.orange'>
      {watchlist?.companies && watchlist.companies.length > 0 && (
        <>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId='droppable'>
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {watchlist?.companies?.map((wc, index) => (
                    <Draggable
                      key={wc.id}
                      draggableId={wc.id.toString()}
                      index={index}
                    >
                      {(provided, snapshot) => (
                        <Box
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          border='1px'
                          borderColor='termos.gray'
                          mb={2}
                        >
                          <WatchlistListCompany
                            wc={wc}
                            onOpen={onOpen}
                            setRemoveWatchlistCompany={
                              setRemoveWatchlistCompany
                            }
                            handleClickCompany={handleClickCompany}
                            dragHandleProps={provided.dragHandleProps}
                            currentWatchlistCompanyId={
                              currentWatchlistCompanyId
                            }
                          />
                        </Box>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
      <DeleteModal
        id='removewatchlistcompany'
        isOpen={isOpen}
        onClose={onClose}
        heading='Remove company'
        description={`Do you want to remove "${removeWaatchlistCompany?.company?.name}"?`}
        onDelete={handleRemoveCompany}
        deleteButtonText='Remove'
      />
    </Box>
  );
};

interface Prups {
  wc: WatchlistCompany;
  onOpen: () => void;
  setRemoveWatchlistCompany: React.Dispatch<
    React.SetStateAction<WatchlistCompany | undefined>
  >;
  handleClickCompany: (watchlistCompanyId: number) => void;

  dragHandleProps: DraggableProvidedDragHandleProps | undefined;
  currentWatchlistCompanyId: number;
}

const WatchlistListCompany: React.FC<Prups> = ({
  wc,
  onOpen,
  setRemoveWatchlistCompany,
  handleClickCompany,
  dragHandleProps,
  currentWatchlistCompanyId,
}) => {
  return (
    <>
      <Flex
        bgColor={
          wc.id === currentWatchlistCompanyId
            ? 'termos.darkbeige'
            : 'termos.darkorange'
        }
      >
        <Box pl={1} {...dragHandleProps} w='100%'>
          {`${wc.company?.ticker} - ${wc.company?.name}`}{' '}
          {wc.company?.ticker && <CompanyRating ticker={wc.company.ticker} />}
        </Box>
        <Box>
          <IconButton
            aria-label='Discard company'
            icon={<CloseIcon />}
            colorScheme='ghost'
            variant='solid'
            color='white'
            size='xs'
            onClick={() => {
              onOpen();
              setRemoveWatchlistCompany(wc);
            }}
          />
        </Box>
      </Flex>
      <Box
        bgColor={
          wc.id === currentWatchlistCompanyId
            ? 'termos.darkbeige'
            : 'termos.darkorange'
        }
        onClick={() => handleClickCompany(wc.id)}
        _hover={{ cursor: 'pointer' }}
        p={1}
      >
        {wc.company?.fundamentals && (
          <FlatlistRatios
            fundamental={wc.company?.fundamentals[0]}
            company={wc.company}
          />
        )}
      </Box>
    </>
  );
};
